import React from "react";
import { ListItemIcon, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core";
import { useAppContext } from "../Context/AppContext";
import CaretIcon from "../Icons/CaretIcon";
import CardTypeIcon from "./CardTypeIcon";
import CardNumberText from "../../lib/CardNumberText";

const useStyles = makeStyles({
    textField: {
        "& > .MuiOutlinedInput-root": {
            "& > .MuiSelect-root.MuiSelect-select": {
                display: "flex",
                paddingTop: 15,
                paddingBottom: 15
            }
        }
    },
    dropdownPaper: {
        border: "1px solid #d9dbdc"
        , boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.07)"
    }
});


type PaymentOptionsStatus = "LOADING" | "COMPLETED" | "ERROR" | "";
export default function (props: {
    value?: string;
    onValueChange(value: string): void;
    onStatusChange?(status: PaymentOptionsStatus): void;
    submitButtonText: string;
}) {

    const { API, initialized } = useAppContext();

    const [items, set_items] = React.useState<any[]>([])
    const [status, set_status] = React.useState<PaymentOptionsStatus>("LOADING");
    const [error, set_error] = React.useState("");

    React.useEffect(() => {

        if (!initialized) return;
        if (status !== "LOADING") return;

        Promise.all([
            API.PaymentMethods()
            , API.getUserPreferences()
        ]).then(([result, prefs]) => {

            set_items(result);
            set_status("COMPLETED");

            if (prefs.defaultPaymentMethod)
                return props.onValueChange(prefs.defaultPaymentMethod);

            if (!props.value && result.length)
                // selecciona el ultimo metodo de pago siempre, si hay
                return props.onValueChange(result[result.length - 1].id);

            return props.onValueChange("new");

        }).catch(ex => {
            set_status("ERROR");
            set_error(ex.message);
        }).finally(() => {
            props.onStatusChange && props.onStatusChange(status);
        });

    }, [initialized, status]);


    const isDisabled = status !== "COMPLETED";
    const isLoading = status === "LOADING";
    const ref = React.useRef<any>(null);

    const classes = useStyles();
    const value = props.value || "new";
    return (<TextField
        select
        className={classes.textField}
        disabled={isDisabled || isLoading}
        value={value}
        onChange={event => props.onValueChange(event.target.value)}
        variant="outlined"
        color="secondary"
        error={status === "ERROR"}
        helperText={error ?
            (<>
                <img
                    title="Error Icon"
                    style={{ display: "inline-block", height: "0.8em", marginRight: 5 }}
                    src={process.env.PUBLIC_URL + "/svg/danger.svg"}
                />
                {error}
            </>)
            : (value === "new"
                ? <>
                    <img
                        title="Secure Icon"
                        style={{ display: "inline-block", height: "0.8em", marginRight: 5 }}
                        src={process.env.PUBLIC_URL + "/svg/helper-lock.svg"}
                    />
                    {`Después de hacer click en '${props.submitButtonText}' te enviaremos a Transbank para agregar tu medio de pago de forma segura.`}
                </>
                : undefined)
        }
        inputRef={ref}
        FormHelperTextProps={{
            style: {
                marginRight: 40,
                marginTop: 5,
                marginLeft: 2,
                fontSize: 12
            }
        }}
        SelectProps={{
            MenuProps: {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                classes: {
                    paper: [classes.dropdownPaper, "MuiOutlinedInput-borderRadius"].join(" ")
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                getContentAnchorEl: null,
                disableScrollLock: true,
            },
            IconComponent: () => <CaretIcon style={{ right: 20, width: 15, top: "initial" }} className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" />
        }}
    >
        {
            items.map(card => (Item({
                card_number: card.card_number,
                card_type: card.card_type,
                id: card.id
            })))
        }

        {
            Item({
                card_number: isLoading ? "Cargando métodos de pago..." : "Agregar método de pago",
                card_type: "credit-card",
                id: "new"
            })
        }

    </TextField >)
}

function Item(props: {
    card_number: string;
    card_type: string;
    id: string;
}) {



    return (
        <MenuItem
            key={props.id}
            value={props.id}
            style={{ display: "flex", flexDirection: "row" }}
        >
            <ListItemIcon>
                <CardTypeIcon name={props.card_type} height={20} width={40} />
            </ListItemIcon>
            <Typography style={{ flex: 1, flexGrow: 1 }}>
                {CardNumberText(props.card_number)}
            </Typography>
        </MenuItem>);
}
