import React from "react";
import { Button, CircularProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    BtnRoot: {
        textAlign: "left"
        , marginTop: 10
        ,
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            paddingBottom: 10
        }
    },
    btn: {
        minWidth: 240,
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    }
}));

export default function (props: {
    onSubmit?(): void;
    isLoading?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
    fullWidth?: boolean
    type?: "button" | "submit"
}) {
    const classes = useStyles();
    return (
        <div className={classes.BtnRoot} >
            <Button
                fullWidth={props.fullWidth}
                disabled={props.disabled || props.isLoading}
                size="large"
                color="primary"
                type={props.type || "button"}
                className={classes.btn}
                variant="contained"
                onClick={props.onSubmit}>
                {props.isLoading
                    ? <CircularProgress size={30} color="inherit" />
                    : props.children}
            </Button>
        </div>);
}