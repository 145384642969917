import { FirebaseError } from "firebase/app";
import { signInWithPopup } from "firebase/auth";
import React from "react";
import { FirebaseErrorMessage } from "../../lib/FirebaseAuthError";
import { AuthInstance, facebookAuthProvider } from "../../lib/FirebaseConfig";
import ButtonWithIcon from "./ButtonWithIcon";

export default function (props: {
    onStart(): void;
    onCompleted(): void;
    onError(ex: Error, origin: "FACEBOOK"): void;
    isLoading: boolean;
    disabled: boolean;
}) {

    const onClick = React.useCallback(async function () {
        if (props.disabled || props.isLoading) return;
        props.onStart();
        signInWithPopup(AuthInstance, facebookAuthProvider)
            .then(props.onCompleted)
            .catch(ex => {
                console.error("FacebookLoginButton signInWithPopup error ", ex);
                if (ex instanceof FirebaseError)
                    ex.message = FirebaseErrorMessage(ex);
                props.onError(ex, "FACEBOOK");
            });
    }, []);

    return (<ButtonWithIcon
        onClick={onClick}
        iconSrc="https://facebook.com/favicon.ico"
        text="Continuar con Facebook"
        disabled={props.disabled}
        isLoading={props.isLoading}
    />);
}