import { Button, Typography } from "@material-ui/core";

export default function () {
    return (<div style={{
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#071d34",
        paddingTop: 40,
        paddingBottom: 40
    }} >
        <div style={{ margin: "auto", textAlign: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>¿Necesitas ayuda?</Typography>
            <Typography variant="caption" component="p" style={{ color: "white" }} >Chatea con nuestro equipo de soporte</Typography>
            <Button component="a" id="CHAT_BTN" variant="contained" color="secondary" style={{
                marginTop: 20
                , backgroundColor: "white"
                , color: "#081d34"
                , borderRadius: 8
                , padding: "9px 16px"
            }}>Chatear</Button>
        </div>
    </div>);
}