import { Box, makeStyles, Typography } from "@material-ui/core";
import useBreakpoints from "../../Components/Hooks/useBreakpoints";
import Header from "../Item-Selection-Flow/header";
import LandingLogo from "../LandingPage/LandingLogo";
import useStyles from "../Item-Selection-Flow/index.style";
import React from "react";
import CountDown from "src/Components/CountDown";

const useMainStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    }
    , vertical_content_max_width: {
        maxWidth: 630
    }
    , childrenContainer: {
        flex: 1
        , overflow: "auto"
        , flexDirection: "column"
        , display: "flex"
        , "& > div:last-child": {
            flexGrow: 1,
            minHeight: 0
        }
        , scrollbarWidth: 'thin'
        , scrollbarColor: 'transparent transparent'
        , '&::-webkit-scrollbar': {
            width: '6px',
            background: 'transparent',
        }
        , '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
        }
        , '&::-moz-scrollbar': {
            width: '6px',
            background: 'transparent',
        }
        , '&::-moz-scrollbar-thumb': {
            background: 'transparent',
        }
        , '&::-ms-scrollbar': {
            width: '6px',
            background: 'transparent',
        }
        , '&::-ms-scrollbar-thumb': {
            background: 'transparent',
        }
    }
}));

export default function FlowV2PageLayout(props: {
    showCloseBtn?: boolean
    title: React.ReactNode
    subtitle: React.ReactNode
    children?: React.ReactNode
    footer?: React.ReactNode
    onSubmit?: React.FormEventHandler<HTMLElement>
}) {
    const xsDown = useBreakpoints();
    const flowLayoutClasses = useStyles();
    const mainClasses = useMainStyles();

    const onSubmit: React.FormEventHandler<HTMLElement> | undefined = props.onSubmit && function (e) {
        e.stopPropagation();
        e.preventDefault();
        props.onSubmit && props.onSubmit(e);
    }
    return (<Box className={mainClasses.root}>
        <Box className={mainClasses.childrenContainer}>
            <CountDown />
            <Box className="header">
                {xsDown ? <Header xsDownItem={props.showCloseBtn ? "close" : "logo"} /> : <LandingLogo />}
            </Box>
            <Box
                className={["flow-content-container", flowLayoutClasses.contentContainer].join(" ")}
                component={onSubmit ? "form" as any : undefined}
                onSubmit={onSubmit}
            >
                <div style={{ paddingBottom: 32, textAlign: "center" }} >
                    {typeof props.title === "string" ? <Typography className={flowLayoutClasses.title} variant="h2">{props.title}</Typography> : props.title}
                    {(<div style={{ marginTop: 5 }}>
                        {props.subtitle ? (<Typography variant="body1" className="md" >{props.subtitle}</Typography>) : null}
                    </div>)}
                </div>
                <div className={flowLayoutClasses.controllersContainer}>
                    {props.children}
                </div>
            </Box>
        </Box>
        <Box component={onSubmit ? "form" as any : undefined} onSubmit={onSubmit}>
            {props.footer}
        </Box>
    </Box>)
}