import { makeStyles } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Radio, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles({
    accordion: {
        '&.MuiAccordion-root': {
            marginBottom: 0,
            margin: "0px !important",
            borderRadius: 8,
            overflow: "hidden",
            border: "1px solid rgb(221, 225, 229)",
            '&.Mui-expanded:before': {
                display: 'none'
            },
        }
    },
    title: {
        margin: "12px 0px !important",
    },
    acoordionSummaryRoot: {
        "&.Mui-expanded": {
            borderBottom: "1px solid rgb(221, 225, 229)"
        }
    },
    list: {

        "& div:not(:first-child)": {
            borderTopWidth: 0
        },
        "& div:not(:first-child):not(:last-child)": {
            borderRadius: 0
        },
        "& div:first-child": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
        "& div:last-child": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        }
    }
    , accordionDetails: {
        backgroundColor: "#F6F7F9"
        , flexDirection: "column"
    }
    , detailsRoot: {
        paddingTop: 20
        , paddingBottom: 20
    }
    , wallpaper: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/svg/payment_platforms/wallpaper.svg)`
        , backgroundRepeat: 'no-repeat'
        , backgroundPosition: 'center'
        , minHeight: 100
        , width: "100%"
        , opacity: 0.5
        , marginBottom: 10
    }
    , detailsTextRoot: {
        textAlign: "center",
        maxWidth: 500,
        margin: "auto"
    }
    , mercadopago: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/svg/payment_platforms/mercadopago.svg)`
    }
    , webpay: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/svg/payment_platforms/webpay.svg)`
        , minWidth: 80
    }
    , radioLabel: {
        minWidth: 100
        , height: "100%"
        , backgroundRepeat: 'no-repeat'
        , backgroundPosition: 'center'
    }
});

function PaymentPlatformList(props: {
    value?: string | null,
    onChange(value: string): void
}) {
    const selectedValue = props.value || "";
    const handleChange = props.onChange;
    const classes = useStyles();
    return (
        <div className={classes.list} >
            <Accordion elevation={0} className={classes.accordion} expanded={selectedValue === 'mercadopago'} onChange={() => handleChange('mercadopago')}>
                <AccordionSummary classes={{ content: classes.title, root: classes.acoordionSummaryRoot }}>
                    <FormControlLabel
                        value="mercadopago"
                        control={<Radio color="primary" checked={selectedValue === 'mercadopago'} />}
                        classes={{ label: [classes.radioLabel, classes.mercadopago].join(" ") }}
                        labelPlacement="end"
                        label=""
                    />
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <div className={classes.detailsRoot}>
                        <div className={classes.wallpaper} ></div>
                        <div className={classes.detailsTextRoot}>
                            <Typography component="p" variant="caption">Después de hacer clic en "Confirmar y solicitar registro", serás redirigido a Mercadopago para completar tu compra de forma segura.</Typography>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>


            <Accordion elevation={0} className={classes.accordion} expanded={selectedValue === 'webpay'} onChange={() => handleChange('webpay')}>
                <AccordionSummary classes={{ content: classes.title, root: classes.acoordionSummaryRoot }}>
                    <FormControlLabel
                        value="webpay"
                        control={<Radio color="primary" checked={selectedValue === 'webpay'} />}
                        classes={{ label: [classes.radioLabel, classes.webpay].join(" ") }}
                        labelPlacement="end"
                        label=""
                    />
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <div className={classes.detailsRoot}>
                        <div className={classes.wallpaper}></div>
                        <div className={classes.detailsTextRoot}>
                            <Typography component="p" variant="caption">Después de hacer clic en "Confirmar y solicitar registro", serás redirigido a Webpay para completar tu compra de forma segura.</Typography>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default PaymentPlatformList;