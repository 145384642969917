import React from "react";
import { Grid, Typography, Button, makeStyles, Theme, GridSize } from "@material-ui/core";
import InfoDialog from "../../Components/InfoDialog";
import ReviewList from "./Reviews.json";
import { BORDER_STYLE } from "../../Theme/colors";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: 40,
        [theme.breakpoints.down("xs")]: {
            marginTop: 30,
            marginBottom: 50
        }
    },
    star: {
        height: theme.typography.caption.fontSize, marginRight: 5
    },
    bottom_button: {
        marginTop: 70
        , paddingBottom: 7
        , paddingTop: 7
        , borderRadius: 5
        , fontSize: 13
        , [theme.breakpoints.down("xs")]: {
            marginTop: 50
        }
    },
    grid_with_no_side_padding: {
        width: "100%",
        margin: 0,
        "& .MuiGrid-root.MuiGrid-item": {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 25,
            paddingBottom: 25
        }
    },
    ReviewsContainer: {
        marginTop: 23,
        [theme.breakpoints.down("xs")]: {
            marginTop: 0
        }
    }
}));

export default function Reviews(props: {
    full_screen?: boolean,
    no_side_paddings?: boolean,
    reviewsNumber?: number
}) {
    const one_per_row = props.full_screen;
    const col_length = one_per_row ? 12 : 6;

    const [isDialogOpen, set_isDialogOpen] = React.useState(false);
    const classes = useStyles();

    const reviewList = [...ReviewList];
    reviewList.length = props.reviewsNumber || 4;

    return (<div className={col_length === 6 ? classes.root : undefined}>
        {props.full_screen
            ? null
            : <Typography variant="h5">Opiniones de Clientes</Typography>}

        <Grid container spacing={5} className={[props.no_side_paddings ? classes.grid_with_no_side_padding : "", classes.ReviewsContainer].join(" ")} >
            {reviewList.map((r, index) => (<Review
                img={`${process.env.PUBLIC_URL}/img/user-reviews/` + r.person.replace(/ /g, "-").toLowerCase() + ".png"}
                key={index}
                title={r.person}
                subtitle={r.company}
                text={r.comment}
                rows={col_length}
            />))}
        </Grid>
        {props.full_screen
            ? null
            : (<>
                <InfoDialog isOpen={isDialogOpen} onClose={() => set_isDialogOpen(false)} title="Opiniones de Clientes"  >
                    <Reviews reviewsNumber={12} full_screen={true} no_side_paddings={true} />
                    <br />
                    <br />
                </InfoDialog>
                <Button
                    variant="outlined"
                    color="secondary"
                    className={"hover-underline " + classes.bottom_button}
                    onClick={() => set_isDialogOpen(true)}
                >
                    Ver más opiniones
                </Button>
            </>)}
    </div>)
}

function Review(props: {
    title: string
    , subtitle: string
    , text: string
    , rows: GridSize
    , img: string
    , openMore?(): void;
}) {
    const classes = useStyles();

    return (<Grid item sm={props.rows} style={props.rows === 12 ? { borderTop: BORDER_STYLE } : undefined} >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ marginRight: 20 }}>
                <img style={{ borderRadius: "50%" }} src={props.img} alt={props.title} height={60} />
            </div>
            <div style={{ flex: 1, flexGrow: 1, margin: "auto" }} >
                <Typography className="sm" variant="body2">{props.title}</Typography>
                <Typography style={{ display: "block" }} variant="caption">{props.subtitle}</Typography>
                <div style={{ marginTop: 8 }}>
                    <img className={classes.star} src={process.env.PUBLIC_URL + "/svg/star.svg"} />
                    <img className={classes.star} src={process.env.PUBLIC_URL + "/svg/star.svg"} />
                    <img className={classes.star} src={process.env.PUBLIC_URL + "/svg/star.svg"} />
                    <img className={classes.star} src={process.env.PUBLIC_URL + "/svg/star.svg"} />
                    <img className={classes.star} src={process.env.PUBLIC_URL + "/svg/star.svg"} />
                    <div style={{ display: "inline", marginLeft: 5 }} >
                        <Typography variant="caption" component="i">5/5</Typography>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ marginTop: 25 }}>
            <Typography variant="caption" component="p">{props.text}</Typography>
            {props.openMore && (<Button
                style={{ fontSize: 17, margin: 0, padding: 0 }}
                type="button"
                onClick={() => props.openMore && props.openMore()}
                variant="text"
                color="secondary"
            >
                Leer más
            </Button>)}
        </div>
    </Grid>);
}