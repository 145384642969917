import React from "react";
import useNavigateToStep from "../../Components/Hooks/useNavigateToStep";
import Footer from "../Item-Selection-Flow/Footer";
import TrademarkInput from "../Item-Selection-Flow/Trademark-Input";
import RootGroupContainer from "./RootGroupContainer";
import Layout from "./PageLayout";
import useFlowState from "./FlowState";
import { Routes, Route, useNavigate, Link, useParams } from "react-router-dom";
import { Typography, makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
    footer: {
        backgroundColor: "#F6F7F9"
    }
});


export default function PopupBasedFlowPage() {

    const scrollableRef = React.useRef<HTMLDivElement>(null);

    const flowState = useFlowState(scrollableRef);
    const { addItemId, removeItemId, setItemIds,
        marca, onMarcaChanged,
        itemIds, has_custom_classes } = flowState;

    const { navigateToOrderPage } = useNavigateToStep()

    React.useEffect(() => {
        if (has_custom_classes) navigateToOrderPage();
    }, [has_custom_classes]);

    const subPath = (useParams()["*"] || "").split("/").shift();

    const directions = useResolveFlowDirections(subPath, flowState);
    const classes = useStyle();

    return (<Layout
        title={directions.headings.title}
        showCloseBtn={false}
        subtitle={directions.headings.subtitle}
        onSubmit={directions.goNext}
        footer={<Footer
            canGoNext={directions.canGoNext}
            nextBtnText={subPath === "pick" ? "Buscar" : "Siguiente"}
            borderTopComponent="line"
            classes={{
                root: classes.footer
            }}
        />}
    >
        <Routes>
            <Route
                path="pick"
                element={<RootGroupContainer
                    selectedIds={itemIds}
                    onAddId={addItemId}
                    onRemoveId={removeItemId}
                    onSetIds={setItemIds}
                />}
            />

            <Route
                index
                element={<TrademarkInput
                    value={marca}
                    onTextChange={onMarcaChanged}
                    placeholder="Escribe el nombre de la marca"
                />} />

            <Route
                path="*"
                element={<div style={{
                    display: "flex"
                    , alignItems: "center"
                    , height: "100%"
                }}>
                    <Typography>No encontramos el resultado:
                        Comienza escribiendo tu marca <Link style={{ color: "#018aff" }} to={{ pathname: "./" }}>aquí</Link>.
                    </Typography>
                </div>} />
        </Routes>
    </Layout>);
}

function useResolveFlowDirections(path: string, state: ReturnType<typeof useFlowState>) {

    const navigate = useNavigate();
    const { navigateToOrderPage } = useNavigateToStep();
    if (path === "pick") {
        const canGoNext = state.itemIds.size > 0;
        return {
            headings: {
                title: "¿Qué lleva tu marca?",
                subtitle: "Puedes seleccionar más de una opción."
            }
            , canGoNext
            , goNext() {
                if (!canGoNext) return;
                navigateToOrderPage()
            }
            , progress: 0.5
        }
    }
    if (["", "/"].includes(path)) {
        const canGoNext = (state.marca || "").trim().length > 0;
        return {
            headings: {
                title: <div>
                    <div style={{ paddingBottom: 20 }}>
                        <Typography variant="body2">
                            <mark>Buscador con Inteligencia Artificial</mark>
                        </Typography>
                    </div>
                    <Typography variant="h2">¿Qué marca quieres registrar?</Typography>
                </div>,
                subtitle: null
            }
            , canGoNext
            , progress: 0
            , goNext() {
                if (!canGoNext) return;
                navigate("./pick" + window.location.search);
            }
            , goBack: undefined
        }
    }

    return {
        headings: {
            title: "",
            subtitle: "",
        }
        , goNext() { void (0) }
        , progress: 0.5
    }

}
