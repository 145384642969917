import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    "@global": {
        ".aside-root > .MuiPaper-root.MuiPaper-rounded": {
            padding: 20
        }
    }
    , root: {
        marginTop: 15
        , [theme.breakpoints.down("xs")]: {
            marginTop: 0
        }
    }
    , ContentContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingRight: "80px !important",
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: "initial",
            width: "100%"
        }
    }
    , AsideContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingLeft: "17px !important",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "initial",
            width: "100%"
        },
    }
}));


export function MainContainer(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { header?: React.ReactNode }) {
    const classes = useStyles();
    return <div style={props.style} className={[props.className || "", "order-layout-container"].join(" ")} >
        {props.header}
        <Grid className={classes.root} container spacing={2} style={props.style}>
            {props.children}
        </Grid>
    </div>
}

export function LeftContainer(props: { children: React.ReactNode, style?: React.CSSProperties }) {
    const classes = useStyles();
    return (<Grid item sm={8} className={classes.ContentContainer} style={props.style} >
        {props.children}
    </Grid>);
}

export function RightContainer(props: { children: React.ReactNode, style?: React.CSSProperties }) {
    const classes = useStyles();
    return (<Grid item sm={4} className={classes.AsideContainer} style={props.style}>
        {props.children}
    </Grid>)
}