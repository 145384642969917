import { Button, Typography } from "@material-ui/core";
import React from "react";
import LandingHeader from "../../Layouts/LandingPage/LandingHeader";
import useNavigateToStep from "../Hooks/useNavigateToStep";

export default function (props: {
    statusCode?: number;
    errorCode?: string;
    message: string;
    name?: string;
    children?: React.ReactNode
}) {

    const { navigateToFlow } = useNavigateToStep();

    const errorCode = props.errorCode || props.statusCode || props.name;
    return (<div>
        <LandingHeader show_login={false} />

        <div style={{
            textAlign: "center",
            margin: "auto",
            marginTop: 100,
            maxWidth: 500 + 60,
            paddingLeft: 30,
            paddingRight: 30
        }}>

            <Typography variant="h3">Ups... Ocurrió un problema</Typography>

            <Typography style={{ marginTop: 15 }} >{props.message}</Typography>
            {errorCode && <Typography variant="caption" component="small" className="xs">
                codigo: {errorCode}
            </Typography>}

            {props.children
                ? props.children
                : <div style={{ marginTop: 20 }}>
                    <Button variant="text" color="secondary" onClick={() => navigateToFlow()}>Regresar al inicio</Button>
                </div>}
        </div>
    </div>)
}