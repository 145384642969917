import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import PurchaseWarrantyDialog from "./PurchaseWarrantyDialog";

const useStyles = makeStyles(theme => ({
    warranty_root: {
        padding: 20
        , marginTop: 10
        , marginBottom: 20
        , borderRadius: 7,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 0,
            paddingLeft: 15,
            paddingRight: 15
        }
        , backgroundColor: "#f6f7f9"
    },
    inherit: {
        fontSize: "inherit !important"
        , lineHeight: "inherit !important"
        , display: "inline"
    }
}));

export default function () {

    const classes = useStyles();
    const [dialog, set_dialog] = React.useState(false);

    return (<>
        <div className={classes.warranty_root} style={{ borderRadius: 10 }} >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <img style={{ maxHeight: 29 }} src={process.env.PUBLIC_URL + "/svg/security.svg"} />
                <div style={{ marginLeft: 10, flex: 1 }}>
                    <Typography variant="caption" color="secondary" component="small" className="xs">
                        <Typography variant="body2" className={classes.inherit} color="inherit">SEGURO <Typography color="primary" variant='body2' className={classes.inherit} style={{ color: "#0084ff" }} >0%</Typography> RIESGO. </Typography>
                        Si tu primera marca es rechazada por INAPI, cubriremos nuestra tarifa para registrar una marca diferente.&nbsp;
                        <Typography onClick={() => set_dialog(true)} className={classes.inherit} style={{ display: "inline-block" }} variant="caption" component="a" color="primary">Saber más</Typography>
                    </Typography>
                </div>
            </div>
        </div>
        <PurchaseWarrantyDialog open={dialog} onClose={() => set_dialog(false)} />
    </>);
}