import React from "react";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles({
    InputGroup: {
        "& div:not(:first-child):not(:last-child) .MuiInputBase-root.MuiOutlinedInput-root": {
            borderRadius: 0
        },
        "& div:not(:last-child) .MuiInputBase-root.MuiOutlinedInput-root fieldset": {
            borderBottomWidth: "0.5px",
            borderTopWidth: "0.5px"
        },
        "& div:first-child .MuiInputBase-root.MuiOutlinedInput-root": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            "& fieldset": {
                borderTopWidth: "1px",
                borderBottomWidth: "0.5px"
            }
        },
        "& div:last-child .MuiInputBase-root.MuiOutlinedInput-root": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            "& fieldset": {
                borderTopWidth: "0.5px"
            }
        }
    }
})

export default function (props: React.HTMLAttributes<HTMLDivElement> = {}) {
    const classes = useStyles();
    const { className, style, ...divProps } = props;
    return (<div
        {...divProps} className={classes.InputGroup + " " + (className || "") + " div-input-group"}
        style={{ display: "block", ... (style || {}) }}

    >
        {props.children}
    </div >)
}