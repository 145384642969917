import { Typography } from "@material-ui/core";
import DateToText, { DateToTextArg } from "../../lib/DateToText";
import TitleStatusView from "./TitleStatusView";

export default function (props: { date: DateToTextArg, amount: number }) {
    return (<div>
        <TitleStatusView>Tu pago está en proceso</TitleStatusView>
        <Typography variant="h6">Te informaremos el resultado a tu correo electrónico.</Typography>
        <Typography variant="body1">Fecha de Cobro: {DateToText(props.date)}</Typography>
        <Typography>Monto: {props.amount.toCurrency()}</Typography>
    </div>)
}