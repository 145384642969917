import React from "react";
import { useAppContext } from "../Context/AppContext";
import useNavigateToStep from "../Hooks/useNavigateToStep";
import LoadingScreen from "../LoadingScreen";
import AnonymousEmailSignUpDialog from "../Login/AnonymousEmailSignUpDialog";

export default function <T extends object>(Component: React.ComponentType<T>) {

    return (props: T & {
        onIsNotLoggedIn?(routes: ReturnType<typeof useNavigateToStep>): void;
    }) => {
        const { user, anonymous_activity_email, initialized } = useAppContext();
        const { onIsNotLoggedIn, ...remainProps } = props;

        const has_credentials = !!(user || anonymous_activity_email);
        const routes = useNavigateToStep();
        React.useEffect(() => {
            if (initialized && !has_credentials && onIsNotLoggedIn) onIsNotLoggedIn(routes);
        }, [initialized]);


        if (!initialized || !has_credentials)
            return (<>
                <LoadingScreen fullScreen />
                <AnonymousEmailSignUpDialog
                    open={initialized}
                />
            </>);

        return (<Component {...remainProps as T} />)
    }
}