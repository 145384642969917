import React from "react";
import { makeStyles, Theme, Toolbar, Typography, Grid } from "@material-ui/core";
import Line from "../../Components/Line";

const useStyle = makeStyles((theme: Theme) => ({
    toolbar: {
        display: "block",
        paddingTop: 70,
        zIndex: 2,
        paddingBottom: 50,
        textAlign: "center",
        backgroundColor: "white",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 35,
            paddingBottom: 20
        }
    }
    , footer_container: {
        maxWidth: 1170
        , width: "100%"
        , margin: "auto"
    }
    , links_container: {
        display: "flex"
        , flexDirection: "row"
        , marginBottom: 7
        , textAlign: "left"
        , padding: 20
        , "& > div": {
            [theme.breakpoints.down("xs")]: {
                display: "block"
            }
        }
    }
    , grid_content: {
        paddingLeft: 15
        , paddingRight: 15
        , "& > *": {
            display: "block",
        }
        , [theme.breakpoints.down("xs")]: {
            marginTop: 20
        }
    }
    , text: {
        marginTop: 10,
        fontSize: theme.typography.caption.fontSize
    }
    , title: {
        fontSize: 14
    }
}))

export default function LandingFooter() {
    const classes = useStyle();
    return (<Toolbar className={classes.toolbar}>
        <div className={classes.footer_container}>
            <div className={classes.links_container}>
                <Grid container>
                    <Grid item sm={3}>
                        <div className={classes.grid_content}>
                            <Title>Asistencia</Title>
                            <Text link="http://asistencia.simplemarcas.cl/">Centro de ayuda</Text>
                            <Text link="https://www.simplemarcas.cl/precios?_fs=16264582704-14983426205">Venta telefónica</Text>
                        </div>
                    </Grid>
                    <Grid item sm={3}>
                        <div className={classes.grid_content}>
                            <Title>Legal</Title>
                            <Text link="https://www.simplemarcas.cl/politicas-de-privacidad?_fs=16264582704-14983426205">Políticas de Privacidad</Text>
                            <Text link="https://www.simplemarcas.cl/terminos-de-uso-del-buscador?_fs=16264582704-14983426205">Términos de uso del buscador</Text>
                            <Text link="https://www.simplemarcas.cl/terminos-del-servicio?_fs=16264582704-14983426205">Términos del servicio</Text>
                        </div>
                    </Grid>
                    <Grid item sm={3}>
                        <div className={classes.grid_content}>
                            <Title>Trabaja con nosotros</Title>
                            <Text link="https://www.simplemarcas.cl/empleos?_fs=16264582704-14983426205">Empleos</Text>
                            <Text link="https://www.simplemarcas.cl/error-89yrf8hjnd?_fs=16264582704-14983426205">Programa de referidos</Text>
                        </div>
                    </Grid>
                    <Grid item sm={3}>
                        <div className={classes.grid_content}>
                            <Title>Acerca de</Title>
                            <Text link="https://www.simplemarcas.cl/error-89yrf8hjnd?_fs=16264582704-14983426205">Sala de Prensa</Text>
                            <Text link="https://www.simplemarcas.cl/error-89yrf8hjnd?_fs=16264582704-14983426205">Comunidad</Text>
                            <Text link="https://www.instagram.com/simplemarcas/">Instagram</Text>
                            <Text link="https://www.facebook.com/simplemarcas">Facebook</Text>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{ paddingLeft: 35, paddingRight: 35 }}>
                <div style={{ marginTop: 15, marginBottom: 20 }}>
                    <Line size={1} use="header" />
                </div>
                <div style={{ textAlign: "left" }}>
                    <Text>Simple Marcas SPA, Avenida Libertad 269, 904, Viña del Mar, Chile.</Text>
                    <Text>Simple Marcas LLC, 848 Brickell Ave Suite 950, Miami, FL 33131, Estados Unidos.</Text>
                </div>
            </div>
        </div>
    </Toolbar>);
}

function Title(props: { children?: React.ReactNode }) {
    const classes = useStyle();
    return (<Typography className={classes.title} variant="body2" color="secondary">{props.children}</Typography>);

}

function Text(props: {
    children?: React.ReactNode,
    link?: string
}) {
    const classes = useStyle();
    return (<Typography target="_blank" style={{ textDecoration: "none", textTransform: "none", color: "inherit" }} variant="body1" component={props.link ? "a" : undefined as any} href={props.link} className={classes.text}>
        {props.children}
    </Typography>);
}