import { User } from "firebase/auth";
import { setCurrentScreen, logEvent, getAnalytics, setUserId, setUserProperties } from "firebase/analytics"
const isProduction = process.env.NODE_ENV === "production";


type minimalUser = { uid?: string, email?: string, displayName?: string, phoneNumber?: string, providerData?: Array<{ providerId?: string }> } | null;
module AnalyticsEvents {

    function structureUserData(user: minimalUser) {
        return {
            displayName: user?.displayName
            , email: user?.email
            , providerId: user?.providerData[0]?.providerId
            , phoneNumber: user?.phoneNumber
        }
    }
    export function setUser(user: minimalUser) {
        if (!isProduction) return;
        setUserId(getAnalytics(), (user?.uid || null) as any);
        setUserProperties(getAnalytics(), structureUserData(user));
    }
    export function logged_in(user: User) {
        if (!isProduction) return;
        if (((+(user.metadata as any).lastLoginAt) - (+(user.metadata as any).createdAt)) <= 10) {
            logEvent(getAnalytics(), "sign_up", structureUserData(user));
            logEvent(getAnalytics(), "generate_lead", { action: "sign_up", ...structureUserData(user) });
            return;
        }
        logEvent(getAnalytics(), "login", structureUserData(user));
    }
    export function newLead(user: minimalUser) {
        if (!isProduction) return;
        logEvent(getAnalytics(), "sign_up", structureUserData(user));
        logEvent(getAnalytics(), "generate_lead", { action: "sign_up", ...structureUserData(user) });
    }
    export function click(button_name: string) {
        if (!isProduction) return;
        logEvent(getAnalytics(), "click", { button_name });
    }
    export function setPage() {
        if (!isProduction) return;
        setCurrentScreen(getAnalytics(), document.title);
        logEvent(getAnalytics(), "page_view", {
            page_location: window.location.href,
            page_path: window.location.pathname,
            page_title: document.title
        })
    }

    export function purchaseCompletionShown(req: Lead, user: minimalUser | null) {
        if (!isProduction) return;
        const first_payment = req.payments[0];
        const items = Object.entries(req.detalle_clases).reduce((a, [clase, detalle_clase]) => {
            if (detalle_clase.details.length) {
                detalle_clase.details.forEach(item => a.push({
                    item_id: item.itemId
                    , item_name: item.itemDescription
                    , item_category: "clase " + clase
                }));
            } else {
                a.push({
                    item_id: "personalizado"
                    , item_name: detalle_clase.title
                    , item_category: "clase " + clase
                })
            }
            return a;
        }, [] as Array<{ item_id: string, item_name: string, item_category: string }>)
        if (req.logo) items.push({
            item_category: "logo"
            , item_id: "logo"
            , item_name: "logo"
        });
        logEvent(getAnalytics(), "purchase", {
            value: first_payment?.amount || req.resumen.cuota_inicial_amount || req.resumen.cuota_amount
            , total: req.resumen.total
            , user_email: user?.email
            , user_displayName: user?.displayName
            , currency: req.resumen.currency
            , transaction_id: req.id
            , descuento: req.resumen?.valor_descuento
            , marca: req.marca
            , payment_method: first_payment?.card_type
            , items
            , coupon: req.resumen.discount_info?.label
        })
    }
    export function error(ex: Error) {
        if (!isProduction) return;
        logEvent(getAnalytics(), "exception", {
            ...ex,
            description: ex.message,
            fatal: false,
            stack: ex.stack,
            code: (ex as any).code,
            name: ex.name,
            path: window.location.pathname,
            location: window.location.href,
        })
    }
}

export default AnalyticsEvents;