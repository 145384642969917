import { ListItem, Chip, List, ListItemText, ListItemAvatar, Checkbox, makeStyles } from "@material-ui/core";
import SearchItemInput from "../../../Components/ItemSearchBar/SearchItemInput";
import { GroupItem, GroupItemWithNextStep } from "../Flow-templates";
import { BORDER_STYLE } from "../../../Theme/colors";
import findMatchesInsideItem from "../../Flow.v2/FindMatchesInsideItem";
import ErrorScreen from "src/Components/ErrorScreen";
import useSearchWorker from "./useWorker";
import fetchFlowData from "../Flow-templates/useFetchFlow";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    badge: {
        padding: "0px",
        borderRadius: 0
    },
    badgeLabel: {
        fontSize: 10,
        maxWidth: "calc(200px - 30px)"
    }
    , badgeRow: {
        display: "block"
        , borderTop: BORDER_STYLE
        , marginTop: 5
        , minHeight: 42
        , paddingLeft: 0
        , paddingRight: 0
        , borderBottom: BORDER_STYLE
        , "& > .MuiChip-root": {
            marginTop: 2, marginBottom: 2
        }
    }
    , text: {
        fontSize: "14px !important",
        lineHeight: theme.typography.body1.lineHeight
    }
    , button: {
        width: 180
        , padding: "10px 20px"
        , [theme.breakpoints.down("sm")]: {
            width: 135
            , padding: "5px 10px"
        }
    }
    , listItemAvatar: {
        textAlign: "right"
    }
}));

export default function SearchList(props: {
    data: Set<string>,
    type: ListType,
    onChange(state: Set<string>): void,
    onSubmenuClicked?(item: GroupItemWithNextStep): void
}) {

    const classes = useStyles();
    let [search, set_search, itemList, loading, error] = useSearchWorker(props.type);
    const [Items, itemsAreLoading, fetchFlowError] = fetchFlowData(props.type);

    if (itemsAreLoading) itemList = new Array(10).fill({
        primaryText: <Skeleton />
        , isSkeleton: true
    });

    if (error) return <ErrorScreen {...error} />;
    if (fetchFlowError) return <ErrorScreen {...fetchFlowError} />;

    const addItem = function (item: GroupItem) {
        if ("nextStep" in item && props.onSubmenuClicked)
            return props.onSubmenuClicked(item);

        if (props.data.has(item.id)) return;
        const new_state = new Set(props.data);
        new_state.add(item.id);
        props.onChange(new_state);
    }

    const removeItem = function (item: GroupItem) {
        if ("nextStep" in item && props.onSubmenuClicked)
            return props.onSubmenuClicked(item);
        if (!props.data.has(item.id)) return;
        const new_state = new Set(props.data);
        new_state.delete(item.id);
        props.onChange(new_state);
    }

    const selectedIds: Array<string> = Array.from(props.data.values());
    selectedIds.push(
        // Se agregan items que NO esten incluidos, pero sin embargo tienen SUB-ITEMS que si!
        ...Items.filter(item => !selectedIds.includes(item.id) && findMatchesInsideItem(item, props.data).length > 0).map(x => x.id)
    );
    const selectedData = selectedIds.map(id => Items.find(x => x.id === id)).filter(x => !!x) as GroupItem[];


    return (<div>
        <SearchItemInput
            onTextChange={set_search}
            value={search}
            loading={loading || itemsAreLoading}
        />
        <ListItem className={classes.badgeRow} style={selectedData.length
            ? undefined
            : { borderTop: "none", paddingTop: 0, paddingBottom: 0, minHeight: 7 }}
        >
            {selectedData.map(item => (<Chip
                style={{
                    marginLeft: 3
                    , marginRight: 3
                    , whiteSpace: "nowrap"
                    , overflow: "hidden !important"
                    , textOverflow: "ellipsis"
                }}
                classes={{
                    labelSmall: classes.badgeLabel
                }}
                deleteIcon={<img src={`${process.env.PUBLIC_URL}/svg/white-close-button.svg`} style={{ marginRight: 5, height: 7 }} />}
                onDelete={() => removeItem(item)}
                color="primary"
                key={item.id}
                className={classes.badge}
                size="small"
                label={item.primaryText}
                onClick={() => removeItem(item)}
                variant="outlined"
            />))}
        </ListItem>

        <List style={{ paddingTop: 0 }}>
            {itemList.map((item, index) => (<ListItem
                key={"isSkeleton" in item ? ("skeleton_idx_line_" + index) : item.id}
                style={{ borderBottom: BORDER_STYLE }}
                dense
                button
                onClick={() => {
                    if ("isSkeleton" in item) return;
                    if (selectedIds.includes(item.id)) {
                        removeItem(item);
                    } else {
                        addItem(item);
                    }
                }}>
                <ListItemText primary={
                    item.match
                        ? (<span dangerouslySetInnerHTML={{ __html: item.match }} />)
                        : item.primaryText
                }
                    primaryTypographyProps={{
                        variant: selectedIds.includes(item.id) ? "body1" : "caption",
                        style: {
                            color: selectedIds.includes(item.id) ? "#0087ff" : "#222222"
                        },
                        className: classes.text
                    }} />
                <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
                    <Checkbox
                        size="small"
                        color="primary"
                        disabled={"isSkeleton" in item}
                        checked={selectedIds.includes(item.id)}
                        onChange={ev => {
                            if (ev.target.checked) {
                                addItem(item);
                            } else {
                                removeItem(item);
                            }
                        }}
                    />
                </ListItemAvatar>
            </ListItem>))}
        </List>
    </div>);
}

