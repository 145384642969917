import { TextField, Typography } from "@material-ui/core";
import React from "react";
import BackIcon from "../../../Components/Icons/BackIcon";
import InfoDialog from "../../../Components/InfoDialog";
import TitleInfoDialog from "../../../Components/TitleInfoDialog";
import SubmitButton from "../Submit-Button";
import RejectedMarcaRecomendaciones from "./EditMarcaDialog/RejectedMarcaRecomendaciones";

export default function (props: {
    onClose?(): void;
    onCompleted(value: string): void;
    open: boolean;
    previousValue?: string;
    reason?: string;
    loading?: boolean;
    avoidConfirm?: boolean;
    children?: React.ReactNode
}) {

    const [value, set_value] = React.useState("");
    const [error, set_error] = React.useState("");
    const [step, set_step] = React.useState<"" | "CONFIRM">("");

    React.useEffect(() => {
        if (props.open) {
            set_error("");
            set_value("");
            set_step("");
        }
    }, [props.open]);


    const onSubmit = function () {
        const prev = (props.previousValue || "").toString().trim().toUpperCase();
        const current = (value || "").toString().trim().toUpperCase();
        if (!current)
            return set_error("Digite la nueva marca");

        if (current === prev)
            return set_error("La marca debe ser diferente a la anterior");

        if (step === "CONFIRM" || props.avoidConfirm)
            return props.onCompleted(value.trim());

        set_step("CONFIRM");
    };
    return (<InfoDialog
        isOpen={props.open}
        maxWidth="sm"
        onClose={props.onClose}
    >
        <form
            style={{ marginBottom: 30 }}
            onSubmit={event => {
                event.preventDefault();
                onSubmit();
            }}>
            {step === "CONFIRM"
                ? <Typography color="primary" style={{ marginBottom: 15 }} variant="caption" component="a" onClick={() => props.loading ? null : set_step("")} ><BackIcon /> Atrás</Typography>
                : null}
            <TitleInfoDialog style={{ marginBottom: 20 }} >Ingresar nueva marca</TitleInfoDialog>
            {step === ""
                ? (<>
                    {(props.reason && step === "")
                        ? <RejectedMarcaRecomendaciones reason={props.reason as any} />
                        : null}
                    <TextField
                        variant="outlined"
                        color="secondary"
                        value={value}
                        disabled={props.loading}
                        onChange={event => set_value(event.target.value)}
                        error={!!error}
                        helperText={error || undefined}
                        label="Ingresa tu nueva marca aquí"
                        style={{ marginBottom: 15 }}
                    />
                </>)
                : null}

            {step === "CONFIRM"
                ? <>
                    <div style={{ marginTop: 20, marginBottom: 25 }} >
                        <Typography style={{ display: "inline-block" }} variant="body1" color="secondary">La nueva marca ingresada es:</Typography>
                        <br />
                        <br />
                        <Typography style={{ display: "block" }} variant="body2" color="secondary">{value}</Typography>
                    </div>
                </>
                : null}

            {props.children}

            <SubmitButton fullWidth isLoading={props.loading} onSubmit={onSubmit}>
                {step === ""
                    ? "Continuar"
                    : "Confirmar"}
            </SubmitButton>
        </form>
    </InfoDialog >);
}