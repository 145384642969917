import MultilineTextToComponent from "../../../../Components/MultilineTextToComponent";
import { Typography } from "@material-ui/core";
import Line from "../../../../Components/Line";

const reasons = require("./rejected-reasons.json");

export default function (props: { reason: keyof typeof reasons }) {
    return <>
        <Typography variant="body2" color="secondary" style={{ marginTop: 30 }} >Instrucciones:</Typography>
        <div style={{ marginTop: 20, marginBottom: 25 }} >
            <MultilineTextToComponent input={reasons[props.reason]}>
                {({ text }) => <Typography variant="caption" style={{ fontFamily: "Helvetica Now Text Regular", lineHeight: "inherit" }} component="p">{text}</Typography>}
            </MultilineTextToComponent>
        </div>
        <Line size={1} />
        <Typography variant="body2" color="secondary" style={{ marginTop: 25, marginBottom: 15 }}>Nueva marca:</Typography>
    </>
}