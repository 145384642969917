import { withStyles } from "@material-ui/styles";
import { HOVER_COLOR } from "./colors";


export default withStyles(() => ({
    "@global": {
        ".MuiListItem-button:hover": {
            backgroundColor: HOVER_COLOR
        }
    }
}))(() => null);