import React from "react";
import { Grid, Typography } from "@material-ui/core";
import LandingLogo from "../LandingPage/LandingLogo";
import Header from "./header";
import useStyles from "./index.style";
import useBreakpoints from "../../Components/Hooks/useBreakpoints";
import VericalCenter from "./VericalCenter";

export default function (props: {
    leftContent?: React.ReactNode,
    children: React.ReactNode,
    rightFootContent: React.ReactNode,
    showCloseBtn?: boolean,
    title?: string | null,
    subtitle?: string | null,
    titleIconSrc?: string,
    onSubmit?(): void,
    classes?: {
        left_content?: string
    }
    scrollContainerRef?: React.Ref<HTMLDivElement>
}) {
    const classes = useStyles();
    const { xsDown } = useBreakpoints();
    return (
        <Grid ref={xsDown ? props.scrollContainerRef : undefined} container className={[classes.root, "full-height"].join(" ")}>
            <Grid item sm={5} className={[classes.leftSide_root, props.classes?.left_content || ""].join(" ")} >
                <div className={classes.innerContainer}>
                    <div className="header">
                        {xsDown ? <Header xsDownItem={props.showCloseBtn ? "close" : "logo"} /> : <LandingLogo />}
                    </div>
                    <VericalCenter style={{ padding: 20 }} >
                        {props.leftContent}
                        {props.titleIconSrc && <img title="Title Icon" src={props.titleIconSrc} height={80} width={80} />}
                        {props.title && <Typography className={classes.title} variant="h2">{props.title}</Typography>}
                        {props.subtitle && (<div style={{ marginTop: 5 }}>
                            {props.subtitle ? (<Typography variant="body1" className="md" >{props.subtitle}</Typography>) : null}
                        </div>)}
                    </VericalCenter>
                </div>
            </Grid>
            <Grid item sm={7} className={classes.rightSide_root} >
                <form onSubmit={event => {
                    event.preventDefault();
                    props.onSubmit && props.onSubmit();

                }} className={classes.innerContainer}>
                    {xsDown ? null : <Header xsDownItem={props.showCloseBtn ? "close" : "logo"}  />}
                    <div style={{ height: "100%", overflow: "auto", padding: 20 }} ref={xsDown ? undefined : props.scrollContainerRef} >
                        {props.children}
                    </div>

                    {props.rightFootContent && <>
                        <div className={["footer", classes.footer].join(" ")}>
                            {props.rightFootContent}
                        </div>
                        {xsDown ? <div className={classes.footer} ></div> : null}
                    </>}
                </form>
            </Grid>
        </Grid>);
}