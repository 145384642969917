import { Typography, Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import useBreakpoints from "../../Components/Hooks/useBreakpoints";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: 40,
        [theme.breakpoints.down("xs")]: {
            paddingTop: 25
        }
    },
    grid_root: {
        paddingRight: 30
        , [theme.breakpoints.up("md")]: {
            marginBottom: 5
        }
    },
    img: {
        width: "100%"
        , height: 220
        , backgroundPositionX: "50%"
        , backgroundPositionY: "50%"
        , backgroundSize: "122%"
        , backgroundRepeat: "no-repeat"
        , [theme.breakpoints.down("xs")]: {
            height: 130
        }
    },
    text: {
        marginTop: 14
    },
    gridContainer: {
        paddingTop: 40,
        [theme.breakpoints.down("xs")]: {
            paddingTop: 25
        }
    },
    priceText: {
        textDecoration: "line-through",
        display: "inline-block",
        marginTop: 5
    }
}));

export default function () {

    const classes = useStyles();
    const { xsDown } = useBreakpoints();

    return (<div className={classes.root}>
        <Typography variant="h5">Pack Marca Registrada</Typography>

        <Grid container className={classes.gridContainer} spacing={xsDown ? 2 : 3} >
            <ImgItem imgNumber={1} title="Certificado de Registro">
                Una vez que tu marca ya se encuentre registrada, realizaremos el tramite y pago del Certificado de Título de Registro.<br />
                El cual te te enviaremos directamente a tu correo electrónico, para que puedas utilizarlo desde el primer momento.<br />
                <Price>$47.000</Price>&nbsp;<Gratis />
            </ImgItem>
            <ImgItem imgNumber={2} title="Defensa a observaciones de fondo">
            Realizaremos el análisis legal, redacción y presentación de escritos de defensa a cualquier observación de fondo.<br />
            Contestaciones hechas por especialistas. Nos encargaremos de defender tu marca... Esto es lo que hacemos!<br />
                <Price>$447.000</Price>&nbsp;<Gratis />
            </ImgItem>
            <ImgItem imgNumber={3} title="Cartas para cese de uso">
                Podrás notificar formalmente a quien sea que utilice tu marca sin tu autorización.<br />
                Te entregamos el material que usamos en nuestros procesos para que los utilices cuando y como quieras!<br />
                <Price>$97.000</Price>&nbsp;<Gratis />
            </ImgItem>
            <ImgItem imgNumber={4} title="Contratos para marca registrada">
                Contratos para transferencia, venta, licencia o franquicia. Tu marca se transforma en un bien intangible con el cual puedes tener múltiples beneficios a futuro. Copia y pega usando nuestros mejores documentos.<br />
                <Price>$487.000</Price>&nbsp;<Gratis />
            </ImgItem>
            <ImgItem imgNumber={5} title="Simple Asistencia por 10 años">
                Recibe asesoría legal de expertos líderes en la industria para todo lo referido a tu Marca Registrada por los 10 primeros años que dura tu registro.<br />
                Aquí encontrarás las respuestas para todo tipo situaciones.<br />
                <Price>$389.900</Price>&nbsp;<Gratis />
            </ImgItem>
            <ImgItem imgNumber={6} title="Gastos de Publicación + Notariales">
                Pago completo del costo de publicación de la solicitud en el Diario Oficial de la República De Chile.<br />
                Y pago de todos los gastos notariales que podrán ser requeridos en el ingreso de la solicitud.<br />
                <Price>$20.900</Price>&nbsp;<Gratis />
            </ImgItem>
        </Grid>
    </div >);
}


function Price(props: {
    children: React.ReactNode
}) {
    const classes = useStyles();
    return <Typography className={classes.priceText} variant="body2" component="small">{props.children}</Typography>
}

function Gratis() {
    return (<Typography {...{ variant: "body2", component: "small", style: { color: "#008a05" } }}>GRATIS</Typography>);
}


function ImgItem({ imgNumber, children, title }: { imgNumber: number, children: React.ReactNode, title: string }) {
    const classes = useStyles();
    return (<Grid item sm={3} xs={6} className={classes.grid_root}>
        <div className={classes.img} style={{ backgroundImage: `url(${process.env.PUBLIC_URL + `/img/bono-${imgNumber}.jpg`})` }} />
        <div style={{ marginTop: 10 }}>
            <Typography variant="body1" component="small" color="secondary" className={classes.text} >
                <Typography variant="body2" color="secondary">{title}</Typography>
                <Typography style={{ marginTop: 5 }} variant="caption" component="p" >{children}</Typography>
            </Typography>
        </div>
    </Grid>);
}
