import { withStyles } from "@material-ui/styles";
import { BORDER_COLOR } from "./colors";


export default withStyles(theme => ({
    "@global": {

        ".MuiInputBase-root.MuiOutlinedInput-root, .MuiOutlinedInput-borderRadius": {
            borderRadius: 10
        },

        ".MuiInputBase-root.MuiOutlinedInput-root.Mui-focused > fieldset": {
            borderWidth: "2px !important",
            top: -5
        },
        ".MuiOutlinedInput-colorSecondary": {
            "& > .MuiOutlinedInput-input": {
                paddingTop: 25,
                paddingBottom: 10
            },
            "& > fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: BORDER_COLOR
            }
        },

        ".MuiInputBase-input::placeholder": {
            fontFamily: "Poppins"
        },
        ".MuiInputLabel-outlined.MuiFormLabel-colorSecondary.MuiInputLabel-formControl": {
            fontFamily: "Poppins"
        },
        ".MuiInputLabel-outlined.MuiFormLabel-colorSecondary.MuiInputLabel-formControl.MuiInputLabel-shrink": {
            transform: "translate(14px, 10px) scale(0.75)"
        },

    }
}))(() => null);