import React from "react";
import InfoDialog from "../../Components/InfoDialog";
import { Button, List, ListItem, makeStyles, Typography, Theme, ListItemText, ListItemIcon } from "@material-ui/core";
import NotificationDiv from "../../Components/Controls/NotificationDiv";
import { BORDER_STYLE, HOVER_COLOR } from "../../Theme/colors";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: "flex"
        , width: "100%"
        , flexDirection: "row"
        , [theme.breakpoints.down("xs")]: {
            display: "block"
            , "& > button": {
                marginTop: 10
            }
        }
    }
    , list: {
        backgroundColor: HOVER_COLOR
        , "& > li:not(:last-child)": {
            borderBottom: BORDER_STYLE
        }
        , padding: 0
        , marginLeft: -33
        , paddingLeft: 33
        , marginRight: -33
        , paddingRight: 33
    }
}));

export default function LiabilityInfo(props: {
    results: SearchResult[]
    , code?: "descriptiva" | string
    , percentage?: string
    , onEditButtonClicked?(): void
}) {
    const [open, set_open] = React.useState(false);

    var title = "PreciseResult™";
    var message = props.percentage === "NONE" ? "No se han encontrado marcas similares" : "Encuentra el detalle de tu búsqueda aquí.";

    const closeDialog = () => set_open(false);

    const iconSrc = `${process.env.PUBLIC_URL}/img/ai-static.png`;
    const classes = useStyles();
    return (<>
        <NotificationDiv iconSrc={iconSrc} iconHeight={30}>
            <div className={classes.content}>
                <div style={{ flex: 1, flexGrow: 1 }} >
                    <Typography variant="body2">{title}</Typography>
                    <Typography variant="caption" component="p">{message}</Typography>
                </div>
                {props.percentage === "NONE"
                    ? null
                    : (<Button style={{ minWidth: 120, maxWidth: 120, minHeight: 45, backgroundColor: "white", borderColor: "black" }} type="button" onClick={() => set_open(true)} variant="outlined" color="secondary">
                        Ver más
                    </Button>)}
            </div>
        </NotificationDiv >
        <InfoDialog title={<>
            <img src={iconSrc} style={{ height: ".89em", width: "auto", marginRight: 10 }} title="Icon" />
            {title}
        </>} isOpen={open} onClose={closeDialog} >
            <div style={{ paddingBottom: 50 }} >
                <div style={{ marginBottom: 15 }}>
                    <Typography variant="body2" color="secondary" className="c-303030" component="small" >
                        {props.code === "descriptiva" ? "Riesgo de no ser Distintiva." : "Resultado."}
                    </Typography>
                </div>
                <Typography variant="caption" component="p">
                    {props.code === "descriptiva"
                        ? <>
                            Antes de Solicitar el Registro de la marca, es importante que verifiques que tu marca contenga algo que la diferencie
                            (puede ser una sigla, nombre o una palabra que no sea de uso común dentro del rubro de tu marca),
                            ya que por este motivo tu solicitud podría ser rechazada.
                        </>
                        : <>
                            El buscador ha encontrado <i>posibles</i> marcas similares que debes revisar.<br /><br />
                            Antes de solicitar tu registro asegúrate que estos nombres no se confundan con tu marca.<br /><br />
                            NOTA IMPORTANTE: Este listado es una búsqueda de similitudes y <strong style={{ color: "#081d34" }}>no necesariamente</strong> pondrá en riesgo tu proceso de registro.
                        </>}
                </Typography>
                {props.results.length
                    ? (<>
                        <div style={{ marginBottom: 10 }}>
                            <Typography variant="body2" color="secondary">
                                <br />
                                Marcas similares ya registradas:
                            </Typography>
                        </div>
                        <List className={classes.list}>
                            <ListItem dense>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: "body2"
                                        , color: "secondary"
                                        , component: "small"
                                    }}
                                    primary={"Marca similiar"}
                                />
                                <ListItemIcon>
                                    <Typography variant="body2" color="secondary" component="small">
                                        N°
                                    </Typography>
                                </ListItemIcon>
                            </ListItem>
                            {props.results.map((item, index) => (<ListItem key={index} dense>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: "body1", component: "small", color: "secondary"
                                    }}
                                    primary={item.marca}
                                />
                                <ListItemIcon>
                                    <Typography variant="body1" component="small" color="secondary">{item.numeroSolicitud}</Typography>
                                </ListItemIcon>
                            </ListItem>))}
                        </List>
                    </>)
                    : null}
            </div>
        </InfoDialog >
    </>);
}