import { makeStyles } from "@material-ui/core";
import React from "react";
import InfoDialog from "../../Components/InfoDialog";
import { GetFlow, GetHeadings } from "../Item-Selection-Flow/Flow-templates";
import SearchList from "../Item-Selection-Flow/Search-List";
import SelectGroup from "../Item-Selection-Flow/Select-Group";
import Footer from "../Item-Selection-Flow/Footer";
import { BORDER_STYLE } from "../../Theme/colors";


const useStyles = makeStyles((theme) => ({
    content: {
        [theme.breakpoints.up("sm")]: {
            paddingLeft: 30,
            paddingRight: 30,
        }
    }
    , footer_container: {
        borderTop: BORDER_STYLE
    }
    , footer_content: {
        paddingTop: 10,
        paddingBottom: 10,
        width: "100%"
    }
}));

export default function SelectItemDialog(props: {
    // si la propiedad `showListName` está llena se muestra el popup, caso contrario se cierra
    showListName: ListType | GroupType | "root-group" | null
    onClose(selectedIds?: Set<string>): void
    selectedIds: Set<string>
}) {

    const [mainListName, set_mainListName] = React.useState(props.showListName);
    const [mainSelectedIds, set_mainSelectedIds] = React.useState<Set<string>>(new Set(props.selectedIds));
    /** Mientras mas pasos tenga, mejor! */
    const [sub_steps, set_sub_steps] = React.useState<{
        itemId: string
        , active: boolean
        , nextStep: GroupType | ListType
        , selectedIds: Set<string>
    }[]>([]);

    React.useEffect(() => {
        // si se va a quedar el nombre vacio, mantiene el nombre antiguo.
        // esto es para que al cerrar el popup, no se cierre en blanco la pantalla
        if (props.showListName) {
            set_mainListName(props.showListName);
            set_mainSelectedIds(props.selectedIds);
            set_sub_steps([]);
        }
    }, [props.showListName]);

    var activeSubStep = sub_steps[sub_steps.length - 1];
    const listName = activeSubStep?.nextStep || mainListName;
    const selectedIds = activeSubStep?.selectedIds || mainSelectedIds;
    const set_selectedIds = function (selectedIds: Set<string>) {
        if (activeSubStep) {
            const new_state_step = { ...activeSubStep, selectedIds };
            const new_step_group = [...sub_steps];
            var idx = new_step_group.indexOf(activeSubStep);
            if (idx < 0) return;
            new_step_group[idx] = new_state_step;
            set_sub_steps(new_step_group);
        } else {
            set_mainSelectedIds(selectedIds);
        }
    }

    const headings = listName ? GetHeadings(listName) : { title: "Selecciona una opción" };
    const classes = useStyles();
    return (<InfoDialog maxWidth="sm"
        classes={{
            paper: "max-full-screen"
            , dialogContent: classes.content
            , dialogActions: classes.footer_container
        }} isOpen={!!props.showListName} onClose={() => props.onClose()} title={headings.title}
        actions={<Footer
            borderTopComponent="line"
            progress={0}
            classes={{
                root: classes.footer_content
            }}
            backBtnText={activeSubStep ? "Regresar" : "Cancelar"}
            nextBtnText={"Aceptar"}
            goBack={() => {
                if (!activeSubStep) return props.onClose();
                const new_sub_steps = [...sub_steps];
                new_sub_steps.splice(new_sub_steps.indexOf(activeSubStep!), 1);
                set_sub_steps(new_sub_steps);
                window.scrollTo(0, 0);
            }}
            goNext={() => {
                const new_main_selected_ids = new Set(selectedIds);
                if (!activeSubStep) return props.onClose(new_main_selected_ids);
                const new_sub_steps = [...sub_steps];
                new_sub_steps.splice(new_sub_steps.indexOf(activeSubStep!), 1);
                /** Limpio los registros anteriores en pantalla */
                GetFlow(activeSubStep.nextStep).forEach(item => {
                    if (new_main_selected_ids.hasOwnProperty(item.id))
                        delete new_main_selected_ids[item.id];
                });
                activeSubStep.selectedIds.forEach(sub_selected_id => {
                    new_main_selected_ids.add(sub_selected_id);
                })
                if (!new_sub_steps.length) return props.onClose(new_main_selected_ids);
                set_mainSelectedIds(new_main_selected_ids);
                set_sub_steps(new_sub_steps);
                window.scrollTo(0, 0);
            }}
            canGoNext={true}
        />}
    >
        {listName?.endsWith("group")
            ? <SelectGroup
                type={listName as GroupType}
                key={listName}
                data={selectedIds}
                onChange={set_selectedIds}
                onSubmenuClicked={item => {
                    // si encuentra un sub-grupo, lo llama en vez de continuar!
                    set_sub_steps([...sub_steps, {
                        itemId: item.id,
                        nextStep: item.nextStep!,
                        active: true,
                        /** 
                         * Regenero el estado de la sub-lista,
                         * buscando que items pertenecen a ella en `mainSleectedIds`
                        */
                        selectedIds: new Set(mainSelectedIds)
                    }]);
                }}
            />
            : null}
        {listName?.endsWith("list")
            ? <SearchList
                type={listName as ListType}
                key={listName}
                data={selectedIds}
                onSubmenuClicked={item => {
                    // si encuentra un sub-grupo, lo llama en vez de continuar!
                    set_sub_steps([...sub_steps, {
                        itemId: item.id,
                        nextStep: item.nextStep,
                        active: true,
                        /** 
                         * Regenero el estado de la sub-lista,
                         * buscando que items pertenecen a ella en `mainSleectedIds`
                        */
                        selectedIds: new Set(GetFlow(item.nextStep)
                            .filter(x => mainSelectedIds.hasOwnProperty(x.id) && !("nextStep" in x))
                            .map(a => a.id))
                    }]);
                }}
                onChange={set_selectedIds}
            />
            : null}
    </InfoDialog>)
}