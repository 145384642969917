import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    img: {
        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    imgExpanded: {
        transform: "rotate(180deg)"
    }
})
export default function (props: {
    invert?: boolean,
    className?: string,
    style?: React.CSSProperties
}) {
    const classes = useStyles();
    return (<img
        className={[classes.img, props.invert ? classes.imgExpanded : "", props.className || ""].join(" ")}
        style={props.style}
        src={`${process.env.PUBLIC_URL}/svg/black-arrow.svg`}
        height={15}
    />);
}