import { Button, LinearProgress, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { BORDER_COLOR } from "../../Theme/colors";
import Line from "src/Components/Line";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex"
        , flexDirection: "row"
        , alignItems: "center"
        , background: "transparent"
        , padding: "20px 50px"
        , boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.07)"
        , [theme.breakpoints.down("xs")]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 15,
            paddingBottom: 15
        }
    },
    right_side: {
        flex: 1,
        flexGrow: 1,
        textAlign: "right"
    },
    btn_group: {
        "& button": {
            display: "inline-block",
        },
        "& .button(:last-child)": {
            marginLeft: 20
        }
    },
    progressBar: {
        height: 3
        , backgroundColor: BORDER_COLOR
        , [theme.breakpoints.down("xs")]: {
            height: 2
        }
    }
}))

export default function (props: {
    progress?: number;
    goBack?(): void;
    goNext?(): void;
    canGoNext?: boolean;
    isLoading?: boolean;
    borderTopComponent?: "progress_bar" | "line";
    backBtnText?: React.ReactNode;
    nextBtnText?: React.ReactNode;
    classes?: { root?: string };
}) {
    const classes = useStyles();
    return (<>
        {props.borderTopComponent === "line" ? <Line /> : null}
        {props.borderTopComponent === "progress_bar"
            ? <div style={{ display: "flex", flexDirection: "row" }}>
                <LinearProgress style={{ width: "20vw" }} className={classes.progressBar} color="secondary" variant="determinate" value={100} />
                <LinearProgress style={{ flex: 1, flexGrow: 1 }} className={classes.progressBar} color="secondary" variant="determinate" value={props.progress} />
            </div>
            : null}
        <div className={[classes.root, props?.classes?.root || ""].join(" ")}>
            <div>
                <Button
                    disabled={props.isLoading}
                    style={{ visibility: props.goBack ? "visible" : "hidden" }}
                    type="button"
                    variant="text"
                    color="secondary"
                    component="a"
                    onClick={props.goBack}>
                    {props.backBtnText || "Atrás"}
                </Button>
            </div>
            <div className={classes.right_side}>
                <div className={classes.btn_group}>
                    <Button
                        disabled={props.isLoading || (!props.canGoNext)}
                        type={props.goNext ? "button" : "submit"}
                        onClick={props.goNext}
                        variant="contained"
                        color="primary"
                        size="medium"
                        className="small"
                    >
                        {props.nextBtnText || "Siguiente"}
                    </Button>
                </div>
            </div>
        </div>
    </>
    );
}