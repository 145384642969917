import React from "react";
import { Button, IconButton, Theme, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import QuestionDialog from "./QuestionDialog";
import useBreakpoints from "../../Components/Hooks/useBreakpoints";
import CloseIcon from "../../Components/Icons/CloseIcon";
import useNavigateToStep from "../../Components/Hooks/useNavigateToStep";
import LandingLogo from "../LandingPage/LandingLogo";
import CallSales from "./CallSales";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex"
        , flexDirection: "row"
        , alignItems: "center"
        , padding: 50
        , paddingTop: 30
        , paddingBottom: 20
        , [theme.breakpoints.down("xs")]: {
            padding: 15
        }
    },
    right_side: {
        flex: 1,
        flexGrow: 1,
        textAlign: "right"
    },
    btn_group: {
        "& button": {
            display: "inline-block",
            marginLeft: 10
        }
    },
    btn: {
        borderRadius: 7,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        borderColor: "#d9dbdc",
        minWidth: "auto !important"
    }
}))

export default function (props: {
    xsDownItem?: "logo" | "close"
}) {

    const { navigateToFlow } = useNavigateToStep();
    const classes = useStyles();
    const { xsDown } = useBreakpoints();
    const [qIsOpen, set_qIsOpen] = React.useState(false);
    var xsDownItem;
    if (props.xsDownItem === "close")
        xsDownItem = (<IconButton type="button" onClick={() => navigateToFlow()}>
            <CloseIcon />
        </IconButton>);

    if (props.xsDownItem === "logo")
        xsDownItem = (<LandingLogo style={{ marginLeft: 5 }} />);
    return (<div className={classes.root}>
        <QuestionDialog onClose={() => set_qIsOpen(false)} isOpen={qIsOpen} />
        {xsDown ? xsDownItem : <LandingLogo />}
        <div className={classes.right_side} >
            <div className={classes.btn_group} >
                <CallSales />
                {xsDown
                    ? null
                    : (<Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        className={classes.btn}
                        type="button"
                        onClick={() => window.open('/live-chat.html', '', 'width = 445, height = 700')}
                    >
                        <Typography component="span" variant="body2" style={{ fontSize: 12 }}>
                            Ayuda
                        </Typography>
                    </Button>)}
                {
                    (!xsDown && props.xsDownItem === "close")
                        ? (<Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className={classes.btn}
                            type="button"
                            onClick={() => navigateToFlow()}
                        >
                            <Typography variant="body2" style={{ fontSize: 12 }}>
                                Salir
                            </Typography>
                        </Button>)
                        : null}
            </div>
        </div>
    </div>);
}