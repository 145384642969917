import { FirebaseError } from "firebase/app";
import { signInWithPopup } from "firebase/auth";
import React from "react";
import { FirebaseErrorMessage } from "../../lib/FirebaseAuthError";
import { AuthInstance, googleAuthProvider } from "../../lib/FirebaseConfig";
import ButtonWithIcon from "./ButtonWithIcon";

export default function (props: {
    onCompleted(): void;
    onError(ex: Error, origin: "GOOGLE"): void;
    onStart(): void;
    disabled?: boolean;
    isLoading?: boolean;
}) {

    const onClick = React.useCallback(async function () {
        if (props.disabled || props.isLoading) return;
        props.onStart();
        signInWithPopup(AuthInstance, googleAuthProvider)
            .then(props.onCompleted)
            .catch(ex => {
                console.error("GoogleLoginButton signInWithPopup error ", ex);
                if (ex instanceof FirebaseError)
                    ex.message = FirebaseErrorMessage(ex);
                props.onError(ex, "GOOGLE");
            });
    }, []);

    return (
        <ButtonWithIcon
            iconSrc="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
            text="Continuar con Google"
            onClick={onClick}
            disabled={props.disabled}
            isLoading={props.isLoading}
        />)
}