import React from "react";
import { BORDER_COLOR } from "../Theme/colors";

export default function ({ size, style, use }: { size?: number, style?: React.CSSProperties, use?: "header" | "separator" }) {
    return <hr style={{
        backgroundColor: use === "header" ? "#ebf0f6" : BORDER_COLOR
        , margin: 0
        , border: "none"
        , height: size || 1
        , ...(style || {})
    }} />
}