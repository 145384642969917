import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Resumen from "../../API/Resumen";
import { useAppContext } from "../../Components/Context/AppContext";
import useNavigateToStep from "../../Components/Hooks/useNavigateToStep";
import { SEARCH_PARAM_KEYS } from "../Flow.v2/FlowState";


type RiskAnalysis = Awaited<ReturnType<ReturnType<typeof useAppContext>["API"]["setSearch"]>>["search"]["riskAnalysis"];

export default function useOrderSummaryState() {

    const { API, user, anonymous_activity_email } = useAppContext();
    const { navigateToSubmit, navigateToFlow } = useNavigateToStep();
    const navigate = useNavigate();
    const [searchParams, set_searchParams] = useSearchParams();

    const [status, set_status] = React.useState<"" | "submit" | "error" | "quoting" | "completed" | "initializing">("initializing");
    const [error_message, set_error_message] = React.useState<string | null>(null);
    const [id, set_id] = React.useState(searchParams.get(SEARCH_PARAM_KEYS.ID) || "");
    const [selected_clases, set_selected_clases] = React.useState<Set<number>>(new Set());

    const [marca, set_marca] = React.useState(searchParams.get(SEARCH_PARAM_KEYS.MARCA));
    const [detalle_clases, set_detalle_clases] = React.useState<Lead["detalle_clases"]>({});
    const [resumen, set_resumen] = React.useState(Resumen() as any);
    const [payments, set_payments] = React.useState([] as Payment[]);
    const [riskAnalysis, set_riskAnalysis] = React.useState<RiskAnalysis>({
        risk_probability: "",
        code: "",
        coincidences: []
    });

    const isInitializing = status === "initializing";
    /** SI no hay marca, ni clase, ni nada, deberia regresar al flujo, al inicio del flujo! */
    React.useEffect(() => {
        if (!isInitializing) return;
        const itemIds_in_query = (searchParams.get(SEARCH_PARAM_KEYS.ITEMS) || "").split(",");
        const startup = new Date().valueOf()
        API.setSearch({ // creacion/consulta especial por parametros extras
            marca: searchParams.get(SEARCH_PARAM_KEYS.MARCA) || undefined
            , itemIds: itemIds_in_query.length ? itemIds_in_query : undefined
            , id: id || undefined
            , email: searchParams.get("email") || undefined
            , promo_code: searchParams.get("promo_code") || undefined
        }).then(async data => {
            // si los items no estan en la URL, los agrego en pantalla
            if (!searchParams.get(SEARCH_PARAM_KEYS.ITEMS)) {
                searchParams.set(SEARCH_PARAM_KEYS.ITEMS, data.search.itemIds.join(","));
                set_searchParams(new URLSearchParams(searchParams.toString()));
            }
            // si la marac no está en la URL
            if (!searchParams.get(SEARCH_PARAM_KEYS.MARCA)) {
                searchParams.set(SEARCH_PARAM_KEYS.MARCA, data.marca);
                set_searchParams(new URLSearchParams(searchParams.toString()));
            }
            
            if (!id) {
                const diff = Math.ceil(new Date().valueOf() - startup);
                if (diff >= 7000)
                    return data;
                await new Promise<void>(res => {
                    setTimeout(() => res(), 7000 - diff)
                });
            }
            return data;
        }).then(({ id, marca, detalle_clases, resumen, search, payments }) => {
            searchParams.delete("_m");
            searchParams.set(SEARCH_PARAM_KEYS.ID, id);
            set_searchParams(new URLSearchParams(searchParams.toString()));
            set_id(id);

            set_marca(marca);
            set_detalle_clases(detalle_clases);
            set_resumen(resumen);
            set_payments(payments);
            set_riskAnalysis(search.riskAnalysis);
            set_status("");
            set_error_message(null);
        }).catch(ex => {
            if (ex.name === "TrademarkSearchMissingDataError" && ex.message?.includes("`itemIds`")) {
                let pathname = "/flow";
                if (ex.marca) {
                    searchParams.append(SEARCH_PARAM_KEYS.MARCA, ex.marca);
                    pathname = "/flow/pick"
                }
                return navigate({
                    pathname,
                    search: searchParams.toString()
                });
            }
            set_status("error");
            set_error_message(errorToFancyMessage(ex));
            console.error("API.setSearch", ex);
        });

    }, [status]);

    /** Re-inicializa `riskAnalysis` todo si ha cambiado `anonymous_activity_email` (recarga esa sección) */
    React.useEffect(() => {
        if (status !== "initializing" && anonymous_activity_email) {
            API.assignEmailToSearch(id).catch(ex => {
                console.error("API.assignEmailToSearch", ex);
            });
            const storedAnalysis = { ...riskAnalysis };
            set_riskAnalysis(null);
            setTimeout(() => {
                set_riskAnalysis(storedAnalysis);
            }, 1);
        }
    }, [anonymous_activity_email]);
    React.useEffect(() => {
        if (status !== "submit") return;
        API.checkoutSearch({
            selected_clases: Array.from(selected_clases)
            , id
        }).then(result => {
            navigateToSubmit(result.id);
        }).catch(ex => {
            set_status("error");
            set_error_message(errorToFancyMessage(ex));
            console.error("API.checkoutSearch", ex);
        });

    }, [status === "submit"]);

    React.useEffect(() => {
        if (status !== "quoting") return;
        API.GetQuote({
            selected_clases: Array.from(selected_clases), cuotas: 1
        }).then(({ resumen, payments }) => {
            set_resumen(resumen);
            set_payments(payments);
            set_status("");
            set_error_message(null);
        }).catch(ex => {
            set_status("error");
            set_error_message(errorToFancyMessage(ex));
            console.error("API.GetQuote", ex);
        });
    }, [status === "quoting"]);

    const isLoading = (status === "submit" || status === "quoting" || isInitializing);
    return {
        selected_clases,
        toggle_clase(claseId: number) {
            if (isLoading) return;
            if (selected_clases.has(claseId)) {
                selected_clases.delete(claseId)
            } else {
                selected_clases.add(claseId)
            }
            set_selected_clases(new Set(selected_clases));
            set_status("quoting");
        },
        detalle_clases,

        isLoading,
        isInitializing,
        error_message,

        request_identify: !(user || anonymous_activity_email),

        set_status,
        marca,
        id,
        can_go_back: !(
            // Object.values(clases).find(x => x.__custom_clases) || 
            !searchParams.has(SEARCH_PARAM_KEYS.ITEMS)
        ),
        goToEditMode() {
            if (!searchParams.has(SEARCH_PARAM_KEYS.ITEMS))
                return alert("No se puede ir atrás");
            navigateToFlow(id);
        },

        resumen,
        payments,
        riskAnalysis
    }
}


function errorToFancyMessage(ex) {
    if (ex.name === "Error" || !ex.name)
        return ex.message;
    const codmsg = []
    if (ex.code) codmsg.push(ex.code);
    if (ex.name && ex.name !== "TypeError") codmsg.push(ex.name);
    if (ex.status) codmsg.push(ex.status);
    return "Debido a la alta demanda estamos experimentando intermitencia en nuestros servicios, intenta actualizar la pagina en un momento. Cod " + (codmsg.join(".") || "0")
}