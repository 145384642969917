import { Typography, Button, TextField } from "@material-ui/core";
import React from "react";
import { useAppContext } from "../../../Components/Context/AppContext";
import InfoDialog from "../../../Components/InfoDialog";
import SubmitButton from "../Submit-Button";

export default function (props: PromoCodeFormProps) {
    const [open, set_open] = React.useState(false);
    return (<div style={{ marginTop: 20 }} >
        <Button
            variant="text"
            color="secondary"
            type="button"
            onClick={() => set_open(true)}
            style={{
                textDecoration: "underline"
                , fontFamily: "Helvetica Now Text Medium"
                , paddingLeft: 2
                , paddingRight: 2
            }}
        >
            Ingresa un cupón de descuento o giftcard.
        </Button>

        <PromoCodeForm
            {...props}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            open={open}
            onClose={() => set_open(false)}
        />
    </div>);
}

interface PromoCodeFormProps {
    onChange(data: {
        resumen: Lead["resumen"],
        payments: Lead["payments"],
        promo_code: string
    }): void;
    clases_seleccionadas: Array<string | number>;
    cuotas: number;
    id?: string;
    logo: boolean;

    defaultValue?: string;
}

function PromoCodeForm(props: PromoCodeFormProps & {
    open: boolean;
    onClose(): void;
}) {

    const { API } = useAppContext();
    const [value, set_value] = React.useState(props.defaultValue || "");
    const [status, set_status] = React.useState("");
    const [error, set_error] = React.useState("");

    const value_is_validated = !!(value && value.toString().trim().length > 6);
    const isLoading = status === "submit";
    const inputRef = React.useRef<any>(null);

    React.useEffect(() => {

        if (status !== "submit") return;

        API.GetQuote({
            selected_clases: props.clases_seleccionadas,
            logo: props.logo,
            cuotas: props.cuotas,
            id: props.id,
            promo_code: value
        }).then(result => {
            props.onChange({ ...result, promo_code: value });
            props.onClose();
        }).catch(ex => {
            set_status("ERROR");
            set_error(ex.message);
            if (inputRef && inputRef.current && inputRef.current.focus) inputRef.current.focus();
        });
    }, [status]);

    React.useEffect(() => {
        if (props.open) {
            set_value("");
            set_status("");
        }
    }, [props.open]);

    return (<InfoDialog
        isOpen={props.open}
        maxWidth="xs"
        onClose={() => {
            if (status === "submit") return;
            props.onClose();
        }}
        title="Digita el código"
    >
        <form
            style={{ marginBottom: 30 }}
            onSubmit={event => {
                event.preventDefault();
                set_status("submit");
            }}
        >
            <TextField
                type="text"
                value={value}
                onChange={event => set_value(event.target.value)}
                label="Escribe el código aquí"
                variant="outlined"
                color="secondary"
                autoFocus
                inputRef={inputRef}
                inputProps={{
                    maxLength: 20, style: {
                        textTransform: "uppercase"
                    }
                }}
                disabled={isLoading}
            />

            <div>
                {(error && error.length)
                    ? <Typography variant="body2" color="error">{error}</Typography>
                    : null}
            </div>

            <SubmitButton
                fullWidth
                isLoading={isLoading}
                disabled={!value_is_validated}
                onSubmit={() => set_status("submit")}
            >
                Continuar
            </SubmitButton>
        </form>
    </InfoDialog>);
}