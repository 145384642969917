import React from "react";
import { useAppContext } from "../Components/Context/AppContext";
import LoginDialog from "../Components/Login/LoginDialog";

const SEARCH_BUTTONS: string[] = (process.env.CLICK_TRIGGERS || "#click-trigger").split(",");
const USERNAME_TEXTS: string[] = (process.env.USERNAME_TEXTS || "p#username").split(",")

function Redirect() {
    window.location.href = "https://app.simplemarcas.cl/start-flow";
}


export default function () {
    const { initialized, user } = useAppContext();
    const [openLogin, set_openLogin] = React.useState(false);
    const [username_text_status, set_username_text_status] = React.useState<Array<[string, string]>>([]);

    React.useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            console.info("Context status", { user, initialized });
            console.info("Env variables", process.env.CLICK_TRIGGERS, process.env.USERNAME_TEXTS);
        }
        if (!initialized) {
            SEARCH_BUTTONS.forEach(id => {
                const button = document.querySelector(id);
                if (!button) return;
                button.setAttribute("disabled", "disabled");
                button.removeAttribute("onclick");
            });
            const text_status: typeof username_text_status = [];
            USERNAME_TEXTS.forEach(selector => {
                const div = document.querySelector<HTMLButtonElement>(selector);
                if (!div) return;
                text_status.push([selector, div.innerText]);
                div.innerText = "Cargando...";
            });
            set_username_text_status(text_status);
            return;
        }

        SEARCH_BUTTONS.forEach(selector => {
            const button = document.querySelector<HTMLButtonElement>(selector);
            if (!button) return;
            button.removeAttribute("disabled");
            button.removeAttribute("onclick");
        });
        USERNAME_TEXTS.forEach(selector => {
            const div = document.querySelector<HTMLButtonElement>(selector);
            if (!div) return;
            if (user) {
                div.innerHTML = user.displayName;
            } else {
                const prevStatus = username_text_status.find(items => items[0] === selector);
                div.innerText = (prevStatus && prevStatus[1]) || "Iniciar sesión";
            }
        });

        var listeners: Function[] = [];

        SEARCH_BUTTONS.map(id => {
            const button = document.querySelector(id);
            if (!button) return;
            var clickFn = (event: Event) => {
                event.preventDefault();
                if (user) return Redirect();
                set_openLogin(true);
            }
            button.addEventListener("click", clickFn);
            listeners.push(() => button?.removeEventListener("click", clickFn));
        });

        return () => {
            listeners.map(x => x());
        };
    }, [initialized]);

    return (<>
        <LoginDialog
            open={!!openLogin}
            onClose={() => set_openLogin(false)}
            onLoggedIn={() => Redirect()}
        />
    </>);
}