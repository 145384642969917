import { Typography } from "@material-ui/core";
import Line from "../../../Components/Line";
import ImageCarousel from "../../LandingPage/ImageCarousel";
import ImageGrid from "../../LandingPage/ImageGrid";
import LandingFooter from "../../LandingPage/LandingFooter";
import Questions from "../../LandingPage/Questions";
import Reviews from "../../LandingPage/Reviews";
import SupportSection from "../SupportSection";
import VideoSection from "../VideoSection";
import useStyles from "./index.styles";
import useBreakpoints from "../../../Components/Hooks/useBreakpoints";

export default function () {
    const { xsDown } = useBreakpoints();
    const classes = useStyles();
    return (<>
        <VideoSection classes={{
            grid_container: "order-layout-container"
            , root: classes.VideoSection
        }} />

        <div className={"order-layout-container " + classes.FeaturesContainer}>

            <div className={classes.CarouselSection}>
                <Typography variant="h5" className={classes.QueIncluyeText}>¿Por qué Simple Marcas?</Typography>
                <ImageCarousel />
            </div>

            <div className={[classes.xsFullWidth, classes.DividerRule3].join(" ")}  >
                <Line size={xsDown ? 6 : 1} />
            </div>

            <ImageGrid />

            <div className={[classes.xsFullWidth, classes.DividerRule4].join(" ")}>
                <Line size={xsDown ? 6 : 1} />
            </div>

            <Reviews />

            <div className={[classes.xsFullWidth, classes.DividerRule4].join(" ")}>
                <Line size={xsDown ? 6 : 1} />
            </div>

            <div style={{ marginBottom: 30 }}>
                <Typography variant="h5">Preguntas Frecuentes</Typography>
            </div>

            <div style={{ marginBottom: 60 }}>
                <Questions />
            </div>
            <div style={{ marginBottom: 30 }}>
                <Typography variant="caption" component="p">
                    *PreciseResult™ utiliza inteligencia artificial y esta puede cometer errores, si bien contamos con medidas de mejoramiento, el sistema puede brindarle información inexacta. No pretende dar consejos.
                </Typography>
                <br />
                <Typography variant="caption" component="p">
                *La búsqueda de marcas está realizada con la base de datos de marcas registradas y no provee información sobre marcas que estén en trámite.
                </Typography>
            </div>
        </div>

        <SupportSection />

        <LandingFooter />

    </>)
}