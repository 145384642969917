import React from "react";
import { TextField, InputAdornment, Button } from "@material-ui/core";

export default function (props: {
    value: string, onChange(value: string): void,
    error?: string | null
    disabled?: boolean;
}) {

    const [type, set_type] = React.useState<"text" | "password">("password");

    return (<TextField
        fullWidth
        value={props.value}
        onChange={event => props.onChange(event.target.value)}
        type={type}
        error={!!props.error}
        disabled={props.disabled}
        helperText={props.error}
        variant="outlined"
        color="secondary"
        label="Contraseña"
        InputProps={{
            endAdornment: <InputAdornment position="end">
                <Button
                    onClick={() => set_type(type === "password" ? "text" : "password")}
                    type="button"
                    color="secondary"
                    variant="text"
                    style={{ fontSize: 12, textDecoration: "underline" }}
                >
                    {type == "password" ? "Mostrar" : "Ocultar"}
                </Button>
            </InputAdornment>
        }}
    />);
}