import { withStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";


export default withStyles((theme: Theme) => ({
    "@global": {

        "a": {
            textDecoration: "none",
            cursor: "pointer",
            "&:hover": {
                textDecoration: "underline"

            }
        }
        , ".MuiTypography-root.MuiTypography-colorPrimary": {
            color: "#0071cd"
        }


        , "small.MuiTypography-body1": {
            fontSize: (+theme.typography.body1.fontSize!) - 1
            , [theme.breakpoints.down("xs")]: {
                fontSize: theme.typography.body1.fontSize
            }
        }
        , ".md.MuiTypography-body1": {
            fontSize: (+theme.typography.body1.fontSize!) + 1
        }


        , "small.MuiTypography-body2": {
            fontSize: (+theme.typography.body1.fontSize!) - 1
            , lineHeight: 1.4
            , [theme.breakpoints.down("xs")]: {
                fontSize: theme.typography.body1.fontSize
            }
        }
        , ".sm.MuiTypography-body2": {
            fontSize: (+theme.typography.body2.fontSize!) - 1
            , lineHeight: 1.4
            , [theme.breakpoints.down("xs")]: {
                fontSize: theme.typography.body1.fontSize
            }
        }
        , ".MuiTypography-body1.xs, .MuiTypography-body2.xs": {
            fontSize: 12
        }

        , "span.MuiTypography-caption": {
            lineHeight: 1.4
        }

        , "small.MuiTypography-caption.xs": {
            fontSize: "11px !important"
            , [theme.breakpoints.down("xs")]: {
                fontSize: "13px !important"
            }
        }
        , ".MuiTypography-caption.sm": {
            [theme.breakpoints.down("xs")]: {
                fontSize: 14
            }
        }
        , "p.MuiTypography-caption": {
            fontSize: (+theme.typography.body1.fontSize!) - 1
            , lineHeight: 1.4
            , [theme.breakpoints.down("xs")]: {
                fontSize: theme.typography.body1.fontSize
            }
        }
        , "a.MuiTypography-caption": {
            [theme.breakpoints.down("xs")]: {
                fontSize: (+theme.typography.caption.fontSize!) + 1
            }
        }
        , ".MuiTypography-caption.md": {
            fontSize: 15
        }
    }
}))(() => null);

