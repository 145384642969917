import { Typography } from "@material-ui/core";
import RadioInput from "../../../Components/RadioInput";

export default function (props: {
    value: boolean, onChange(value: boolean): void, disabled?: boolean
}) {
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, flexGrow: 1 }}>
                    <Typography variant="h6">Agregar registro de logotipo<br/>$9.990</Typography>
                </div>
                <RadioInput disabled={props.disabled} options={[{ value: "false", name: "No" }, { value: "true", name: "Sí" }]} onChange={value => props.onChange(value === "true")} value={(props.value || "false").toString()} />
            </div>
            {props.value
                ? (<div style={{ marginTop: 15 }}>
                    <Typography variant="caption" component="p">Listo! Te solicitaremos el logotipo una vez se confirme el pago.</Typography>
                </div>)
                : null}
        </div>);
}