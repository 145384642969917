import React from "react";
import { CircularProgress, Dialog, DialogContent, Slide, Typography } from "@material-ui/core";
import { TransitionProps } from '@material-ui/core/transitions';
import RedirectToPaymentPlatform from "../PurchaseProcess/SubmitRequest/redirect-to-payment-platform";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function RedirectionScreen(props: {
    isOpen: boolean, token?: string, url: string
}) {
    const readyToRedirect = Boolean(props.isOpen && props.url);
    React.useEffect(() => {
        if (readyToRedirect) setTimeout(() => {
            requestAnimationFrame(() => {
                RedirectToPaymentPlatform(props.url, props.token);
            });
        }, 1500);
    }, [readyToRedirect])

    return (<Dialog maxWidth={false} open={props.isOpen} fullScreen={true} TransitionComponent={Transition}>
        <DialogContent>
            <div style={{ width: "100%", height: "100%", display: "table", textAlign: "center" }} >
                <div style={{ margin: "auto", display: "table-cell", verticalAlign: "middle" }}>
                    <div style={{ margin: 15 }}>
                        <img src={`${process.env.PUBLIC_URL}/svg/shopping-cart.svg`} height={50} />
                    </div>
                    <Typography variant="h6">Te estamos redirigiendo a la plataforma de pagos.</Typography>
                    <Typography variant="h6">Esto puede tardar hasta 60 segundo.</Typography>
                    <Typography variant="h6">No cierres el navegador.</Typography>
                    <div style={{ margin: 20 }}>
                        <CircularProgress size={60} color="primary" />
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>)
}