import ErrorScreen from ".";
import { Button } from "@material-ui/core";
import { signOut } from "firebase/auth";
import { AuthInstance } from "../../lib/FirebaseConfig";

export default function (props: {
    authorized_user: string
} & Omit<React.ComponentProps<typeof ErrorScreen>, "message">) {
    return (<ErrorScreen name="Acceso denegado" message={`Esta página no está disponible para tu usuario. Cierra sesión e inicia desde la cuenta '${props.authorized_user}'`}>
        <div style={{ marginTop: 40 }}>
            <Button
                size="large"
                color="primary"
                type="button"
                variant="contained"
                onClick={() => signOut(AuthInstance)
                    .then((ex) => (window.location as any).reload(true))
                    .catch(ex => alert("Ocurrió un error: " + ex.message + ". Recargue la pantalla y reintentelo."))}
            >
                Cerrar sesión y reintentar
            </Button>
        </div>
    </ErrorScreen>);
}