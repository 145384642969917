enum LeadStatus {
    /** la solicitud aún es una busqueda (preliminar). */
    SEARCH = "SEARCH",
    /** Solicitud entro a `checkout`. */
    CREATED = "CREATED",
    /** Pasó del `checkout` y está lista revisar por SimpleMarcas. */
    SUBMITTED = "SUBMITTED",
    /** La solicitud pasó la revisión de SimpleMarcas y está lista para cobrar y empezar el trámite. */
    APPROVED = "APPROVED",
    /** La solicitud no pasó la revisión de SimpleMarcas (se puede especificar el motivo). */
    REJECTED = "REJECTED",
    /** La solicitud ya fue cerrada. Ha llegado al fin del proceso y no tiene cobros por realizar. */
    COMPLETED = "COMPLETED"
}

enum LeadStatusOrder {
    SEARCH,
    CREATED,
    SUBMITTED,
    APPROVED,
    REJECTED,
    COMPLETED
}

enum PaymentStatus {
    CREATED = "",
    IN_PROGRESS = "IN_PROGRESS",
    PAYING = "PAYING",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR",
    READY = "READY"
}
export {
    LeadStatus,
    PaymentStatus,
    LeadStatusOrder
}

