import React, { ComponentProps } from "react";
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Theme, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useBreakpoints from "./Hooks/useBreakpoints";
import CloseIcon from "./Icons/CloseIcon";
import TitleInfoDialog from "./TitleInfoDialog";

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    title_section: {
        paddingLeft: 20
        , paddingRight: 20,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
            "& > *": {
                paddingLeft: 15
            }
        }
    }
    , content_section: {
        paddingTop: 0
        , paddingBottom: 0
        , paddingLeft: 20
        , paddingRight: 20,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    }
    , dialogActions: {
        padding: 0
    }
}));

export default function (props: {
    onClose?(): void;
    isOpen: boolean;
    children: React.ReactNode;
    title?: React.ReactNode;
    actions?: React.ReactNode;
    maxWidth?: DialogProps["maxWidth"];
    disableFullScreenMobile?: true;
    classes?: DialogProps["classes"] & { dialogContent?: string, dialogActions?: string };
    dialogProps?: Omit<ComponentProps<typeof Dialog>, "open" | "onClose" | "maxWidth" | "fullwidth" | "disableEscapeKeyDown" | "fullScreen" | "classes">
}) {
    const classes = useStyles();
    let { xsDown } = useBreakpoints();
    if ("disableFullScreenMobile" in props) xsDown = false;
    const { dialogContent: dialogContentClasses, dialogActions: dialogActionsClasses, ...dialogClasses } = (props.classes || {} as Partial<typeof props["classes"]>);
    return (<Dialog
        {...props.dialogProps}
        classes={dialogClasses}
        open={props.isOpen}
        onClose={props.onClose}
        TransitionComponent={xsDown ? Transition : undefined}
        maxWidth={props.maxWidth || "md"}
        fullWidth
        disableEscapeKeyDown={false}
        fullScreen={xsDown}
    >
        <DialogTitle className={classes.title_section}>
            {props.onClose
                ? (<IconButton type="button" onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>)
                : <p></p>}
        </DialogTitle>
        <DialogContent className={[classes.content_section, dialogContentClasses || ""].join(" ")}>
            <div style={{ marginLeft: "1vw", marginRight: "1vw" }}>
                <div className="landing-page-block-2">
                    <TitleInfoDialog> {props.title}</TitleInfoDialog>
                    {props.children}
                </div>
            </div>
        </DialogContent>
        {props.actions
            ? (<DialogActions className={[classes.dialogActions, dialogActionsClasses || ""].join(" ")} >
                {props.actions}
            </DialogActions>)
            : null}

    </Dialog>);
}