import React from "react";
import InfoDialog from "../InfoDialog";
import LoginForm from "./LoginForm";

export default function LoginDialog(props: {
    open: boolean;
    onClose?(): void;
    onLoggedIn?(): void;
    LoginFormProps?: Omit<React.ComponentProps<typeof LoginForm>, "onLoggedIn">
}) {
    return (<InfoDialog
        maxWidth="sm"
        isOpen={props.open}
        onClose={props?.onClose}
    >
        <LoginForm {...(props.LoginFormProps || {})} onLoggedIn={() => props.onLoggedIn
            ? props.onLoggedIn()
            : (props.onClose && props.onClose())} />
    </InfoDialog>)
}