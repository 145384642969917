// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { EmailAuthProvider, getAuth, FacebookAuthProvider, GoogleAuthProvider, onAuthStateChanged, browserLocalPersistence, connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY
    , authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
    , projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
    , storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
    , messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
    , appId: process.env.REACT_APP_FIREBASE_APP_ID
    , measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

const facebookAuthProvider = new FacebookAuthProvider();
const googleAuthProvider = new GoogleAuthProvider();
const emailAuthProvider = new EmailAuthProvider();


const AuthInstance = getAuth(firebaseApp);
AuthInstance.setPersistence(browserLocalPersistence);
AuthInstance.languageCode = "es";

var authUrlTest = null;
if (process.env.NODE_ENV === "development")
    authUrlTest = "http://localhost:9099";
if (window.location.hostname.indexOf("ngrok.io") > 0)
    authUrlTest = window.origin || window.location.origin;


if (authUrlTest)
    connectAuthEmulator(AuthInstance, authUrlTest);

export {
    onAuthStateChanged,
    facebookAuthProvider,
    googleAuthProvider,
    emailAuthProvider,
    AuthInstance
}