import { List, ListItem, ListItemText, Typography, makeStyles } from "@material-ui/core";
import Line from "./Line";
import Comps from "./SummaryProductList/ListItems";
import CuotasList from "./SummaryProductList/CuotasList";
import UTM_Details from "./UTM_Details";


const useStyles = makeStyles({
    ListItem: {
        padding: 0,
        alignItems: "end"
    },
    leftTitle: {
        paddingBottom: 6,
        textAlign: "right"
    },
    lineThrough: {
        textDecoration: "line-through"
    }
});

export const productSummaryStyles = useStyles;

export default function (props: Lead["resumen"] & { hide_detalle_title?: boolean } & { payments: Payment[] }) {
    const classes = useStyles();

    return (<List style={{ paddingTop: 0 }}>

        {props.hide_detalle_title
            ? null
            : (<ListItem style={{ paddingTop: 5, paddingBottom: 10 }} className={classes.ListItem} dense>
                <ListItemText primaryTypographyProps={{ className: "c-303030", variant: "body2" }}
                    primary="Detalle"
                />
            </ListItem>)}


        <Comps.ProductSumaryGroup
            items={[{
                title: `Solicitud de registro x ${props.clases} Clase(s)`
                , value: (props.clases_amount + props.valor_descuento).toCurrency()
            }, {
                title: "Pack Marca Registrada"
                , value: (<>
                    <span style={{ opacity: 0.4 }} className={classes.lineThrough} >{(1526700).toCurrency()}</span>
                    <span>&nbsp;&nbsp;{(0).toCurrency()}</span>
                </>)
            },
            props.valor_descuento
                ? {
                    title: <>
                        {props.discount_info?.label || "Cupón de descuento"}
                        {props.discount_info?.remarkable && <img
                            title="Remarkable Discount"
                            src={`${process.env.PUBLIC_URL}/svg/remarkable-discount.svg`}
                            height={15}
                            width={15}
                            style={{ marginLeft: 5, height: "1em", verticalAlign: "baseline" }}
                        />}
                    </>
                    , value: (<Typography variant="body2" component="small" style={{ color: "#008a05" }}>
                        {(Math.abs(props.valor_descuento) * -1).toCurrency()}
                    </Typography>)
                }
                : null
            ]}
        />

        <ListItem className={classes.ListItem} style={{ paddingTop: 8, paddingBottom: 11 }} >
            <Line size={1} style={{ width: "100%" }} />
        </ListItem>

        <Comps.ProductSumaryGroup
            items={[
                {
                    title: "Subtotal",
                    value: (props.clases_amount).toCurrency()
                }, {
                    title: `Tasa inicial para ${props.clases} Clase(s)`
                    , value: (props.UTM_inicial_amount || 0).toCurrency()
                }]}
        />

        <ListItem className={classes.ListItem} style={{ paddingTop: 8, paddingBottom: 11 }} >
            <Line size={1} style={{ width: "100%" }} />
        </ListItem>

        <Comps.ProductSumaryTitle title="Total" value={props.total.toCurrency()} />

        <Comps.ProductSumaryRow title="Tasa final no incluida" value={<UTM_Details />} />

        {props.payments && props.payments.length > 1
            ? (<CuotasList list={props.payments!} />)
            : null}

    </List >);
}