import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import LandingHeader from "../../Layouts/LandingPage/LandingHeader";
import MultilineTextToComponent from "../MultilineTextToComponent";
import SubmitButton from "../../Layouts/PurchaseProcess/Submit-Button";
import { useAppContext } from "../Context/AppContext";
import DateToText from "../../lib/DateToText";
import RedirectionScreen from "../../Layouts/PurchaseProcess/RedirectionScreen";
import ErrorNotification from "../ErrorNotification";
import CopyRightFooter from "../../Layouts/LandingPage/CopyRightFooter";
import PaymentPlatformList from "src/Layouts/PurchaseProcess/SubmitRequest/PaymentPlatformList";
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        textAlign: "center"
    },
    content: {
        margin: "auto",
        paddingTop: 40,
        maxWidth: 500 + 60,
        paddingLeft: 30,
        paddingRight: 30,
        textAlign: "center",
    },
    MultiTextContainer: {
        marginTop: 35,
        marginBottom: 20,
        "&>*": {
            display: "inline"
        }
    },
    titleContainer: {
        marginTop: 20,
        textAlign: "center",
        "&>*": {
            display: "inline"
        }
    },
    paymentOptionsContainer: {
        marginBottom: 20,
        textAlign: "left"
    },
    copyRightContainer: {
        marginTop: 20
    }
}));

type Status = "SUBMIT" | "ERROR" | "CANCEL" | "";

export default function (props: OpenPayment) {

    const { status: defaultPaymentStatus
        , errorMessage: defaultErrorMessage
        , ...data } = props;
    const initialPaymentError = defaultPaymentStatus === "ERROR"
        ? (defaultErrorMessage && new Error(defaultErrorMessage))
        : undefined;

    const [payment_platform, set_payment_platform] = React.useState<string>("mercadopago");
    const [error, set_error] = React.useState<Error | undefined>(initialPaymentError || {
        message: "Tu pago no pudo ser procesado, verifica la tarjeta o intenta con una nueva.",
        name: "PaymentError"
    });
    const [status, set_status] = React.useState<Status>(initialPaymentError ? "ERROR" : "");
    const [redirectData, set_redirectData] = React.useState<{ url: string, token: string } | undefined>();

    const { API } = useAppContext();

    React.useEffect(() => {
        if (status !== "SUBMIT") return;
        if (!payment_platform) return;
        set_error(undefined);

        API.checkoutOpenPayment({
            id: data.id,
            payment_platform
        }).then((results: any) => {
            if (!results) {
                set_error(new Error("Ups. Algo salió mal. Reintenta nuevamente. Cod. [Undf.]"));
                set_status("ERROR");
                return;
            }
            if (results.redirectUrl || results.redirectToken) {
                return set_redirectData({
                    url: results.redirectUrl,
                    token: results.redirectToken
                });
            } else if (["COMPLETED", "PAYING", "REVERTED"].includes(results.status)) {
                window.location.reload();
            } else {
                set_error(new Error("Ups. Algo salió mal. Reintenta nuevamente. Cod. [OoR.]"));
                set_status("ERROR");
            }
        }).catch(ex => {
            set_error(ex as any);
            set_status("ERROR");
        });
    }, [status]);

    const paymentIsCompleted = data.paidAt;
    const titulo = paymentIsCompleted ? "¡Listo!" : data.titulo;

    const classes = useStyles();

    return (<div className={classes.root}>
        <RedirectionScreen isOpen={!!redirectData} {... (redirectData || { token: "", url: "" })} />
        <LandingHeader show_login={false} />
        <div className={classes.content}>
            {
                status === "ERROR"
                    ? (error && <ErrorNotification
                        message={error?.message ||
                            "El pago no se pudo completar, verifica que tu tarjeta esté activa y tenga fondos suficientes. Si la información de pago es correcta, ponte en contacto con tu banco para obtener más detalles."
                        }
                    />)
                    : null
            }
            <div className={classes.titleContainer}>
                <MultilineTextToComponent input={titulo} >
                    {({ text }) => <Typography color="secondary" variant="h1">{text}</Typography>}
                </MultilineTextToComponent>
            </div>
            {paymentIsCompleted
                ? <CompletedStatus paidAt={data.paidAt!} />
                : <OperativeStatus
                    status={status}
                    error={error}
                    set_status={set_status}
                    payment_platform={payment_platform}
                    set_payment_platform={set_payment_platform}
                    {...data}
                />}
            <div className={classes.copyRightContainer}>
                <CopyRightFooter />
            </div>
        </div>
    </div>);
}


function OperativeStatus(props: {
    status: Status,
    payment_platform?: string,
    set_status(state: Status): void,
    set_payment_platform(value: string): void,
    error?: Error
} & Omit<OpenPayment, "status">) {

    const { status, set_status, error, payment_platform, set_payment_platform, ...data } = props;
    const isLoading = status === "SUBMIT";
    const submitButtonText = "Pagar";
    const classes = useStyles();
    return <>
        <div className={classes.MultiTextContainer} >
            <MultilineTextToComponent input={data.descripcion}
                RenderBold={({ text }) => <Typography color="secondary" variant="body2">{text}</Typography>}
            >
                {({ text }) => <Typography color="secondary" variant="body1">{text}</Typography>}
            </MultilineTextToComponent>
        </div>

        <Typography>Monto: {(data.monto_pago && data.monto_pago.toCurrency()) || "Ninguno"}</Typography>
        <br />
        <br />

        <div className={classes.paymentOptionsContainer}>
            <PaymentPlatformList onChange={set_payment_platform} value={payment_platform} />
        </div>

        <SubmitButton fullWidth onSubmit={() => set_status("SUBMIT")} isLoading={isLoading} disabled={status === "CANCEL"}>
            {submitButtonText}
        </SubmitButton>
    </>
}

const useLevitateStyle = makeStyles({
    img: {
        "animation": "levitate 1.2s alternate ease-in-out",
        maxHeight: 100,
        marginTop: 25
    }
});

function CompletedStatus(data: {
    paidAt: string
}) {
    const classes = useLevitateStyle();
    return (<div>
        <img
            title="Pago completado"
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/svg/check-mark.svg`}
        />
        <div style={{ marginTop: 15 }}>
            <Typography variant="body1" color="secondary">
                El pago fue realizado exitosamente{
                    new Date().toLocaleDateString() === new Date(data.paidAt!).toLocaleDateString()
                        ? ""
                        : (" el " + DateToText(data.paidAt!))
                }.
            </Typography>
            <Typography variant="body1" color="secondary">
                Recibirás un correo electónico con la confirmación.
            </Typography>
        </div>
    </div>);
}