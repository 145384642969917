import React from "react";
import AnalyticsEvents from "../../lib/AnalyticsEvents";
import { useAppContext } from "../Context/AppContext";
import ErrorScreen from "../ErrorScreen";
import WrongUserErrorScreen from "../ErrorScreen/WrongUserErrorScreen";
import useNavigateToStep from "../Hooks/useNavigateToStep";
import useSessionID from "../Hooks/useSessionID";
import LoadingScreen from "../LoadingScreen";

type Status = "COMPLETED" | "FETCHING" | "ERROR" | "INITIALIZING";

export default function <T extends { lead: any }>(Component: React.ComponentType<T>) {

    return function (props: {
        // Devuelve true si hubo redirección;
        validateFetchedData?(lead: Lead, navigation: ReturnType<typeof useNavigateToStep>): boolean;
    } & Omit<T, "lead">) {

        const { validateFetchedData, ...componentProps } = props;

        const { sessionID, payment_number } = useSessionID();
        const navigationRoutes = useNavigateToStep();
        const { API } = useAppContext();
        const [status, set_status] = React.useState<Status>("FETCHING" as any);
        const [error, set_error] = React.useState(null as Error & {
            authorized_user?: string;
            status?: number;
            statusText?: string;
        } | null);
        const [lead, set_lead] = React.useState(null as Lead | null);


        React.useEffect(() => {
            if (status !== "FETCHING") return;
            if (sessionID) {
                API.FetchRequest(sessionID, payment_number)
                    .then(lead => {
                        const redirected = props.validateFetchedData ? props.validateFetchedData(lead, navigationRoutes) : false;
                        if (redirected) return;
                        set_lead(lead);
                        set_status("COMPLETED");
                    }).catch(ex => {
                        set_error(ex);
                        set_status("ERROR");
                        AnalyticsEvents.error(ex);
                    });
                return;
            }

            set_lead({} as any);
            set_status("COMPLETED");
            /*if (props.goToFlowOnNotFound)*/ return navigationRoutes.navigateToFlow();

        }, [status, sessionID]);


        if (status === "ERROR" ||
            (status === "COMPLETED" && !lead)
        ) {
            if (status === "ERROR" && error?.status === 403 && error?.authorized_user) {
                return (<WrongUserErrorScreen authorized_user={error?.authorized_user!} />);
            }
            return (<ErrorScreen {...(error || { message: "Ocurrió un error" })} />);
        }

        if (status === "FETCHING") return <LoadingScreen fullScreen />;

        return (<Component
            {...componentProps as any}
            lead={lead}
        />);
    }

}