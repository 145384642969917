import React from "react";
import { Button, IconButton, TextField, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { MouseEventHandler } from "react";
import useBreakpoints from "src/Components/Hooks/useBreakpoints";
import { BORDER_COLOR } from "src/Theme/colors";
import CloseIcon from "src/Components/Icons/CloseIcon";
import { useNavigate } from "react-router-dom";
import { SEARCH_PARAM_KEYS } from "../Flow.v2/FlowState";
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.up("sm")]: {
            display: "flex"
            , flexDirection: "row"
            , "& > div": {
                marginRight: 10
            }
            , maxWidth: 650
        }
        , [theme.breakpoints.down("xs")]: {
            display: "block",
            "& > div": {
                marginBottom: 10
            }
            , "& > button": {
                marginTop: 5
                , width: "100%"
                , marginBottom: 10
            }
        }
        , margin: "auto"
    }
    , whiteBackground: {
        backgroundColor: "#ffffff"
    }
    , roundBorder: {
        borderRadius: 10
    }
    , button: {
        paddingLeft: 54,
        paddingRight: 54,
        fontSize: 16,
        borderColor: "black !important"
    }
}));

const useXsComponentStyle = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
    , textContainer: {
        flex: 1
        , textAlign: "left"
        , marginLeft: 20
    }
    , label: {
        fontFamily: "Poppins",
        fontWeight: 500,
        lineHeight: "1.1876em",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "0.75em"
    },
    labelValue: {
        fontSize: 14
    }
}));

interface EditMarcaLinkProps {
    marca: string
    , clases: Array<number | string>
    , style?: React.CSSProperties
    , className?: string
    , onEditClases?: MouseEventHandler<HTMLAnchorElement>
    , refs?: {
        marca_input?: React.Ref<HTMLInputElement>
    }
}

export default function EditarMarcaLink(props: EditMarcaLinkProps) {
    const breakpoints = useBreakpoints();
    const xsStyles = useXsComponentStyle();
    const [isOpen, set_isOpen] = React.useState(false);
    if (breakpoints.xsDown) {
        if (isOpen) {
            return (<div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <div style={{ textAlign: "left", marginBottom: 10 }}>
                    <IconButton onClick={() => set_isOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <EditarBusquedaLinkComponents  {...props} />
            </div>);
        }
        return (<div tabIndex={0} onClick={() => set_isOpen(true)} className={xsStyles.container}>
            <div style={{ marginLeft: 20 }} >
                <img
                    src={process.env.PUBLIC_URL + "/svg/blue-search.svg"}
                    height={20}
                    style={{ margin: 5 }}
                />
            </div>
            <div
                className={xsStyles.textContainer}
                style={{ borderRight: "1px solid " + BORDER_COLOR }}
            >
                <label className={xsStyles.label}>Marca</label>
                <Typography className={xsStyles.labelValue}>{props.marca}</Typography>
            </div>
            <div className={xsStyles.textContainer}>
                <label className={xsStyles.label}>Clases</label>
                <Typography className={xsStyles.labelValue}>{props.clases.join(",")}</Typography>
            </div>
        </div>)
    }
    return (<EditarBusquedaLinkComponents {...props} />)
}

function EditarBusquedaLinkComponents(props: EditMarcaLinkProps) {
    const classes = useStyles();
    const [marca, set_marca] = React.useState(props.marca);
    const [loading, set_loading] = React.useState(false);
    const navigate = useNavigate();

    const atemptEdit = function (e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!(marca && marca.toString().trim().length)) {
            const marcaInput: HTMLInputElement = (e.target as HTMLFormElement).elements["marca"];
            marcaInput.focus();
            return;
        }
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(SEARCH_PARAM_KEYS.MARCA, marca.trim());
        window.location.search = queryParams.toString();
        set_loading(true);
        // window.location.reload();
    }
    const tieneItems = !!new URLSearchParams(window.location.search).get(SEARCH_PARAM_KEYS.ITEMS);

    return (<form onSubmit={e => atemptEdit(e)}
        style={{ ...(props.style || {}) }}
        className={[classes.container, props.className || ""].join(" ")}
    >
        <TextField
            label="Marca"
            value={marca}
            name="marca"
            disabled={loading}
            onChange={e => set_marca(e.target.value)}
            variant="outlined"
            color="secondary"
            InputProps={{
                inputRef: props.refs?.marca_input
            }}
            inputProps={{
                className: [classes.roundBorder, classes.whiteBackground].join(" ")
            }}
        />
        <TextField
            label="Clases"
            value={props.clases.join(", ")}
            name="clases"
            aria-readonly="true"
            disabled={loading || !tieneItems}
            variant="outlined"
            color="secondary"
            onClick={() => {
                if (!tieneItems) return;
                const search = new URLSearchParams(window.location.search);
                search.set(SEARCH_PARAM_KEYS.MARCA, marca.trim());
                navigate({
                    pathname: "/flow/pick",
                    search: search.toString()
                })
            }}
            inputProps={{
                className: [classes.roundBorder, classes.whiteBackground].join(" ")
            }}
        />
        <Button
            variant="outlined"
            disabled={loading}
            color="secondary"
            className={[classes.button, classes.whiteBackground].join(" ")}
            type="submit"
        >
            Buscar
        </Button>
    </form >);
}