import { TextField, Typography, makeStyles, Fade } from "@material-ui/core"
import SubmitButton from "../../../Layouts/PurchaseProcess/Submit-Button"
import React from "react";
import InfoDialog from "../../InfoDialog";
import { IsEmail } from "../../../API/Models/DataTypeValidation";
import Line from "src/Components/Line";

const useStyles = makeStyles(theme => ({
    blurRoot: {
        "& > .MuiBackdrop-root": {
            backdropFilter: "blur(10px)"
            , "-webkit-backgrop-filter": "blur(10px)"
            , "-moz-backgrop-filter": "blur(10px)"
            , "background-color": "rgba(232,232,237,.4)"
        }
    }
    , container: {
        [theme.breakpoints.down("xs")]: {
            alignItems: "baseline"
        }
    }
}));

export default function AnonymousEmailSignUpDialog(props: {
    onLoggedIn?(): void;
    open: boolean;
    blurBackdrop?: boolean;
    onClose?(): void;
}) {
    const [status, set_status] = React.useState("");
    const [email, set_email] = React.useState("");
    const [error, set_error] = React.useState("");
    const classes = useStyles();
    React.useEffect(() => {
        if (status !== "loading") return;

        if (!IsEmail(email)) {
            set_status("");
            return set_error("Digite un correo válido");
        }

        window.localStorage.setItem("anonymous_activity_email", email.trim());

        set_status("completed");
        props.onLoggedIn && props.onLoggedIn();
    }, [status]);

    // Reinicio de la pantalla (se vuelve a abrir)
    React.useEffect(() => {
        if (!(status === "completed" && props.open == true)) return;
        set_status("");
        set_email("");
        set_error("");
    }, [props.open, status]);

    const isLoading = ["completed", "loading"].includes(status);
    return (
        <InfoDialog
            classes={{
                root: props.blurBackdrop ? classes.blurRoot : undefined
                , container : classes.container
            }}
            disableFullScreenMobile
            onClose={props.onClose}
            isOpen={props.open}
            maxWidth="xs"
            title="Inicia sesión con tu correo electrónico"
            dialogProps={{
                TransitionComponent: Fade
                , transitionDuration: 500
            }}
        >
            <form style={{ paddingBottom: 40 }} onSubmit={e => {
                e.preventDefault();
                set_status("loading");
            }} >
                <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                    <TextField
                        placeholder="Correo electrónico"
                        label="Correo electrónico"
                        disabled={isLoading}
                        value={email}
                        variant="outlined"
                        color="secondary"
                        onChange={e => set_email(e.target.value)}
                        helperText={error}
                        error={!!error}
                    />
                </div>
                <div>
                    <SubmitButton type="submit" fullWidth isLoading={isLoading}>
                        Continuar
                    </SubmitButton>
                </div>
                <div>
                    <Line style={{ marginTop: 20, marginBottom: 15 }} />
                    <Typography variant="caption" component="small" className="sm" color="secondary">
                        Al continuar, acepta nuestra&nbsp;<TermnsLink url="https://www.simplemarcas.cl/politicas-de-privacidad">
                            Política de privacidad
                        </TermnsLink>&nbsp;y&nbsp;<TermnsLink url="https://www.simplemarcas.cl/terminos-de-uso-del-buscador">
                            Términos de uso del buscador
                        </TermnsLink>.
                    </Typography>
                </div>
            </form>
        </InfoDialog>);
}

function TermnsLink(props: {
    url: string,
    children?: React.ReactNode
}) {
    return (<Typography
        href={props.url}
        target="_blank"
        color="primary"
        style={{ fontSize: "inherit", lineHeight: "inherit", fontFamily: "inherit" }}
        component="a"
    >
        {props.children}
    </Typography>)
}