import React from "react";
import { GroupItem } from "../Flow-templates";
import { HighlightText } from "../HighlightText";

const worker = new Worker(process.env.PUBLIC_URL + "/searchWorker.js");
export default function useSearchWorker(type: ListType) {
    const [loading, set_loading] = React.useState(true);
    const [error, set_error] = React.useState<Error | null>(null);
    const [data, set_data] = React.useState<Array<GroupItem & { match: string }>>([]);

    const [search, set_search] = React.useState("");

    React.useEffect(() => {
        set_data([]);
        set_loading(true);
        set_error(null);
        const result = (event) => {
            const action = event.data;
            // console.info(event);
            set_loading(false);
            // if (action.type === "initialized") 
            if (action.type === "initialization_error") set_error(action.payload);
        }
        worker.postMessage({
            type: "initialize", payload: type
        });
        worker.addEventListener("message", result);
        worker.addEventListener("messageerror", e => {
            set_error(e.data);
        });
        return () => {
            worker.removeEventListener("message", result);
            // remove worker 
        }
    }, [type]);

    React.useEffect(() => {
        var timeout = setTimeout(() => {
            if (!(search && search?.toString()?.trim().length)) {
                set_data([]);
                set_loading(false);
                return;
            }
            set_loading(true);
            worker.postMessage({
                type: "search", payload: search
            });
        }, 400);
        const callback = event => {
            const action = event.data;
            if (action.type === "search_results") set_data(action.payload.map(x => ({
                ...x,
                match: HighlightText(x.primaryText, search)
            })));
        }
        worker.addEventListener("message", callback);
        return () => {
            clearTimeout(timeout);
            worker.removeEventListener("message", callback);
        }
    }, [search]);
    return [search, set_search, data, loading, error] as const;
}