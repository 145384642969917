import ButtonOverrideCss from "./ButtonOverrideCss";
import ComponentsOverrideCss from "./ComponentsOverrideCss";
import InputOutlilnedCss from "./inputOutlilnedCss";
import InputOverrideCss from "./InputOverrideCss";
import ListItemOverrideCss from "./ListItemOverrideCss";
import TextOverrideCss from "./TextOverrideCss";

export default function () {
    return (<>
        <InputOverrideCss />
        <ButtonOverrideCss />
        <ComponentsOverrideCss />
        <TextOverrideCss />
        <InputOutlilnedCss />
        <ListItemOverrideCss />
    </>);
}