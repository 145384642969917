import React from "react";
import { LeadStatus } from "../../../API/Models/LeadStatus";
import { useAppContext } from "../../../Components/Context/AppContext";
import useNavigateToStep from "../../../Components/Hooks/useNavigateToStep";

interface Redirection {
    token: string;
    url: string;
}
type PayPurchaseStatus = "SUBMIT" | "QUOTE" | "ERROR" | "REDIRECT-TO-TRANSBANK" | "";

const payment_error = "Ocurrió un problema con el método de pago. Verifica que tu método de pago esté activo y con cupo disponible suficiente. Inténtalo nuevamente.";

export default function usePayPurchaseState(tmReq: Lead) {

    const [paymentRedirectData, set_paymentRedirectData] = React.useState(null as Redirection | null);

    const [invoice_type, set_invoice_type] = React.useState("boleta" as "boleta" | "factura");
    const [logo, set_logo] = React.useState(tmReq.logo || false);
    const [marca, replace_marca] = React.useState(tmReq.marca || "");
    const [payment_platform, set_payment_platform] = React.useState<undefined | string>("mercadopago");

    const [promo_code, set_promo_code] = React.useState(tmReq.promo_code || "");
    const [resumen, set_resumen] = React.useState(tmReq.resumen);
    const [payments, set_payments] = React.useState(tmReq.payments);

    const [status, set_status] = React.useState<PayPurchaseStatus>("");
    const [error_message, set_error_message] = React.useState(
        ["FAILED", "ERROR"].indexOf(tmReq.status || "") >= 0
            ? ((tmReq as any).status_message || payment_error)
            : (tmReq.info_message || null)
    );

    const force_change_marca = tmReq.status === LeadStatus.REJECTED &&
        marca.trim().toUpperCase() === tmReq.marca.trim().toUpperCase();

    const { API, anonymous_activity_email } = useAppContext();
    const [contacto, set_contacto] = React.useState<Lead["contacto"]>({
        nombre: tmReq.contacto?.nombre
        , telefono: tmReq.contacto?.telefono
        , email: anonymous_activity_email || ""
    });

    const { navigateToCompleted } = useNavigateToStep();
    React.useEffect(() => {
        if (status !== "SUBMIT") return;
        set_error_message(null);
        // el valor `email` se actualiza en `contacto`
        API.setAnonymousActivityEmail(contacto.email);
        API.submitRequest({
            request: {
                id: tmReq.id!
                , clases_seleccionadas: tmReq.clases_seleccionadas!
                , marca
                , invoice_type
                , logo
                , promo_code
                , contacto
            }, payment_platform
        }).then(results => {
            if (!results) throw new Error("Ups. Algo salió mal. Reintenta nuevamente. Cod. [Undf.]");
            if (results.redirectUrl) {
                set_paymentRedirectData({
                    url: results.redirectUrl!,
                    token: results.redirectToken || "",
                });
                set_status("REDIRECT-TO-TRANSBANK");
                return;
            }
            if (["COMPLETED", "SUBMITTED"].includes(results.status)) {
                navigateToCompleted(results.id || tmReq.id);
                return;
            }

            if (!results) throw new Error("Ups. Algo salió mal. Reintenta nuevamente. Cod. [OoR.]");

        }).catch(ex => {
            set_status("ERROR");
            let message = ex.message || "Ocurrió un problema. ";
            if (ex.code || ex.statusCode) {
                message += " código " + (ex.code || ex.statusCode);
            }
            set_error_message(message);
        });
    }, [status]);

    React.useEffect(() => {
        if (status !== "QUOTE") return;
        API.GetQuote({
            cuotas: resumen.cuotas
            , selected_clases: tmReq.clases_seleccionadas!
            , id: tmReq.id
            , promo_code
            , logo
        }).then(data => {
            set_resumen(data.resumen);
            set_payments(data.payments);
            set_status("");
        }).catch(err => {
            set_status("ERROR");
            set_error_message(err.message);
        })
    }, [status]);

    return {
        error_message
        , status
        , set_status

        , invoice_type
        , set_invoice_type

        , paymentRedirectData

        , onPromoCodeChange(data: { resumen: Lead["resumen"], payments: Lead["payments"], promo_code?: string }) {
            set_promo_code(data.promo_code || "");
            set_payments(data.payments);
            set_resumen(data.resumen);
        }
        , promo_code
        , resumen
        , payments

        , force_change_marca
        , replace_marca
        , marca

        , logo
        , updateLogo(enabled: boolean) {
            set_logo(enabled);
            set_status("QUOTE");
        }

        , payment_platform
        , set_payment_platform

        , contacto
        , updateContacto: set_contacto
    }

}