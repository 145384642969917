import React from "react";
import { makeStyles, Typography } from "@material-ui/core";


const useRowStyles = makeStyles({
    textLine_root: {
        display: "flex", flexDirection: "row", marginBottom: 5
    },
    textLine_left_text: {
        flex: 1, flexGrow: 1
    },
    group_items_root: {
        marginBottom: 7
    }
});


function Group(props: {
    title?: React.ReactNode
    value?: React.ReactNode
    items: Array<{
        title?: React.ReactNode,
        value?: React.ReactNode
    } | null>
}) {
    const classes = useRowStyles();
    return (<div style={{ paddingBottom: props.title ? 10 : "auto" }}>
        {props.title && <TextTitle title={props.title} value={props.value} />}
        {props.items.filter(x => x).map((item, index) =>
        (<TextLine key={index} title={item!.title} value={item!.value} classes={{
            root: classes.group_items_root
        }} />)
        )}
    </div>);
}

function TextTitle(props: {
    title: React.ReactNode,
    value?: React.ReactNode,
    contentRef?: React.Ref<HTMLDivElement>,
    style?: React.CSSProperties,
    children?: React.ReactNode,
    classes?: {
        title?: string,
        value?: string,
        root?: string,
    }
}) {
    const classes = useRowStyles();
    return (<div
        className={[classes.textLine_root, props.classes?.root || ""].join(" ")}
        style={props.style}
        ref={props.contentRef}
    >
        <Typography variant="body2" component="small" className={[props.classes?.title || "", classes.textLine_left_text].join(" ")}>
            {props.title}
        </Typography>
        {props.children ||
            (<Typography variant="body2" component="small" className={props.classes?.value}>
                {props.value}
            </Typography>)}
    </div>)
}

function TextLine(props: {
    title: React.ReactNode,
    value?: React.ReactNode,
    contentRef?: React.Ref<HTMLDivElement>,
    style?: React.CSSProperties,
    children?: React.ReactNode,
    classes?: {
        title?: string,
        value?: string,
        root?: string,
    }
}) {

    const classes = useRowStyles();
    return (<div
        className={[classes.textLine_root, props.classes?.root || ""].join(" ")}
        style={props.style}
        ref={props.contentRef}
    >
        <Typography variant="caption" component="p" className={[props.classes?.title || "", classes.textLine_left_text].join(" ")} >
            {props.title}
        </Typography>
        {props.children ||
            (<Typography variant="caption" component="p" className={props.classes?.value}>
                {props.value}
            </Typography>)}
    </div>)
}



export default {
    ProductSumaryRow: TextLine,
    ProductSumaryTitle: TextTitle,
    ProductSumaryGroup: Group,
    useStyles: useRowStyles
}