
import HTTP_BaseAPI from "./HTTP_BaseAPI";
import { LeadStatus } from "./Models/LeadStatus";

export default class API extends HTTP_BaseAPI {

    UTM(): Promise<{ year: string, month: string, UTM: number }> {
        return this.F("settings/UTM");
    }

    FetchPromoCodeDiscount(promo_code: string): Promise<Discount> {
        return this.F(`promo-code/${promo_code}`);
    }
    FetchRequest(id: string, payment_number?: string): Promise<Lead> {
        return this.F(`trademark-request/${id}${payment_number ? `?payment_number=${payment_number}` : ""}`);
    }
    GetQuote(body: {
        selected_clases: Array<number | string>;
        cuotas: number;
        promo_code?: string;
        id?: string;
        logo?: boolean;
    }): Promise<{ payments: Payment[], resumen: Lead["resumen"] }> {
        return this.F(`trademark-request/get-quote`, { body });
    }
    PaymentMethods(): Promise<{
        id: string;
        card_type: string;
        card_number: string;
    }[]> {
        return this.F("user/payment-methods");
    }

    getSearchHistory(): Promise<{
        id: string,
        itemIds: string[],
        marca: string
    }[]> {
        return this.F(`trademark-search/`);
    }
    /** Crea o actualiza una solicitud */
    setSearch(_body: { id?: string, marca?: string, email?: string, promo_code?: string, itemIds?: Array<string> }): Promise<{
        id: string,
        marca?: string,
        detalle_clases?: Lead["detalle_clases"],
        search: {
            itemIds: string[];
            riskAnalysis: {
                coincidences: SearchResult[],
                risk_probability: string,
                code: "" | "descriptiva"
            }
        },
        resumen: Lead["resumen"],
        payments: Payment[]
    }> {
        var body: Partial<typeof _body> = { ..._body };
        if (!body.marca?.trim().length)
            delete body.marca;

        if (!body.itemIds?.length)
            delete body.itemIds;

        return this.F(`trademark-search/`, { method: "PUT", body });
    }
    updateMarca(params: { id: string, marca: string }) {
        return this.F(`trademark-search/${params.id}/update:name`, { method: "PUT", body: params });
    }
    checkoutSearch(body: { selected_clases: number[], id: string }): Promise<{ id: string }> {
        return this.F(`trademark-search/${body.id}/checkout`, { body });
    }
    assignEmailToSearch(id: string): Promise<{ ok: true }> {
        return this.F(`trademark-search/${id}/update:email`, { method: "PATCH", body: {} });
    }
    submitRequest(body: {
        request: { id: string, contacto: { telefono: string, nombre: string }, clases_seleccionadas: Array<string | number>, invoice_type: Lead["invoice_type"], marca: string, logo: boolean, promo_code?: string };
        payment_platform?: string;
    }): Promise<{ redirectUrl?: string, redirectToken?: string, status: LeadStatus, id: string }> {
        return this.F(`trademark-request/${body.request.id}/submit`, { body });
    }

    Pay(body: {
        id: string;
        payment_number: string;
        payment_method: string;
    }) {
        return this.F(`payment/${body.id}/charge/${body.payment_number}`, {
            method: "PUT",
            body: { payment_method: body.payment_method }
        });
    }
    processOpenPayment(body: { id: string; payment_platform: string; }) {
        return this.F(`open-payment/${body.id}/pay`, {
            method: "PUT",
            body: { payment_platform: body.payment_platform }
        });
    }

    checkoutOpenPayment(body: { id: string; payment_platform: string; }) {
        return this.F(`open-payment/${body.id}/checkout`, {
            method: "PUT",
            body: { payment_platform: body.payment_platform }
        });
    }
    getOpenPayment(id: string) {
        return this.F(`open-payment/${id}`);
    }

    getUserPreferences(): Promise<UserPreferences> {
        return this.F(`user/preferences`);
    }
    appTopCountDown(): Promise<AppCountDown> {
        return this.F(`settings/appCountDown`);
    }

    userEmailExists(email: string, if3thpartyAuthSendSignInFromBrowserEmail?: boolean): Promise<{
        exists?: boolean;
        isFbUser?: boolean;
        signInFromBrowserEmailSent?: boolean;
        isGoogleUser?: boolean;
    }> {
        return this.F(`user/email-exists?email=${email}${if3thpartyAuthSendSignInFromBrowserEmail ? '&if3thpartyAuthSendSignInEmail=1' : ''}`);
    }
    createUserWIthEmailAndPassword(body: {
        email: string, password: string, name: string, phone?: string
    }) {
        return this.F("user/create-with-email-and-password", { body });
    }
}
