import { List, ListItem, Paper, Typography } from "@material-ui/core";
import { useAppContext } from "../Context/AppContext";
import { AuthInstance } from "../../lib/FirebaseConfig";
import { signOut } from "firebase/auth";

export default function (props: {
    onLoginClick(): void;
    logOutPressed?(): void;
}) {

    const { user } = useAppContext();

    return (<Paper elevation={0} style={{
        borderRadius: 8
        , boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.15)"
        , textAlign: "right"
    }}>
        <List>
            {user && <ListItem>
                <Typography variant="body2">{user!.displayName!}</Typography>
            </ListItem>}
            {user ? null : <ListItem button onClick={() => props.onLoginClick()} >Iniciar sesión o registrarse</ListItem>}
            {user && <ListItem button onClick={() => {
                props.logOutPressed && props.logOutPressed();
                signOut(AuthInstance);
            }} >Cierra la sesión</ListItem>}
        </List>
    </Paper >);
}