import { makeStyles, Typography } from "@material-ui/core"
import InfoDialog from "./InfoDialog"

const useStyles = makeStyles({
    inherit: {
        fontSize: "inherit !important"
        , lineHeight: "inherit !important"
        , display: "inline"
    }
})

export default function PurchaseWarrantyDialog(props: {
    open: boolean, onClose: any
}) {
    const classes = useStyles();
    return (<InfoDialog isOpen={props.open} onClose={props.onClose} title={<>
        SEGURO <Typography color="primary" variant='body2' className={classes.inherit} style={{ color: "#0084ff" }}>0%</Typography> RIESGO
    </>}>
        <div style={{ paddingBottom: 60 }}>
            <Typography variant="caption" component="p" color="secondary">
            Nuestra Garantía Seguro 0% Riesgo te ofrece que si tu primera marca es rechazada, cubriremos nuestra tarifa para
registrar una marca diferente. Es muy importante recalcar que siempre existe la posibilidad de que una marca sea
rechazada por el Instituto Nacional de Propiedad Industrial y que esta es nuestra forma de decirte que estamos
comprometidos con un Resultado Exitoso. Esta garantía aplica sólo en caso de existir una resolución de ‘Rechazo’ por
parte de INAPI al finalizar el proceso de registro de la marca (no incluye el monto de la tasa inicial ya pagada al
Gobierno de Chile). Exclusión de Garantía. La Garantía de Análisis no aplicará en los siguientes casos:<br/><br/>
a. Existencia de resolución de rechazo de INAPI fundado en la “Mala Fe” del usuario.<br/>
b. Existencia de resolución de rechazo de INAPI por haberse incumplido algún punto de los términos del servicio.<br/>
c. Abandonos por no pago por parte del cliente.<br/>
            </Typography>
        </div>
    </InfoDialog>
    )
}