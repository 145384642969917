import React from "react";
import API_Constructor from "../../API";
import { AuthInstance, onAuthStateChanged } from "../../lib/FirebaseConfig";
import { User } from "firebase/auth";
import Mock_API from "../../Mocks/Mock_API";
import Mock_User from "../../Mocks/Mock_User";
import AnalyticsEvents from "../../lib/AnalyticsEvents";
import { IsEmail } from "src/API/Models/DataTypeValidation";

const mock_data = process.env.NODE_ENV === "production" ? false : !!(process.env.MOCK_DATA);
const API = mock_data ? new Mock_API() : new API_Constructor();
if (API instanceof Mock_API) console.warn("Using Mock API");
export const AppContext = React.createContext<{
    user: UserData | null
    , API: API_Constructor
    , initialized: boolean
    , anonymous_activity_email?: string
}>({
    user: AuthInstance.currentUser as any,
    API,
    initialized: false,
    anonymous_activity_email: undefined
});

interface UserData {
    displayName: string,
    email: string,
    role?: "admin",
    photoURL?: string,
    providerId?: string,
    uid?: string;
}

var authInstanceIsInitialized = false;
export default function ({ children }: { children: React.ReactNode }) {

    const [user, set_user] = React.useState<UserData | null>(null);
    const [initialized, set_initialized] = React.useState(false);
    let _email_ = new URLSearchParams(window.location.search).get("email");
    if (!IsEmail(_email_)) _email_ = undefined;
    const [anonymous_activity_email, set_anonymous_activity_email] = React.useState(_email_ || localStorage.getItem("anonymous_activity_email"));

    React.useEffect(() => {
        const eventListener = function (event: { detail: { key: string, newValue?: string } }) {
            if (event.detail.key === "__all__") {
                set_anonymous_activity_email(undefined);
                return;
            }

            if (event.detail.key !== "anonymous_activity_email")
                return;

            const new_value = event.detail.newValue || undefined;
            if (new_value) {
                set_anonymous_activity_email(new_value);
                AnalyticsEvents.newLead({
                    displayName: new_value,
                    email: new_value,
                    uid: new_value && new_value.toLowerCase(),
                    providerData: [{
                        providerId: "email"
                    }]
                });
            } else {
                set_anonymous_activity_email(undefined);
            }
            setEmail(new_value);
        }
        setEmail(anonymous_activity_email);
        window.addEventListener("localstorage" as any, eventListener, false);
        return () => {
            window.removeEventListener("localstorage" as any, eventListener, false);
            setEmail(undefined);
        }
    }, []);

    const setEmail = function (email?: string) {
        AnalyticsEvents.setUser({
            displayName: email,
            email: email,
            uid: email && email.toLocaleLowerCase(),
            providerData: [{
                providerId: "email"
            }]
        });
        API.setAnonymousActivityEmail(email);
    }

    React.useEffect(() => {
        if (mock_data) {
            set_user(Mock_User());
            set_initialized(true);
            return;
        }
        const result = onAuthStateChanged(AuthInstance, async function (user: (User & { role?: "admin" }) | null) {
            // manten el orden, ya que las actualizaciones se hacen en ese mismo orden
            try {
                await API.setTokenGenerator(() => user!.getIdToken())
                await user?.getIdTokenResult()
                    .then((idTokenResult) => {
                        // Confirm the user is an Admin.
                        if (idTokenResult.claims?.role === "admin") user.role = "admin";
                    });
                AnalyticsEvents.setUser(user);
                if (authInstanceIsInitialized && user) // user now exists and it is the second time `onAuthStateChanged` is been called
                    AnalyticsEvents.logged_in(user);
                set_user(user as any);
            } catch (error) {
                console.log("onAuthStateChangeError.getIdTokenResult", error, `setting user ${user?.email} as null`);
                set_user(null);
            }
            set_initialized(true);
            authInstanceIsInitialized = true;
        }, function (errorFn) {
            console.error("AppContext.user error", errorFn);
        });
        return () => {
            result();
            API.setTokenGenerator(undefined);
        };
    }, []);

    return (<AppContext.Provider value={{
        user
        , API
        , initialized
        , anonymous_activity_email
    }}>
        {children}
    </AppContext.Provider>);
};

export function useAppContext() {
    return React.useContext(AppContext);
}