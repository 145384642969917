import React from "react";
import { useAppContext } from "../Context/AppContext";
import useNavigateToStep from "../Hooks/useNavigateToStep";
import LoadingScreen from "../LoadingScreen";
import LoginDialog from "../Login/LoginDialog";

export default function <T extends object>(Component: React.ComponentType<T>) {

    return (props: T & {
        onIsNotLoggedIn?(routes: ReturnType<typeof useNavigateToStep>): void;
    }) => {
        const { user, initialized } = useAppContext();
        const { onIsNotLoggedIn, ...remainProps } = props;
        const routes = useNavigateToStep();
        React.useEffect(() => {
            if (initialized && !user && onIsNotLoggedIn) onIsNotLoggedIn(routes);
        }, [initialized]);


        const shouldRequestLogin = !onIsNotLoggedIn;

        if (!initialized || !user)
            return (<>
                <LoadingScreen fullScreen />
                <LoginDialog open={initialized && (!user && shouldRequestLogin)} />
            </>);

        return (<Component {...remainProps as T} />)
    }
}