// import GastronomyGroup from "./Gastronomy-Group.json";
// import SoftwareGroup from "./Software-Group.json";
// import StoreGroup from "./Store-Group.json";
// import Lists from "./Lists.json";



export interface GroupItemWithNextStep {
    id: string;
    primaryText: string;
    secondaryText?: string;
    iconSrc?: string;
    selected?: boolean;
    nextStep: GroupType | ListType;
}
export interface GroupItemWithID {
    id: string;
    primaryText: string;
    secondaryText?: string;
    iconSrc?: string;
    selected?: boolean
}

export type GroupItem = GroupItemWithID | GroupItemWithNextStep;

const MEMO_LISTS: {
    [key: string]: {
        id?: string
        , primaryText: string
        , secondaryText?: string
        , representa_clase_total?: boolean
        , selected?: boolean

        , iconSrc?: string
        , iconName?: string
        , nextStep?: ListType | GroupType
    }[]
} = {}

export function GetFlow(name: ListType | GroupType | "root-group"
    , data: StepState = {}
    , SORT_DATA: boolean = true
    , callback?: () => void
    , error?: (err: Error) => void): GroupItem[] {
    loadItem(name).then(callback).catch(error);
    // La primera vez obtengo el Flow, y luego se actualiza
    var GenericList = MEMO_LISTS[name] || [];

    return GenericList.map(x => {
        const id = x.id || btoa(JSON.stringify(x));
        /** Se crea un nuevo diccionario
         * solo con las propiedades que tienen valor
         */
        var result: GroupItem = { id, primaryText: x.primaryText };
        if (x.secondaryText) result.secondaryText = x.secondaryText;
        if (x.iconSrc) result.iconSrc = x.iconSrc;
        if (!!data[x.id]) result.selected = x.selected;
        /** Es importante que se haga de esta forma, ya que
         * hay validaciones de tipo `"nextStep" in result`. Y aunque el valor sea `undefined`
         * la validación será positiva. De esta forma, la propiedad solo existe si hay un valor en la propiedad ´nextStep´
         */
        if (x.nextStep) (result as GroupItemWithNextStep).nextStep = x.nextStep;
        if (!result.iconSrc && x.iconName) result.iconSrc = `${process.env.PUBLIC_URL}/svg/root-group/${x.iconName}.svg`;
        return result;
    });
}

async function loadItem(Id: ListType | GroupType | "root-group") {
    if (Id in MEMO_LISTS) return MEMO_LISTS[Id];
    MEMO_LISTS[Id] = [];

    var result: any = [];
    if (Id === "root-group")
        result = (await import("./Root-Group.json")).default;
    if (Id === "icpa-product-list")
        result = await ((await fetch(process.env.PUBLIC_URL + "/flow-templates/ICPA-Product-List.json")).json());
    if (Id === "icpa-service-list")
        result = await ((await fetch(process.env.PUBLIC_URL + "/flow-templates/ICPA-Service-List.json")).json());
    if (Id === "icpa-store-product-list")
        result = await ((await fetch(process.env.PUBLIC_URL + "/flow-templates/ICPA-Store-Product-List.json")).json());
    if (Id === "product-class-group")
        result = (await import("./Product-Class-Group.json")).default;
    if (Id === "service-class-group")
        result = (await import("./Service-Class-Group.json")).default;
    if (Id === "store-group")
        result = (await import("./Store-Group.json")).default;
    MEMO_LISTS[Id] = result;
    return result;
}

export function GetHeadings(name: ListType | GroupType | "root-group") {

    if (["product-class-group", "icpa-product-list"].includes(name)) return {
        title: <>¿Qué <mark>PRODUCTOS</mark> llevan tu marca?</>,
        subtitle: "Puedes seleccionar más de una opción"
    }
    if (["icpa-service-list", "service-class-group"].includes(name)) return {
        title: <>¿Qué <mark>SERVICIOS</mark> llevan tu marca?</>,
        subtitle: "Puedes seleccionar más de una opción"
    }
    if (name === "icpa-store-product-list") return {
        title: <>¿Qué <mark>PRODUCTOS</mark> vende tu TIENDA</>,
        subtitle: "Puedes seleccionar más de una opción"
    }

    // if (name === "transport-product-list") return {
    // title: "¿Qué PRODUCTOS TRANSPORTAS?",
    // subtitle: "Puedes seleccionar más de una opción"
    // }

    // if (name === "software-group") return {
    // title: "Software / App / Web",
    // subtitle: "Selecciona qué lleva la marca. Puedes seleccionar más de una opción."
    // }

    if (name === "store-group") return {
        title: <>¿Que tipo de <mark>TIENDA</mark> lleva la marca?</>,
        subtitle: "Puedes seleccionar más de una opción"
    }

    if (name === "root-group") return {
        title: "¿Qué lleva tu marca?",
        subtitle: "Puedes seleccionar más de una opción"
    }

    // if (name === "gastronomy-group") return {
    //  title: "Gastronomía",
    // subtitle: "Puedes seleccionar más de una opción"
    // }

    return {
        title: null
        , subtitle: null
    }
}