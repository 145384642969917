import API from "../API";
import { faker } from "@faker-js/faker";
import Mock_FetchRequest from "./Mock_FetchRequest";
import Resumen from "../API/Resumen";

export default class Mock_API extends API {
    protected MEMORY: Partial<Lead>[] = [];

    FetchRequest = Mock_FetchRequest;

    CheckoutRequest(body: MinimalLead): Promise<{ id: string }> {
        const id = faker.database.mongodbObjectId();
        this.MEMORY.push({
            ...body, id,
            editable: true,
            resumen: Resumen(true),
            payments: [],
            status: "CREATED"
        });
        return Promise.resolve({
            id
        });
    }
    checkoutSearch(body: { selected_clases: number[], id: string }): Promise<{ id: string }> {
        return Promise.resolve(body);
        // return this.CheckoutRequest(body);
    }

    GetQuote(body: {
        selected_clases: number[];
        cuotas: number;
        promo_code?: string | undefined;
        id?: string | undefined;
    }): Promise<{
        payments: Payment[];
        resumen: Lead["resumen"];
    }> {

        return Promise.resolve({
            payments: []
            , resumen: { ...Resumen(true), remarkable_discount: true }
        })
    }


}