const highlightMemoMap = new Map<string, string>();

export function HighlightText(content: string, highlightedWords: string) {
    // este approach no funciona porque recoge a toda la palabra completa.
    // const regex = new RegExp(`\\p{L}*${highlightedText}\\p{L}*`, 'giu'); // 'g' para reemplazar todas las ocurrencias, 'i' para insensible a mayúsculas y minúsculas.

    const result_token = [content, highlightedWords].toString();
    if (highlightMemoMap.has(result_token))
        return highlightMemoMap.get(result_token)!;

    const _highlightedTexts = [];
    const words = highlightedWords.split(" ");
    for (const word of words) {
        if (!word.trim().length) continue;
        _highlightedTexts.push(
            escapeRegExp(word.trim()).toLowerCase().replaceAll("a", "[aàá]")
                .replaceAll("e", "[eèé]")
                .replaceAll("i", "[iìí]")
                .replaceAll("o", "[oòó]")
                .replaceAll("u", "[uùú]")
                .replaceAll("n", "[nñ]")
        );
    }

    const highlighted_text = content.replace(new RegExp(`(${_highlightedTexts.join("|")})`, "giu"), "<mark>$&</mark>");
    highlightMemoMap.set(result_token, highlighted_text);
    return highlighted_text;
}

function escapeRegExp(text: string) {
    return text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}