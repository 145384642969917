import React from "react";
import { Button, CircularProgress, ClickAwayListener, makeStyles, Popper, Theme } from "@material-ui/core";
import { useAppContext } from "../../Components/Context/AppContext";
import Line from "../../Components/Line";
import LoginDialog from "../../Components/Login/LoginDialog";
import LandingLogo from "./LandingLogo";
import LoginDropdown from "../../Components/Login/LoginDropdown";
import useBreakpoints from "../../Components/Hooks/useBreakpoints";
import AnalyticsEvents from "../../lib/AnalyticsEvents";
import CallSales from "../Item-Selection-Flow/CallSales";

const useStyles = makeStyles((theme: Theme) => ({
    Logo: {
        marginLeft: 45
        , marginRight: 30
        , marginTop: 19 + 3
        , marginBottom: 17 - 1
        , [theme.breakpoints.down("xs")]: {
            marginLeft: 25
        }
    }
}));

export const LandingLogoStyles = useStyles;

export default function (props: {
    fixedHeader?: boolean,
    show_login: boolean
}) {

    const classes = useStyles();

    return (<>
        <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div>
                    <LandingLogo
                        className={classes.Logo}
                    />
                </div>
                <div style={{ flex: 1, flexGrow: 1, textAlign: "right", paddingRight: 20 }}>
                    <CallSales />
                </div>
                {props.show_login ? <UserOptionsBtn /> : null}
            </div>
            <Line size={1} use="header" />
        </div>
    </>);
}

function UserOptionsBtn() {
    const [loginOpen, set_loginOpen] = React.useState(false);
    const [anchorEl, set_anchorEl] = React.useState<any>(null);
    const { user, initialized } = useAppContext();
    const { xsDown } = useBreakpoints();
    return (<>
        <div style={{ flex: 1, flexGrow: 1, textAlign: "right", paddingRight: xsDown ? 20 : 40 }}>
            <ClickAwayListener onClickAway={() => {
                console.info("Clicked Away");
                anchorEl ? set_anchorEl(null) : null;
            }}>
                <div>
                    <Button
                        onClick={event => set_anchorEl(anchorEl ? null : event.currentTarget)}
                        variant="text"
                        className="change-to-primary-on-hover"
                        color="secondary"
                        type="button"
                    >
                        {initialized
                            ? (user ? user.displayName!.split(" ")[0] : "Iniciar sesión")
                            : <CircularProgress size={30} color="inherit" />}
                    </Button>
                    <Popper
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        style={{
                            margin: "auto",
                            marginTop: 15,
                            width: 210
                        }}
                        placement="bottom-end"
                        onClick={() => set_anchorEl(null)}
                    >
                        <LoginDropdown
                            onLoginClick={() => {
                                set_loginOpen(true);
                                AnalyticsEvents.click("iniciar_sesion_o_registrarse");
                            }}
                            logOutPressed={() => {
                                AnalyticsEvents.click("cerrar_sesion");
                            }}
                        />
                    </Popper>
                </div>
            </ClickAwayListener>
        </div >
        <LoginDialog open={loginOpen} onClose={() => set_loginOpen(false)} />
    </>);
}