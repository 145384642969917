import { Button, makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
    button_with_icon: {
        marginTop: 20
    }
});

export default function (props: {
    onClick(): void;
    text: string;
    iconSrc: string;
    isLoading?: boolean;
    disabled?: boolean;
}) {

    const classes = useStyles();
    return (<Button
        color="secondary"
        type="button"
        variant="outlined"
        fullWidth
        className={classes.button_with_icon}
        onClick={() => props.onClick()}
        disabled={props.disabled || props.isLoading}
    >
        {props.isLoading
            ? (<CircularProgress size={30} color="inherit" />)
            : (<div style={{ display: "flex", width: "100%", paddingTop: 5, paddingBottom: 5, flexDirection: "row", justifyItems: "center", alignItems: "center" }}>
                <img
                    height={25}
                    src={props.iconSrc}
                />
                <div style={{ flex: 1, flexGrow: 1 }} >
                    {props.text}
                </div>
            </div>)}
    </Button>);
}