import { FirebaseError } from "firebase/app";
import EnAuthErrors from "./AuthErrors.en.json";
import EsAuthErrors from "./AuthErrors.es.json";

export function FirebaseErrorMessage(ex: FirebaseError): string {
    if (!ex.code) return ex.message;
    const args = ex.code.split("/");
    const propName: keyof typeof EnAuthErrors | keyof typeof EsAuthErrors = args[1] as any;

    if (EsAuthErrors[propName]) return EsAuthErrors[propName];
    return EnAuthErrors[propName] || "Ocurrió un error";
}

export function FirebaseErrorCredentialFirebaseEmail(ex: FirebaseError): string | undefined {
    const [_, propName] = ex.code.split("/");
    if (propName !== "account-exists-with-different-credential")
        return undefined;
    return ex.customData?.email as any;
}