import ListItemInput from "./List-Item-Input";
import { GroupItemWithNextStep } from "../Flow-templates/";
// import { useAppContext } from "../../../Components/Context/AppContext";
// import SuperBoton from "./SuperBoton";
// import { extractCustomClasesFromStepState } from "../../../Components/Hooks/lib/StepState_CustomClases";
import findMatchesInsideItem from "../../Flow.v2/FindMatchesInsideItem";
import LoadingScreen from "src/Components/LoadingScreen";
import ErrorScreen from "src/Components/ErrorScreen";
import fetchFlowData from "../Flow-templates/useFetchFlow";


export default function SelectGroup(props: {
    data: Set<string>,
    type: GroupType | "root-group",
    onChange(data: Set<string>): void
    onSubmenuClicked?(item: GroupItemWithNextStep): void
}) {
    const { data, type, onChange } = props;

    const [sorted_items, loading, error] = fetchFlowData(type);

    // TODO: cambiar esto a `claims`
    // const { user } = useAppContext();

    if (loading) return <LoadingScreen />;
    if (error) return <ErrorScreen {...error} />;
    // const items = extractCustomClasesFromStepState(data) || [];

    return (<ListItemInput
        items={sorted_items}
        iconDimension="large"
        searchBar={true}
        itemSelectedChecker={item => findMatchesInsideItem(item, props.data).length > 0}
        onClick={item => {

            if (props.onSubmenuClicked && "nextStep" in item)
                return props.onSubmenuClicked(item);

            const new_state = new Set(data);
            if (new_state.has(item.id)) {
                new_state.delete(item.id)
            } else {
                new_state.add(item.id)
            }
            onChange(new_state);
        }}
    >
        {/*(user?.role === "admin" && type === "root-group")
            ? <SuperBoton items={items} onDataChange={items => {
                onChange(addCustomClasesToStepState(items, data));
            }} />
            : null*/}
    </ListItemInput>);

}