import { faker } from "@faker-js/faker";

export default function (fake_values = false) {
    return {
        clases:  0,
        clases_amount: fake_values ? RandomCurrency() : 0,
        valor_descuento: fake_values ? RandomCurrency() : 0,
        UTM: fake_values ? RandomCurrency() : 0,
        UTM_inicial: fake_values ? RandomCurrency() : 0,
        UTM_inicial_amount: fake_values ? RandomCurrency() : 0,
        UTM_final: fake_values ? RandomCurrency() : 0,
        cuotas: fake_values ? RandomCurrency() : 0,
        info_aumento_cuotas: fake_values ? RandomCurrency() : 0,
        cuota_amount: fake_values ? RandomCurrency() : 0,
        cuota_inicial_amount: fake_values ? RandomCurrency() : 0,
        total: fake_values ? RandomCurrency() : 0,
        currency: "CLP"
    } as Lead["resumen"];
}

function RandomCurrency() {
    return faker.datatype.number({ min: 100 * 1000, max: 200 * 1000, precision: 1 });
}