import React from "react";
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import InfoDialog from "./InfoDialog";
import { useAppContext } from "./Context/AppContext";


export default function () {
    const [dialog, set_dialog] = React.useState(false);

    const [UTM, set_UTM] = React.useState(null as number | null);
    const { API } = useAppContext();
    React.useEffect(() => {
        API.UTM().then(value => {
            set_UTM(value.UTM)
        }).catch(ex => {
            set_UTM(0);
            console.error("API.UTM error", ex);
        })
    }, []);

    const rows = [""];

    return (<>
        <Typography onClick={() => set_dialog(true)} style={{ display: "inline-block", zIndex: 0, fontSize: 13 }} component="a" variant="caption" color="primary">
            Ver Detalle
        </Typography>
        <InfoDialog isOpen={dialog} onClose={() => set_dialog(false)} title="Tasa final de registro de marca">
            <div style={{ marginBottom: 60 }}>
                <Typography variant="caption" component="p" color="secondary" style={{ lineHeight: 1.5 }}>
                    Este pago se debe realizar una vez que sea aceptado el registro de la marca por parte de INAPI (en aproximadamente 5 meses más).<br />
                    <br />
                    El costo de la tasa variará dependiendo la cantidad de clases a proteger con tu registro de marca:<br />
                    <br />
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">Tasa Final</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rows.map((_, index) => (<UTMRow key={index} clases={index + 1} />)))}
                    </TableBody>
                </Table>
                <Typography variant="caption" component="p" color="secondary">
                    <br />
                    <br />
                    Te enviaremos un botón para pago a tu correo electrónico al momento de ser requerido. El monto está en UTM por lo que se calculará el valor en pesos chilenos al momento de realizar el pago.<br />
                    <br />
                    Valor referencial UTM actual: {UTM === null ? <CircularProgress size="1em" color="inherit" /> : (UTM ? UTM.toCurrency() : "(No disponible)")}
                </Typography>
            </div>
        </InfoDialog >
    </>);
}

function UTMRow(props: { clases: number }) {
    return (<TableRow>
        <TableCell>
            <Typography variant="body1" component="small" color="secondary">
                {props.clases * 2} UTM por cada clase
            </Typography>
        </TableCell>
    </TableRow>);
}