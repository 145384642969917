export function IsRunningOnInAppBrowser() {
    const ua = navigator.userAgent || navigator.vendor || "";
    return ua.includes("Instagram") || ua.includes("Facebook");
}

export function OpenInStandardBrowser(url: string = window.location.href) {
    const x = document.createElement("a");
    x.href = window.location.href;
    x.download = "download";
    document.body.append(x);
    setTimeout(() => x.click(), 300);
    return;
}