import { Grid, ImageList, ImageListItem, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useBreakpoints from "../../Components/Hooks/useBreakpoints";


const CarouselLayoutStyles = makeStyles((theme: Theme) => ({
    carouselRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        marginRight: -20,
        marginLeft: -20,
    },

    // carousel only
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',

        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        display: "flex",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none"
    },
    imageListItem: {
        height: "auto !important",
        flexShrink: 0,
        scrollSnapAlign: "start",
    }
    // carousel only
}));


export default function () {
    const carouselClasses = CarouselLayoutStyles();
    const { smUp } = useBreakpoints();

    const Item1 = (<CarouselContent
        number="1"
        title="Proceso de registro de principio a fin"
        text="Tramitación de 5 meses ante el Instituto Nacional de Propiedad Industrial (INAPI), Diario Oficial y Notarías. Desde la presentación de la solicitud hasta la entrega finalizada de la Marca Registrada."
    />);

    const Item2 = (<CarouselContent
        number="2"
        title="La empresa con más procesos de Chile"
        text="Simple Marcas es realmente la empresa número 1 en Registros de Marca en Chile, con miles de registros de marcas al año, superando a cualquier otro estudio jurídico del país, es lo que hacemos, somos especialistas."
    />);

    const Item3 = (<CarouselContent
        number="3"
        title="Asistencia por 10 años"
        text="Servicio de atención al cliente durante todo el proceso de registro y por los 10 años de duración del registro. También contarás con el seguimiento automático de tu solicitud para que siempre sepas en qué etapa se encuentra."
    />);

    if (smUp) {
        return (<Grid container spacing={2} >
            <Grid item sm={4}> {Item1} </Grid>
            <Grid item sm={4}> {Item2} </Grid>
            <Grid item sm={4}> {Item3} </Grid>
        </Grid >)
    }



    return (<div className={carouselClasses.carouselRoot}>
        <ImageList className={carouselClasses.imageList} cols={1.28} gap={15} >
            <ImageListItem className={carouselClasses.imageListItem} style={{ paddingLeft: 20, paddingRight: 0 }} >
                {Item1}
            </ImageListItem>
            <ImageListItem className={carouselClasses.imageListItem} style={{ paddingLeft: 20, paddingRight: 0, marginRight: 20 }} >
                {Item2}
            </ImageListItem>
            <ImageListItem className={carouselClasses.imageListItem} style={{ paddingRight: 20, paddingLeft: 0 }} >
                {Item3}
            </ImageListItem>
        </ImageList>
    </div>)
}


const carouselStyles = makeStyles((theme: Theme) => ({
    title: {
        color: theme.palette.primary.light,
    },
    text_title: {
        marginTop: 12,
        marginBottom: 3
    },
    img: {
        maxWidth: "100%",
        height: "auto",
        borderRadius: 10,
        [theme.breakpoints.down("xs")]: {
            borderRadius: 7
        }
    }
}));

function CarouselContent(props: {
    title: string,
    text: string,
    number: string
}) {
    const classes = carouselStyles();
    return (<>
        <img src={process.env.PUBLIC_URL + "/img/carousel-" + props.number + ".png"} className={classes.img} />
        <Typography variant="body2" className={classes.text_title} >
            {props.title}
        </Typography>
        <Typography variant="caption" component="p">
            {props.text}
        </Typography>
    </>);
}