import { faker } from "@faker-js/faker";
import API from "../API";
import Resumen from "../API/Resumen";
import Mock_API from "./Mock_API";

export default (function (
    this: Mock_API,
    id: string,
): Promise<Lead> {

    var exists = this.MEMORY.find(x => x.id === id);
    if (exists) return Promise.resolve(exists as Lead);

    const clases = Mock_Clases();
    return Promise.resolve({
        brand_holders: [],
        brand_holders_type: "persona",
        clases,
        id: "123",
        invoice_type: "factura",
        logo: false,
        marca: faker.random.words(),
        payments: [],
        promo_code: "",
        resumen: {
            ...Resumen(true)
            , clases: Object.keys(clases).length
        },
        editable: true,
        status: "CREATED"
    } as any as Lead);


}) as API["FetchRequest"];



import { GetFlow } from "../Layouts/Item-Selection-Flow/Flow-templates";

export function Mock_Clases() {


    const items = take_random(GetFlow("service-list" as any))
        .concat(take_random(GetFlow("store-product-list" as any)));

    const clases: ClaseMap = {};

    for (const item of items) {
        if (!item) continue;
    }

    return clases;
}




function take_random<T extends Array<any>>(list: T, values: number = 5) {
    /** @ts-ignore */
    var choosen: T = [];
    for (let i = 0; i < values; i++) {
        var idx = faker.datatype.number({
            min: 0, max: list.length, precision: 1
        });
        choosen.push(list[idx]);
    }
    return choosen;
}