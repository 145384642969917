import { useSearchParams } from "react-router-dom";
import Base64 from "./Base64";

export class URLSearchState {
    marca?: string = undefined;
    stepTree?: StepTree = undefined;
    selectedClassIds?: number[] = undefined;
    cuotas?: number = undefined;
}


function StateToURLSearchParams(searchParams: URLSearchParams, newState?: URLSearchState) {
    const new_params = new URLSearchParams(searchParams.toString());

    if (newState && Object.keys(newState).length) {
        new_params.set("_m", Base64.encode_from_utf8(JSON.stringify(newState)))
    } else {
        new_params.delete("_m");
    }

    return new_params;
}

export function MergeStateFromSearchParams(searchParams: URLSearchParams, updatedState: URLSearchState = {}) {

    const SearchState = GetStateFromParams(searchParams);

    const mergedState: URLSearchState = { ...SearchState };

    Object.keys(new URLSearchState()).forEach((key) => {
        if (updatedState.hasOwnProperty(key)) {
            mergedState[key] = updatedState[key];
        }
    });

    return StateToURLSearchParams(searchParams, mergedState);
}

function GetStateFromParams(searchParams: URLSearchParams) {
    var SearchState = new URLSearchState();
    try {
        let value = searchParams.get("_m");
        if (!value) throw new Error("_m parameter not exist");
        SearchState = JSON.parse(Base64.decode_to_utf8(value));
    } catch (error) {
        if (process.env.NODE_ENV === "development")
            console.warn("[DEV] - GetStateFromParams no puede generar el estado ", { searchParams: searchParams.toString(), error });
    }
    return SearchState;
}

export default function useStateFromSearchParams() {
    const [searchParams, set_searchParams] = useSearchParams();
    const SearchState = GetStateFromParams(searchParams);
    const marca = SearchState.marca || "";
    const selectedClassIds = (SearchState.selectedClassIds || []).map(x => +x);
    const stepTree = SearchState.stepTree;
    const cuotas = SearchState.cuotas || "";


    const setInitialValues = function (newState?: URLSearchState) {
        set_searchParams(
            StateToURLSearchParams(searchParams, newState)
        );
    }

    const updateInitialValues = function (updatedState: URLSearchState) {
        set_searchParams(
            MergeStateFromSearchParams(searchParams, updatedState)
        );
    }

    return {
        marca
        , stepTree
        , selectedClassIds
        , cuotas
        , setInitialValues
        , updateInitialValues
    }
}

