import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";

const useStyles = makeStyles({
    inputGroup: {
        "& > div": {
            marginBottom: "12px !important"
        }
    }
})

export default function (props: {
    name: string,
    phone: string,
    email: string,
    password: string,
    onNameChange(value: string): void;
    onPhoneChange(value: string): void;
    onPasswordChange(value: string): void;
    onEmailChange(value: string): void;
    name_error?: string;
    phone_error?: string;
    email_error?: string;
    password_error?: string;
    disableInputs?: {
        name?: boolean,
        phone?: boolean,
        email?: boolean,
    },
    disabled?: boolean;
}) {
    const classes = useStyles();
    return (<div className={classes.inputGroup} >
        <TextField
            fullWidth
            value={props.name}
            label="Nombre Completo"
            type="text"
            variant="outlined"
            color="secondary"
            disabled={props.disabled || props.disableInputs?.name}
            error={!!props.name_error}
            helperText={props.name_error}
            onChange={event => props.onNameChange(event.target.value)}
        />
        <PhoneInput
            onChange={props.onPhoneChange}
            value={props.phone}
            disabled={props.disabled || props.disableInputs?.phone}
            error={props.phone_error}
        />
        <TextField
            fullWidth
            value={props.email}
            label="Correo electrónico"
            type="email"
            variant="outlined"
            disabled={props.disabled || props.disableInputs?.email}
            color="secondary"
            onChange={event => props.onEmailChange(event.target.value)}
            error={!!props.email_error}
            helperText={props.email_error}
        />
        <PasswordInput
            value={props.password}
            disabled={props.disabled}
            onChange={props.onPasswordChange}
            error={props.password_error}
        />
    </div>)
}