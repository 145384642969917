import { Card } from "@material-ui/core";
export default function (props: {
    logoUrl?: string | null
    , size?: number
    , dimension: "normal" | "large"
}) {
    const size = props.size || 60;
    return (<Card elevation={0} style={{
        width: size
        , height: size
        , textAlign: "center"
        , display: "flex"
        , borderRadius: 8
        , justifyContent: "center"
        , alignItems: "center"
        /*, backgroundColor: HOVER_COLOR
        , borderColor: BORDER_COLOR
        , borderWidth: 0.5
        , borderStyle: "solid"*/
        , color: "transparent"
    }} >
        {props.logoUrl && <img src={props.logoUrl} style={{ maxWidth: size - (props.dimension === "normal" ? 20 : 0), maxHeight: size - (props.dimension === "normal" ? 20 : 0), margin: "auto" }} />}
    </Card>)
}