import LandingHeader from "./LandingPage/LandingHeader";
import { Hidden, makeStyles, Typography, } from "@material-ui/core";
import React from "react";
import ProductSummaryList from "../Components/ProductSummaryList";
import PurchaseWarranty from "../Components/PurchaseWarranty";
import LoadingScreen from "../Components/LoadingScreen"
import useBreakpoints from "../Components/Hooks/useBreakpoints";
import CopyRightFooter from "./LandingPage/CopyRightFooter";
import Line from "../Components/Line";
import { LeftContainer, MainContainer, RightContainer } from "./PurchaseProcess/OrderLayout/Containers";
import { BORDER_COLOR } from "../Theme/colors";

export const ORDER_LAYOUT_FOOTER_CONTAINER_ID = "ORDER-LAYOUT-FOOTER-CONTAINER";

const useStyles = makeStyles(theme => ({
    "@global": {
        ".aside-root > .MuiPaper-root.MuiPaper-rounded": {
            padding: 20
        }
    },
    text: {
        fontSize: +(theme.typography.body1.fontSize!) - 1,
        lineHeight: 1.4
    },
    Aside_root: {
        [theme.breakpoints.up("sm")]: {
            position: "sticky"
            , top: 30
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: -20,
            marginRight: -20,
            marginTop: 40
        }
    },
    summary_card: {
        border: "1px solid #d9dbdc"
        , paddingTop: 25
        , padding: 20
        , boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.07)"
        , paddingBottom: 7
        , borderRadius: 20
        , marginBottom: 20
        , [theme.breakpoints.down("xs")]: {
            paddingTop: 30,
            paddingBottom: 10,
            borderRadius: 0,
            boxShadow: "none",
            border: 0,
            borderTop: `6px solid ${BORDER_COLOR} !important`,
            borderBottom: `6px solid ${BORDER_COLOR} !importan`,
        }
    }
    , leftTitle: {
        marginTop: 17
    }


    , root: {
        marginTop: 38
        , [theme.breakpoints.down("xs")]: {
            marginTop: 0
        }
    }
    , ContentContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingRight: "80px !important",
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: "initial",
            width: "100%"
        }
    }
    , AsideContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingLeft: "17px !important",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "initial",
            width: "100%"
        },
    }
    , AsideContent: {
        position: "sticky",
        top: 90,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -20,
            marginRight: -20,
            marginTop: 30
        }
    }
}));


export default function OrderLayout(props: {
    children: React.ReactNode,
    marca: string,
    clases_seleccionadas: Array<string | number>,
    resumen: Lead["resumen"],
    payments: Lead["payments"],
    isLoading?: boolean,
    hide_detalle_on_xs?: boolean,
    rightSubmitButton?: React.ReactNode,
    detalle_card_ref?: any;
    inner_detalle_list_ref?: any;
    xsFooter?: React.ReactNode;
    copyright_footer?: boolean;
    customHeader?: React.ReactNode;
}) {

    const classes = useStyles();
    const { xsDown } = useBreakpoints();

    const { rightSubmitButton, marca, payments, resumen, clases_seleccionadas } = props;
    const total_saved = (props.resumen.clases_saved_amount || 0) + (props.resumen.valor_descuento || 0);
    const RIGHT_SIDE_BUTTON = (<>
        {rightSubmitButton}
        <div style={{ textAlign: "center", marginBottom: 25 - 7 }}>
            {props.isLoading
                ? null
                : (<Typography color="secondary" variant="caption" component="small" >
                    Haz ahorrado {total_saved.toCurrency()} en tu pedido
                </Typography>)}
        </div>
    </>);

    const CLASES_INFO = (<div style={{ marginTop: 5, marginBottom: 10 }}>
        <Typography color="secondary" className="c-303030" component="small" variant="body1">
            {marca}
        </Typography><br />
        <Typography color="secondary" className="c-303030" component="small" variant="body1">
            Clase {clases_seleccionadas.join(", ")}
        </Typography>
        <div style={{ marginTop: 15 }} >
            <Line size={1} />
        </div>
    </div>);

    return (<div>
        {props.customHeader || <LandingHeader show_login={false} />}

        <MainContainer>
            <LeftContainer>
                {props.children}
                {props.copyright_footer
                    ? (xsDown
                        ? null
                        : (<div style={{ marginTop: 40, zIndex: 2 }}>
                            <CopyRightFooter />
                        </div>))
                    : null}

            </LeftContainer>

            <RightContainer style={(props.hide_detalle_on_xs && xsDown) ? { display: "none" } : undefined}>
                <div ref={props.detalle_card_ref as any} className={classes.AsideContent} >
                    <div className={classes.summary_card} >

                        <Typography style={{ wordBreak: "break-word", marginBottom: 10 }} color="secondary" className="c-303030" variant="h4">Carrito</Typography>

                        {rightSubmitButton ? null : CLASES_INFO}

                        {props.isLoading
                            ? (<div style={{ marginTop: 50, marginBottom: 50 }}><LoadingScreen fullScreen={false} text={false} /></div>)
                            : (<ProductSummaryList payments={payments} {...resumen} />)}

                        {rightSubmitButton ? RIGHT_SIDE_BUTTON : null}
                    </div>
                    {props.isLoading ? null : <PurchaseWarranty />}
                </div>
            </RightContainer>

        </MainContainer>


        <div id={ORDER_LAYOUT_FOOTER_CONTAINER_ID}>
            {props.xsFooter ? <Hidden smUp>{props.xsFooter}</Hidden> : null}
        </div>
    </div>)
}