export function ValidatePhoneE164Format(phoneNumber: string) {
    const regEx = /^\+[1-9]\d{1,14}$/;

    return regEx.test(phoneNumber);
}

export function IsEmail(email: string) {
    if (IsEmpty(email)) return false;
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function IsEmpty(value: any) {
    if (value === null) return true;
    if (value === undefined) return true;
    if (value === "") return true;
    if (!value.toString().length) return true;
    return false;
}
export function IsPhoneNumber(phoneNumber: string) {
    if (IsEmpty(phoneNumber)) return false;
    return phoneNumber.replace(/\s/g, '').match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im
    );
}