import { Chip, Typography } from "@material-ui/core";
import React from "react";
import ListItemInput from "../Item-Selection-Flow/Select-Group/List-Item-Input";
import findMatchesInsideItem from "./FindMatchesInsideItem";
import SelectItemDialog from "./Select-Item-Dialog";
import fetchFlowData from "../Item-Selection-Flow/Flow-templates/useFetchFlow";
import ErrorScreen from "src/Components/ErrorScreen";

export default function GroupContainerPage(props: {
    selectedIds: Set<string>
    , onAddId(id: string): void
        , onRemoveId(id: string): void
        , onSetIds(selectedIds: Set<string>): void
}) {
    const { selectedIds, onAddId, onRemoveId, onSetIds } = props;
    const [items, isLoading, error] = fetchFlowData("root-group");
    const [popup_type, set_popup_type] = React.useState<ListType | GroupType | null>(null);

    if (error) return <ErrorScreen {...error} />;

    return (<>
        <ListItemInput
            items={items}
            loadingState={{
                isLoading,
                skeletonItems: 3
            }}
            itemSelectedChecker={item => findMatchesInsideItem(item, selectedIds).length > 0}
            onClick={(item, selected) => {
                if ("nextStep" in item)
                    return set_popup_type(item.nextStep);
                if (selected)
                    return onRemoveId(item.id);
                onAddId(item.id);
            }}
            innerItemChildren={item => {
                const itemsFoundInsideRoot = findMatchesInsideItem(item, selectedIds);
                if (!itemsFoundInsideRoot.length) return null;
                return (<div style={{ paddingTop: 5 }} >
                    <Chip component={Typography} color="primary" style={{ height: 21 }} label={itemsFoundInsideRoot.length + " seleccionados"} />
                </div>);
            }}
        />
        <SelectItemDialog
            showListName={popup_type}
            selectedIds={popup_type ? selectedIds : new Set()}
            onClose={new_change_set => {
                set_popup_type(null);
                if (new_change_set)
                    onSetIds(new Set(new_change_set));
            }}
        />
    </>);
}
