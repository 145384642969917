import { makeStyles } from "@material-ui/core";
import React from "react";
import { HOVER_COLOR } from "../../Theme/colors";

const useStyles = makeStyles(theme => ({
    notification_div: {
        padding: 28
        , backgroundColor: HOVER_COLOR
        , display: "flex"
        , borderRadius: 8
        , flexDirection: "row"
        , justifyContent: "center"
        , alignItems: "center"
        , [theme.breakpoints.down("xs")]: {
            marginTop: 0
        }
    }
}));

export default function (props: React.HTMLAttributes<HTMLDivElement> & {
    iconSrc: string, iconHeight?: number, style?: React.CSSProperties
}) {
    const classes = useStyles();
    const { className, iconSrc, iconHeight, ...divProps } = props;
    return (
        <div {...(divProps || {})} style={props.style || undefined}
            className={classes.notification_div + " " + (className || "")}
        >
            <div>
                <img src={iconSrc} height={iconHeight || 25} />
            </div>
            <div style={{ flex: 1, flexGrow: 1, padding: 5, marginLeft: 10 }}>
                {props.children}
            </div>
        </div>);
}