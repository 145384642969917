import { TextField, Typography } from "@material-ui/core";
import { FirebaseError } from "firebase/app";
import { sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import React from "react";
import { IsEmail, IsEmpty, ValidatePhoneE164Format } from "../../API/Models/DataTypeValidation";
import SubmitButton from "../../Layouts/PurchaseProcess/Submit-Button";
import { FirebaseErrorCredentialFirebaseEmail, FirebaseErrorMessage } from "../../lib/FirebaseAuthError";
import { AuthInstance } from "../../lib/FirebaseConfig";
import { IsRunningOnInAppBrowser } from "../../lib/In-App-Browser-Support";
import { useAppContext } from "../Context/AppContext";
import BackIcon from "../Icons/BackIcon";
import Line from "../Line";
import TitleInfoDialog from "../TitleInfoDialog";
import FacebookLoginButton from "./FacebookLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";
import PasswordInput from "./PasswordInput";
import SignInForm from "./SignInForm";


type FormType = "email" | "password" | "sign-up" | "password-recovery" | "signin-on-browser-email-sent";


export default function LoginForm(props: {
    onLoggedIn(): void;
    fixedValues?: {
        email?: string,
        phone?: string,
        name?: string
    },
    beginOn?: "sign-up" | "email"
}) {
    const { API } = useAppContext();

    const [form, set_form] = React.useState<FormType>(props.beginOn || "email");

    const [email, set_email] = React.useState(props.fixedValues?.email || "");

    const [password, set_password] = React.useState("");

    const [name, set_name] = React.useState(props.fixedValues?.name || "");
    const [phone, set_phone] = React.useState(props.fixedValues?.phone || "");

    const [status, set_status] = React.useState<"PASSWORD-RECOVERY-COMPLETED" | "SUBMIT" | "ERROR" | "GOOGLE" | "FACEBOOK" | "">("");
    const [error, set_error] = React.useState<Error & { [key: string]: any } | FirebaseError & { [key: string]: any } | null>(null);

    const [email_error, set_email_error] = React.useState("");
    const [name_error, set_name_error] = React.useState("");
    const [password_error, set_password_error] = React.useState("");
    const [phone_error, set_phone_error] = React.useState("");

    const showSocialNetworkButtons = (form === "email") && !IsRunningOnInAppBrowser();

    React.useEffect(() => {
        if (status === "SUBMIT") {
            set_email_error("");
            set_name_error("");
            set_password_error("");
            set_phone_error("");
            set_error(null);
        }
    }, [status]);

    React.useEffect(() => {
        set_password("");
    }, [form]);


    React.useEffect(() => {
        if (!(form === "email" && status === "SUBMIT")) return;
        if (!IsEmail(email)) {
            set_email_error("Digite un correo válido");
            set_status("");
            return;
        }
        API.userEmailExists(email, !showSocialNetworkButtons)
            .then(response => {
                set_status("");
                if (!response.exists) return set_form("sign-up");
                if (response.isFbUser) {
                    set_error({
                        name: "email-already-exists"
                        , provider: "facebook"
                        , message: "Este usuario ya inició sesión desde Facebook, haz click en 'Continuar con Facebook' para continuar"
                    });
                    if (response.signInFromBrowserEmailSent)
                        set_form("signin-on-browser-email-sent");
                    return;
                }
                if (response.isGoogleUser) {
                    set_error({
                        name: "email-already-exists"
                        , provider: "google"
                        , message: "Este usuario ya inició sesión desde Google, haz click en 'Continuar con Google' para continuar"
                    });
                    if (response.signInFromBrowserEmailSent)
                        set_form("signin-on-browser-email-sent");
                    return;
                }

                set_form("password");
            }).catch(ex => {
                set_email_error(ex.message);
                set_status("");
                console.error("UserExists error", ex);
            });
    }, [status]);

    React.useEffect(() => {
        if (!(form === "password" && status === "SUBMIT")) return;
        if (!moreThan8Char(password)) {
            set_password_error("La contraseña debe tener por lo menos 8 caracteres");
            set_status("");
            return;
        }
        signInWithEmailAndPassword(AuthInstance, email, password)
            .then(props.onLoggedIn)
            .catch((ex: Error & { code: string }) => {
                console.info(ex);
                var message = ex.message;
                if (ex.code === "auth/wrong-password")
                    message = "La contraseña es incorrecta.";

                set_password_error(message);
                set_status("");
                console.error("UserPassword login failed", ex);
            });
    }, [status]);

    React.useEffect(() => {
        if (!(form === "sign-up" && status === "SUBMIT")) return;
        const phone_number = phone.toString().replace(/ /g, "");

        var rollback = false;
        const phone_is_valid = ValidatePhoneE164Format(phone_number);

        if (!IsEmail(email)) {
            set_email_error("Digite un correo válido");
            rollback = true;
        }

        if (!phone_is_valid) {
            set_phone_error("Digite un número de teléfono válido");
            rollback = true;
        }
        if (IsEmpty(name)) {
            set_name_error("Digite el nombre");
            rollback = true;
        }
        if (!moreThan8Char(password)) {
            set_password_error("La contraseña debe contener más de 8 digitos y tener al menos una letra mayúscula");
            rollback = true;
        }
        if (rollback) {
            set_status("");
            return;
        }

        if (phone_is_valid)
            API.createUserWIthEmailAndPassword({ email, password, phone: phone_number, name })
                .then(() => signInWithEmailAndPassword(AuthInstance, email, password))
                .then(props.onLoggedIn)
                .catch(ex => {
                    console.error("createUserWIthEmailAndPassword failed", ex);
                    if (ex.message = "FirebaseError") ex.message = FirebaseErrorMessage(ex);
                    if (!ex.message && ex.error) ex.message = ex.error?.message;
                    if (ex.message === "INVALID_PASSWORD") {
                        set_email_error("La contraseña no es correcta");
                    } else {
                        set_error(ex);
                    }
                    set_status("");
                });
    }, [status]);

    React.useEffect(() => {
        if (!(form === "password-recovery" && status === "SUBMIT")) return;
        sendPasswordResetEmail(AuthInstance, email)
            .then(() => {
                set_status("PASSWORD-RECOVERY-COMPLETED");
            }).catch((ex: Error & { code: string, error?: any }) => {
                if (ex.message = "FirebaseError") ex.message = FirebaseErrorMessage(ex);
                if (!ex.message && ex.error) ex.message = ex.error?.message;
                set_error(ex);
                set_status("");
                console.error("password-recovery sent email failed", ex);
            });
    }, [status]);


    const socialNetworkProcessError = (error: Error & { code?: string } | FirebaseError
        , origin: "FACEBOOK" | "GOOGLE") => {
        if (error?.name === "FirebaseError" &&
            error?.code === "auth/account-exists-with-different-credential"
            && (origin === "FACEBOOK" || origin === "GOOGLE")
        ) {
            console.info("Getting providerId and data from email with differente credentials");
            const email = FirebaseErrorCredentialFirebaseEmail(error as FirebaseError);
            if (!email) {
                set_error(error);
                set_status("");
                return;
            }
            API.userEmailExists(email)
                .then(result => {
                    error.message = "Este usuario ya inició mediante correo electrónico, escribelo y aplasta en Continar";
                    if (result.isFbUser)
                        error.message = "Este usuario ya inició sesión desde Facebook, haz click en 'Continuar con Facebook' para continuar";
                    if (result.isGoogleUser)
                        error.message = "Este usuario ya inició sesión desde Google, haz click en 'Continuar con Google' para continuar";
                    set_error(error);
                    set_status("");
                }).catch(() => {
                    set_error(error);
                    set_status("");
                });
            return;
        }
        set_error(error);
        set_status("");
    };

    if (form === "signin-on-browser-email-sent")
        return (<div>
            <div style={{ margin: "auto", textAlign: "center" }} >
                <img src="https://analytics.alltherooms.com/images/mail-flying.svg" />
            </div>
            <div style={{ marginTop: 20 }} >
                <TitleInfoDialog>
                    Revisa tu E-mail!
                </TitleInfoDialog>
            </div>
            <div style={{ marginBottom: 40 }} >
                <Typography color="secondary">
                    Un link ha sido enviado a<br />
                    <strong>{email}</strong><br /><br />
                    Encuentra este link en un correo enviado por asistencia@simplemarcas.cl y haz clic en el para iniciar {error?.provider ? `con tu cuenta de ${error.provider}` : ""} (si no lo encuentras asegúrate de revisar también en correo no deseado)
                </Typography>
            </div>
        </div>);

    const isLoading = ["FACEBOOK", "GOOGLE", "SUBMIT"].indexOf(status) >= 0;
    // si comienza en alguna acción. No puede regresar.
    // Si comienza en usuario, 
    const canGoBack = (!props.beginOn) && ["sign-up", "password"].indexOf(form) >= 0;

    return (<form noValidate={"novalidate" as any} onSubmit={event => {
        event.preventDefault();
        set_status("SUBMIT");
    }} style={{ paddingBottom: 30, marginTop: -16 }}>

        {canGoBack
            ? <Typography
                style={{ marginBottom: 10 }}
                onClick={() => isLoading ? null : set_form("email")}
                variant="caption"
                color="primary"
                component="a"
            >
                <BackIcon /> Atrás
            </Typography>
            : null
        }
        <TitleInfoDialog>
            {status === "PASSWORD-RECOVERY-COMPLETED" ? "Correo enviado" : "Iniciar sesión o registrarse"}
        </TitleInfoDialog>

        {
            form === "sign-up"
                ? <SignInForm
                    name={name}
                    phone={phone}
                    email={email}
                    disableInputs={{
                        name: !!props.fixedValues?.name,
                        phone: !!props.fixedValues?.phone,
                        email: !!props.fixedValues?.email,
                    }}
                    password={password}
                    disabled={isLoading}

                    onNameChange={set_name}
                    onPhoneChange={set_phone}
                    onEmailChange={set_email}
                    onPasswordChange={set_password}

                    name_error={name_error}
                    phone_error={phone_error}
                    email_error={email_error}
                    password_error={password_error}
                />
                : null
        }

        {
            form === "email"
                ? (<>
                    <div style={{ marginBottom: 25 }} >
                        <Typography>Inicia sesión o crea tu cuenta gratis</Typography>
                    </div>
                    <TextField
                        fullWidth
                        value={email}
                        onChange={event => set_email(event.target.value)}
                        label="Correo electrónico"
                        type="email"
                        variant="outlined"
                        color="secondary"
                        disabled={isLoading || !!props.fixedValues?.email}
                        error={!!email_error}
                        helperText={email_error}
                    />
                </>)
                : null
        }

        {
            form === "password"
                ? <>
                    <PasswordInput disabled={isLoading} value={password} onChange={set_password} error={password_error} />
                    <div style={{ marginTop: 10, marginBottom: 20 }}>
                        <Typography onClick={() => set_form("password-recovery")} variant="caption" component="a" color="primary">¿Olvidaste la contraseña?</Typography>
                    </div>
                </>
                : null
        }
        {(form === "password-recovery" && status !== "PASSWORD-RECOVERY-COMPLETED")
            ? <>
                <Typography>Enviaremos las instrucciones para recuperar tu contraseña a tu correo {email}.</Typography>
            </>
            : null}

        <div>
            {error && <Typography style={{ margin: 10 }} color="error" variant="body2">{error.message}</Typography>}
        </div>

        <div style={{ marginTop: 15 }}>
            {status === "PASSWORD-RECOVERY-COMPLETED"
                ? <Typography>Revisa la bandeja de entrada de tu correo electrónico. Si no lo encuentras, revisa también en la bandeja de No Deseados o SPAM.</Typography>
                : <SubmitButton
                    fullWidth
                    onSubmit={() => set_status("SUBMIT")}
                    type="submit"
                    isLoading={status === "SUBMIT"}
                    disabled={isLoading}
                >
                    Continuar
                </SubmitButton>}
        </div>

        {
            showSocialNetworkButtons
                ? (<>
                    <Line size={1} style={{ marginTop: 30 }} />
                    <div>
                        <GoogleLoginButton
                            onStart={() => set_status("GOOGLE")}
                            onCompleted={() => props.onLoggedIn()}
                            onError={socialNetworkProcessError}
                            isLoading={status === "GOOGLE"}
                            disabled={isLoading}
                        />
                        <FacebookLoginButton
                            onStart={() => set_status("FACEBOOK")}
                            onCompleted={() => props.onLoggedIn()}
                            onError={socialNetworkProcessError}
                            isLoading={status === "FACEBOOK"}
                            disabled={isLoading}
                        />
                    </div>
                </>)
                : null
        }
    </form >);
}


function moreThan8Char(password: string) {
    if (IsEmpty(password)) return false;
    if (password.toString().length < 8) return false;
    return true;
}