import { Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { BORDER_COLOR, HOVER_COLOR } from "./colors";


export default withStyles((theme: Theme) => ({
    "@global": {

        "button": {
            margin: 0
        }

        , ".MuiButtonBase-root": {
            borderColor: BORDER_COLOR
        }

        , ".MuiButton-root": {
            textTransform: "unset",
            fontWeight: "unset"
        }

        , ".MuiButton-root.small": {
            [theme.breakpoints.down("xs")]: {
                fontSize: 17,
                paddingLeft: 20,
                paddingRight: 20
            }
        }
        , ".MuiButton-root.MuiButton-containedPrimary": {
            color: theme.palette.common.white,
            border: "0px solid transparent",
            //background: "linear-gradient( 90deg,  #0099ff 0%, #007ded 100%)",
            //background: "#0087ff",
            boxShadow: "none",
            paddingLeft: 38,
            paddingRight: 38,
            borderColor: "transparent",
            borderRadius: 10,
            fontSize: 16,
            minHeight: 54,
            fontFamily: "Poppins",
            fontWeight: 400
        }
        , ".MuiButton-root.MuiButton-outlinedPrimary": {
            borderRadius: 10,
            fontSize: 16,
            minHeight: 54,
            fontFamily: "Poppins",
            fontWeight: 400
        }

        , ".MuiButton-text.MuiButton-textSecondary, .MuiButton-text.MuiButton-textPrimary": {
            fontFamily: "Helvetica Now Text Medium",
            "&:hover": {
                backgroundColor: HOVER_COLOR,
                textDecoration: "underline"
            }
        }
        /*
        ".MuiIconButton-root": {
            backgroundColor: "transparent !important"
        },
        ".MuiIconButton-colorPrimary:hover": {
            color: theme.palette.primary.main
        },
        ".MuiIconButton-colorPrimary.Mui-focusVisible": {
            color: theme.palette.primary.main
        }
        */
        , ".MuiButton-outlined.MuiButton-outlinedSecondary, .MuiButton-contained.MuiButton-containedSecondary": {
            minWidth: 150
            , fontFamily: "Poppins"
            , fontWeight: 500
            , borderRadius: 10
            , boxShadow: "unset"
            , [theme.breakpoints.down("xs")]: {
                minWidth: 120
                , fontSize: 14
            }
        }
        , ".MuiButton-outlined.MuiButton-outlinedPrimary": {
            color : theme.palette.primary.main
            , borderColor : theme.palette.primary.main
        }
        , ".MuiButton-contained.MuiButton-containedSecondary": {
            border: "2px solid black"
        }
        , ".hover-underline": {
            "&:hover": {
                textDecoration: "underline"
            }
        }

        , ".change-to-primary-on-hover": {
            textDecoration: "none !important",
            "&:hover, &:active, &:focus": {
                color: theme.palette.primary.main
            }
        }
    }
}))(() => null);