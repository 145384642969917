import ReactDOM from 'react-dom';
import "./Theme/index.css";
import "./lib/ExtendNumber";
import reportWebVitals from './reportWebVitals';
import App from './App';
import HookToExternalLandingPage, { get_root_ID } from "./HookToExternalLandingPage";
import './lib/ListenOnLocalStorage';



if (process.env.PROGRAM_TYPE === "landing_hook") {
  ReactDOM.render(
    <HookToExternalLandingPage />,
    document.getElementById(get_root_ID())
  )
} else {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
