import { ListItem, ListItemText, ListItemAvatar, Typography } from "@material-ui/core";
import DateToText from "../../lib/DateToText";
import Line from "../Line";
import { productSummaryStyles } from "../ProductSummaryList";
export default function CuotasList(props: {
    list: Payment[]
}) {
    const list = [...props.list].sort((a, b) => (a.number as any) - (b.number as any));
    const first = list.shift()!;
    const classes = productSummaryStyles();
    return (<>
        <ListItem className={classes.ListItem} style={{ paddingTop: 11, paddingBottom: 11 }} >
            <Line size={1} style={{ width: "100%" }} />
        </ListItem>
        <div style={{ marginTop: 4, marginBottom: 3 }}>
            <ListItem className={classes.ListItem}>
                <ListItemText
                    primaryTypographyProps={{
                        variant: "body2"
                        , className: "c-303030"
                        , component: "small"
                    }}
                    secondaryTypographyProps={{
                        variant: "caption"
                        , component: "p"
                    }}
                    primary="Monto a pagar ahora" />

                <ListItemAvatar className={classes.leftTitle}>
                    <Typography variant="body2" component={"small"} className="c-303030" >
                        {first.amount.toCurrency()}
                    </Typography>
                </ListItemAvatar>
            </ListItem>
            {list.map((cuota, index) => (<ListItem key={index} className={classes.ListItem}>
                <ListItemText
                    primaryTypographyProps={{
                        variant: "caption"
                        , className: "c-303030"
                        , component: "p"
                    }}
                    secondaryTypographyProps={{
                        variant: "caption"
                        , component: "p"
                    }}
                    primary={`Vence el ${DateToText(cuota.date)}`} />

                <ListItemAvatar className={classes.leftTitle}>
                    <Typography variant={"caption"} component={"p"} className="c-303030" >
                        {cuota.amount.toCurrency()}
                    </Typography>
                </ListItemAvatar>
            </ListItem>))}
        </div>
    </>)
}

