import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    embedContainer: {
        position: "relative",
        paddingBottom: "56.25%",
        height: 0,
        overflow: "hidden",
        maxWidth: "100%",
        "& iframe, object, embebed": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
        }
    }
})


export default function () {
    const classes = useStyles();
    return (<div style={{ width: "100%" }}>
        <div className={classes.embedContainer}>
            <iframe src="https://player.vimeo.com/video/719459684?h=77a00aa0e9"
                width="640"
                height="360"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen></iframe>
        </div>
    </div>);
}