import { usePromise } from "@timm/use-promise";
import React from "react";
import { useAppContext } from "../Context/AppContext";


type Status = "LOADING" | "ERROR" | "COMPLETED";
export default function (openPaymentId?: string) {

    const [status, set_status] = React.useState<Status>("LOADING");
    const { API, initialized } = useAppContext();

    const { loading, data, error } = usePromise<OpenPaymentWithLoginData>(
        () => (initialized && openPaymentId)
            ? API.getOpenPayment(openPaymentId).catch(x => {
                var error = new Error(x.message);
                (error as any).details = x;
                throw error;
            })
            : new Promise(function () { })
        , [initialized, openPaymentId]);

    React.useEffect(() => {
        if (loading) return;
        if (error)
            return set_status("ERROR");

        set_status("COMPLETED");

    }, [loading]);

    return {
        status: initialized ? status : "LOADING",
        data,
        error: (error as any)?.details
    };
}