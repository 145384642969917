import { Typography } from "@material-ui/core";
import NotificationDiv from "./Controls/NotificationDiv";

export default function (props: { message?: string | null }) {
    const { message } = props;
    if (!(message && message.length)) return null;

    return (<NotificationDiv
        style={{
            textAlign: "left",
            borderWidth: 2,
            borderColor: "#bb0000",
            padding: 25,
            borderStyle: "solid",
            backgroundColor: "#fffbfb"
        }}
        iconHeight={33}
        iconSrc={process.env.PUBLIC_URL + "/svg/danger.svg"}
    >
        <Typography variant="body2" component="small" color="secondary">{message}</Typography>
    </NotificationDiv>);
}