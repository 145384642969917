import { Typography } from "@material-ui/core";
import React from "react";
import SubmitButton from "../../Layouts/PurchaseProcess/Submit-Button";
import DateToText, { DateToTextArg } from "../../lib/DateToText";
import PaymentOptions from "../PaymentOptions";
import TitleStatusView from "./TitleStatusView";

export default function (props: {
    date: DateToTextArg,
    amount: number,
    error?: Error | null,
    defaultPaymentMethod?: string,
    onSubmit(payment_method: string): void,
    isLoading: boolean,

    title: string,
    subtitle?: string
}) {

    const [payment_method, set_payment_method] = React.useState(props.defaultPaymentMethod || "");
    const submitButtonText = "Pagar";
    return (<div>
        <TitleStatusView>{props.title}</TitleStatusView>
        {props.subtitle
            ? (<>
                <Typography variant="body1">{props.subtitle}</Typography>
                <br />
            </>)
            : null}
        <Typography variant="body1">Fecha de Cobro: {DateToText(props.date)}</Typography>
        <br />

        <Typography>Monto: {props.amount.toCurrency()}</Typography>
        <br />
        <br />

        <div style={{ marginBottom: 20 }}>
            <PaymentOptions submitButtonText={submitButtonText} value={payment_method} onValueChange={set_payment_method} />
        </div>

        {props.error ? <Typography variant="body1" color="error">{props.error.message} {props.error.name ? `| ${props.error.name}` : ""}</Typography> : null}
        <SubmitButton onSubmit={() => props.onSubmit(payment_method)} isLoading={props.isLoading}>{submitButtonText}</SubmitButton>
    </div>);
}