
export default function (props: {
    name: string
    , height?: number
    , style?: React.CSSProperties
    , title?: string
    , className?: string
    , width?: number
}) {

    const { name } = props;

    const useGenericName = ["AmericanExpress", "Visa", "Mastercard", "RedCompra", "Prepago"].indexOf(name) < 0;

    return (<img
        className={props.className}
        style={props.style}
        height={props.height}
        width={props.width}
        title={props.title}
        src={`${process.env.PUBLIC_URL}/svg/${useGenericName ? "credit-card" : name}.svg`}
    />)
}