import React from "react";
import { Typography } from "@material-ui/core";
import withFetchLead from "../HOC/withFetchLead";
import { useAppContext } from "../Context/AppContext";
import LandingHeader from "../../Layouts/LandingPage/LandingHeader";
import UTMViewStatus from "./UTMViewStatus";
import InstallmentStatusView from "./InstallmentStatusView";
import { useLocation } from "react-router-dom";

export default withFetchLead(function (props: {
    lead: {
        id: string
        , marca: string
        , clases: ClaseMap
        , resumen: Lead["resumen"]
        , payments: Lead["payments"]
    }
    , onLoaded(path: string, paymentStatus?: Payment["status"], paymentNumber?: Payment["number"]): void
}) {
    const [status, set_status] = React.useState("");
    const [error, set_error] = React.useState<Error | null>(null);
    const [payment_method, set_payment_method] = React.useState<string | undefined>(undefined);
    const [redirectData, set_redirectData] = React.useState<{ url: string, token: string } | null>(null);


    const { API } = useAppContext();
    // Siempre va a regresar payments[0] porque va a enviar el unico pago disponible por el filtro
    const payment: Payment | undefined = props.lead.payments[0];
    const isLoading = status === "SUBMIT";

    const location = useLocation();
    React.useEffect(() => {
        props.onLoaded(location.pathname, payment?.status, payment?.number);
    }, []);

    React.useEffect(() => {
        if (status !== "SUBMIT") return;
        if (!payment_method) return;
        if (!payment) return;

        set_error(null);

        API.Pay({
            payment_method,
            payment_number: payment.number.toString(),
            id: props.lead.id
        }).then(results => {
            if (results.redirectUrl && results.redirectToken)
                return set_redirectData({
                    url: results.redirectUrl,
                    token: results.redirectToken
                });
            window.location.reload();
        }).catch(ex => {
            set_error(ex as any);
            set_status("ERROR");
        });

    }, [status]);
    if (!payment) return (<div style={{ width: "100%", textAlign: "center", margin: "auto" }} >
        <LandingHeader show_login={false} />
        <div style={{ marginTop: "20%" }} >
            <Typography variant="h3">No encontramos el pago que estás buscando.</Typography>
        </div>
    </div>);

    const onSubmit = (payment_method: string) => {
        if (isLoading) return;
        set_status("SUBMIT");
        set_payment_method(payment_method);
    }

    const isUTM = payment.number.toString().indexOf("UTM") === 0;
    if (isUTM) return (<UTMViewStatus {...payment}
        redirectData={redirectData}
        onSubmit={onSubmit}
        defaultPaymentMethod={payment_method}
        error={error}
        isLoading={isLoading}
    />);
    return (<InstallmentStatusView
        {...props.lead}
        clases_seleccionadas={Object.keys(props.lead.clases)}
        payment={payment}
        redirectData={redirectData}
        onSubmit={onSubmit}
        defaultPaymentMethod={payment_method}
        error={error}
        isLoading={isLoading} />);
});
