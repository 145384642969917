import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import React from "react";


interface KeyValue {
    name: string;
    value: string;
}

export default function <T extends string[]>(props: { value: T[number]; onChange(value: T[number]): void; disabled?: boolean; options: T; style?: React.CSSProperties }): any;
export default function <T extends KeyValue[]>(props: { value: string; onChange(value: string): void; disabled?: boolean; options: T; style?: React.CSSProperties }): any;
export default function (props: {
    value: string;
    onChange(value: string): void;
    disabled?: boolean
    options: { name: string, value: string }[] | string[];
    style?: React.CSSProperties
}) {
    const options = props.options.map(x => {
        if (typeof x === typeof "") return {
            name: x as string,
            value: x as string
        }
        return x as { name: string, value: string };
    });
    return (<RadioGroup
        style={props.style}
        row
        value={props.value}
        onChange={ev => props.onChange(ev.target.value as any)}
        aria-label="gender"
        name="invoice_type"
    >
        {options.map(o => ((
            <FormControlLabel
                disabled={props.disabled}
                key={o.value}
                color="secondary"
                value={o.value}
                control={<Radio />}
                label={o.name}
            />
        )))}
    </RadioGroup>);
}


