import { useSearchParams } from "react-router-dom";

export default function () {
    const [searchParams, set_searchParams] = useSearchParams();
    const sessionID = searchParams.get("_s") || "";
    const payment_number = searchParams.get("_pmn") || "";

    const set_sessionID = function (sessionID: string) {
        const new_params = new URLSearchParams(searchParams.toString());
        new_params.set("_s", sessionID);
        set_searchParams(new_params);
    }


    return {
        sessionID,
        set_sessionID,
        payment_number
    }
}
