import React from "react";
import { useLocation } from "react-router-dom";

export default function <T extends object>(Component: React.ComponentType<T>) {

    return (props: T & { onLoaded(path: string): void }) => {
        const location = useLocation();
        const { onLoaded, ...remainProps } = props;
        React.useEffect(() => {
            onLoaded(location.pathname);
        }, []);

        return (<Component {...remainProps as T} />)
    }
}