import React from "react";
import { Typography, Button } from "@material-ui/core";
import ClaseCard from "./ClaseCard";
import OrderLayout from "../OrderLayout";
import useBreakpoints from "../../Components/Hooks/useBreakpoints";
import FixedFooter from "./FixedFooter";
import useIsInScreen from "../../Components/Hooks/useIsInScreen";
import OrderSummaryHeader from "./OrderSummaryHeader";
import ClasesMasInfo from "./ClasesMasInfo";
import LoadingScreen from "../../Components/LoadingScreen";
import useOrderSummaryState from "./useOrderSummaryState";
import ErrorNotification from "../../Components/ErrorNotification";
import CountDown from "../../Components/CountDown";
import EditarBusquedaLink from "./EditarBusquedaLink";
import FixedContent from "./Fixed-Content";
import useOrderSummaryStyles from "./Fixed-Content/index.styles";
import LiabilityInfo from "./LiabilityInfo";
import AnonymousEmailSignUpDialog from "../../Components/Login/AnonymousEmailSignUpDialog";
import Video from "src/Components/Video";
import LinearProgressCSS from "src/Components/LinearProgressCSS";



export default function OrderSummary() {

    const {
        marca, set_status, error_message, isLoading, isInitializing
        , selected_clases, toggle_clase, detalle_clases
        , resumen, payments
        , can_go_back, goToEditMode, riskAnalysis
        , request_identify
    } = useOrderSummaryState();


    const submitButtonProps = {
        label: "Solicitar Registro"
        , onClick() {
            set_status("submit");
        },
        disabled: !selected_clases.size
        , isLoading
    };


    const [submitBtnRefStartPoint, submitBtnIsInScreen] = useIsInScreen();
    const scrollToRef = React.useRef() as any;

    const RIGHT_SUBMIT_BUTTON = submitButtonProps
        ? <>
            <div ref={submitBtnRefStartPoint} style={{ height: 1, marginTop: -12 }} ></div>
            <div style={{ marginTop: 27, marginBottom: 8 }} >
                <Button
                    type="button"
                    disabled={submitButtonProps.disabled || submitButtonProps.isLoading}
                    onClick={submitButtonProps.onClick}
                    variant="contained"
                    fullWidth
                    color="primary"
                    size="large"
                >
                    {submitButtonProps.label}
                </Button>
            </div>
        </>
        : null;


    const { xsDown } = useBreakpoints();
    const marca_ref = React.useRef<HTMLInputElement>();

    const classes = useOrderSummaryStyles();

    const [initializingIsOnTime, set_initializingIsOnTime] = React.useState(true);
    const loadingDurationSecs = parseInt(process.env.REACT_APP_ORDER_SUMMARY_TIMEOUT_SECS || "20");
    React.useEffect(() => {
        const timerId = setTimeout(() => isInitializing && set_initializingIsOnTime(false), loadingDurationSecs * 1000);
        return () => clearTimeout(timerId);
    }, []);
    if (isInitializing) return (<LoadingScreen
        loadingComponent={<div>
            <Video
                height="100"
                width="auto"
                loop={true}
                src={process.env.PUBLIC_URL + "/video/ai-loading.mp4"}
                type="video/mp4"
            />
            <div className={classes.loadingText}>
                <Typography variant="body2">PreciseResult™</Typography>
                <div style={{ paddingTop: 10 }}>
                    <div style={{ textAlign: "center", margin: "auto", maxWidth: 125, marginBottom: 15 }}>
                        <LinearProgressCSS durationSecs={loadingDurationSecs} />
                    </div>
                    <Typography variant="caption" component="p">{
                        initializingIsOnTime
                            ? "Buscando marcas similares..."
                            : "Solo unos segundos más, no actualices la pantalla"
                    }</Typography>
                </div>
            </div>
        </div>}
        fullScreen
    />);

    return (<>
        <AnonymousEmailSignUpDialog
            open={request_identify}
            blurBackdrop={true}
        />
        {!xsDown ? <div ref={xsDown ? undefined : scrollToRef} style={{ height: 80 }} ></div> : null}
        <CountDown />
        <div className={classes.searchBar}>
            <EditarBusquedaLink
                refs={{
                    marca_input: marca_ref
                }}
                onEditClases={can_go_back
                    ? goToEditMode
                    : undefined}
                marca={marca}
                clases={Object.keys(detalle_clases)}
            />
        </div>
        <OrderLayout
            marca={marca}
            resumen={resumen}
            payments={payments}
            clases_seleccionadas={Array.from(selected_clases.values())}
            rightSubmitButton={RIGHT_SUBMIT_BUTTON}
            isLoading={isLoading}
            detalle_card_ref={xsDown ? scrollToRef : undefined}
            xsFooter={<FixedFooter isLoading={isLoading} marca={marca} total={resumen.total} submitButtonProps={submitButtonProps} detalleRef={scrollToRef} />}
            customHeader={xsDown ? undefined : <OrderSummaryHeader
                marca={marca}
                total={resumen.total}
                isLoading={isLoading}
                submitButtonProps={submitButtonProps}
                detalleRef={scrollToRef}
                show_detalle={!submitBtnIsInScreen}
            />}
        >
            <>
                <ErrorNotification message={error_message} />
                {riskAnalysis && <LiabilityInfo percentage={riskAnalysis.risk_probability} code={riskAnalysis.code} results={riskAnalysis.coincidences} onEditButtonClicked={() => marca_ref && marca_ref.current && marca_ref.current.focus()} />}

                <Typography variant="h4" className={classes.leftTitle} >
                    Clases seleccionadas ({selected_clases.size})
                </Typography>
                <ClasesMasInfo />
                <div style={{ marginTop: 16, marginBottom: -20 }} className="clase-card-list" >
                    {Object.entries(detalle_clases).map(([claseId, details]) => (
                        <ClaseCard
                            key={claseId}
                            id={claseId}
                            {...details}
                            marca={marca}
                            onToggle={() => toggle_clase(+claseId)}
                            selected={selected_clases.has(+claseId)}
                        />
                    ))}
                </div>
            </>
        </OrderLayout>
        <FixedContent />
    </>);
}