import { Theme, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { HOVER_COLOR } from "../../Theme/colors";
import LandingLogo from "../LandingPage/LandingLogo";
import VimeoVideo from "../LandingPage/VimeoVideo";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: HOVER_COLOR
        , padding: 50
        , [theme.breakpoints.down("xs")]: {
            padding: 0
        }
    },
    grid: {
        display: "flex"
        , flexDirection: "row"
        , alignItems: "center"
        , [theme.breakpoints.down("xs")]: {
            display: "block"
        }
    },
    cell: {
        flex: 1
        , [theme.breakpoints.down("sm")]: {
            paddingBottom: 35
        }
    },
    left_cell: {
        paddingRight: 50
        , textAlign: "left"
        , [theme.breakpoints.down("xs")]: {
            paddingRight: 0
            , paddingTop: 35
        }
        , "& > div": {
            margin: "auto",
            marginRight: 30,
            marginLeft: 0,
            maxWidth: 475
        }
    }
}));

export default function (props: {
    style?: React.CSSProperties;
    classes?: {
        root?: string
        , grid_container?: string
    }
}) {
    const classes = useStyles();
    return (<div className={[classes.root, props.classes?.root || ""].join(" ")} style={props.style} >
        <div className={[classes.grid, props.classes?.grid_container || ""].join(" ")}>
            <div className={[classes.cell, classes.left_cell].join(" ")}>
                <div>
                    <LandingLogo />
                    <div style={{ marginBottom: 10, marginTop: 10 }} >
                        <Typography variant="h3" color="secondary">¿Cómo es el Proceso de Registro?</Typography>
                    </div>
                    <Typography variant="caption" className="md">
                        Simple Marcas se creó para que los emprendedores como tú (que no son abogados especialistas en propiedad intelectual) puedan fácilmente Registrar y Proteger sus Marcas.
                        Así puedes transformar 5 meses de procesos en solo 5 minutos...
                    </Typography>
                </div>
            </div>
            <div className={classes.cell}>
                <VimeoVideo />
            </div>
        </div>
    </div>);
}