import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    video: {
        clipPath: "inset(1px 1px)"
        , margin: "auto"
        , zIndex: -1
        , "picture-in-picture": "none"
        , "& ::-webkit-media-controls-start-playback-button": {
            display: "none"
        }
    }
});

export default function Video(props: {
    src: string
    , type?: string
    , width?: string
    , className?: string
    , height?: string
    , loop?: boolean
    , onEnded?: React.ReactEventHandler<HTMLVideoElement>
    , "data-object-fit"?: string
}) {
    const classes = useStyles();
    var { src, type, ...videoProps } = props;
    if (!type && props.src && props.src.toString().endsWith(".mp4")) {
        type = "video/mp4";
    }
    const videoRef = React.useRef<HTMLVideoElement>();
    React.useEffect(() => {
        if (!videoRef.current) return;
        const video = videoRef.current;
        video.setAttribute("autoplay", "autoplay");
        if (video.readyState === 4) {
            const isVideoPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
            if (!isVideoPlaying)
                video.play();
        }
    }, [videoRef && videoRef.current]);
    return (<video
        {...videoProps}
        onEnded={props.onEnded}
        className={[classes.video, props.className || ""].join(" ")}
        muted
        autoPlay
        disablePictureInPicture
        playsInline
        onLoadedData={event => {
            const video = event.currentTarget;
            if (video.readyState === 4) {
                const isVideoPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
                if (!isVideoPlaying)
                    event.currentTarget.play();
            }
        }}
    >
        <source src={props.src} type={type} data-wf-ignore="true" />
    </video>);
}