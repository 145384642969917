import React from "react";
import { useSearchParams } from "react-router-dom";

export enum SEARCH_PARAM_KEYS {
    MARCA = "tm"
    , ITEMS = "items"
    , ID = "_s"
}

export default function useFlowState(scrollableRef?: React.RefObject<HTMLDivElement>) {

    const [searchParams, set_searchParams] = useSearchParams();

    const marca = searchParams.get(SEARCH_PARAM_KEYS.MARCA) || "";
    const [itemIds, custom_items] = searchParamToRubrosAndCustomItems(searchParams)

    React.useEffect(() => {
        if (!scrollableRef) return;
        scrollableRef.current?.scrollTo({
            top: 0,
            behavior: "instant" as any
        });
    }, [""]);

    const onMarcaChanged = function (marca: string) {
        searchParams.set(SEARCH_PARAM_KEYS.MARCA, marca || "");
        set_searchParams(new URLSearchParams(searchParams.toString()), { preventScrollReset: true });
    }

    const addItemId = function (itemId: string) {
        if (!(itemId && itemId.toString().trim().length)) return;
        itemIds.add(itemId);
        searchParams.set(SEARCH_PARAM_KEYS.ITEMS, Array.from(itemIds).join(","));
        set_searchParams(new URLSearchParams(searchParams.toString()), { preventScrollReset: true });
    }

    const removeItemId = function (itemId: string) {
        if (!(itemId && itemId.toString().trim().length)) return;
        itemIds.delete(itemId);
        searchParams.set(SEARCH_PARAM_KEYS.ITEMS, Array.from(itemIds).join(","));
        set_searchParams(new URLSearchParams(searchParams.toString()), { preventScrollReset: true });
    }

    const setItemIds = function (itemIds: Set<string>) {
        searchParams.set(SEARCH_PARAM_KEYS.ITEMS, Array.from(itemIds).join(","));
        set_searchParams(new URLSearchParams(searchParams.toString()), { preventScrollReset: true });
    }


    return {
        marca,
        itemIds,
        custom_items,

        onMarcaChanged,
        addItemId,
        removeItemId,
        setItemIds,
        has_custom_classes: custom_items.length > 0
    }
}


function searchParamToRubrosAndCustomItems(searchParams: URLSearchParams) {
    return (searchParams.get(SEARCH_PARAM_KEYS.ITEMS) || "").split(",").reduce<[Set<string>, Array<string>]>((a, b: string) => {

        if (!(b && b.toString().trim().length))
            return a;

        const itemSet = a[0];
        const custom = a[1];
        if (isNaN(parseInt(b, 16))) {
            custom.push(b)
        } else {
            itemSet.add(b)
        }
        return a;
    }, [new Set<string>(), new Array<string>()]);
}