import React from "react";
import { Typography, Collapse, makeStyles } from "@material-ui/core";
import Questions from "./Questions.json";
import CaretIcon from "../../Components/Icons/CaretIcon";
import MultilineTextToComponent from "../../Components/MultilineTextToComponent";
import { BORDER_STYLE } from "../../Theme/colors";

export default function () {

    return (<>
        {Questions.map((item, index) => (<QuestionItem
            key={index}
            q={item.title}
            a={item.text}
        />))}
    </>);
}

const useStyles = makeStyles({
    root: {
        borderTop: BORDER_STYLE
    }
});
function QuestionItem(props: {
    q: string,
    a: string
}) {
    const [show, set_show] = React.useState(false);
    const classes = useStyles();
    return (<div className={classes.root}>
        <div style={{
            display: "flex"
            , flexDirection: "row"
            , paddingTop: 18
            , paddingBottom: 18
            , cursor: "pointer"
        }} onClick={() => set_show(!show)} >
            <Typography style={{ flex: 1, flexGrow: 1, margin: "auto" }} className="sm" variant="body2">{props.q}</Typography>
            <div style={{ margin: "auto", paddingRight: 10, paddingLeft: 10 }}>
                <CaretIcon invert={show} />
            </div>
        </div>
        <Collapse in={show}>
            <div style={{ paddingBottom: 20 }} >
                <MultilineTextToComponent
                    input={props.a}
                >
                    {({ text }) => <Typography variant="caption" component="p">{text}</Typography>}
                </MultilineTextToComponent>
            </div>
        </Collapse>
    </div >);
}