import { Checkbox, List, ListItem, ListItemAvatar, ListItemSecondaryAction, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import GroupIcon from "./GroupIcon";
import FilterFunctionWithNormalization from "src/Components/ItemSearchBar/FilterFunctionWithNormalization";
import SearchItemInput from "src/Components/ItemSearchBar/SearchItemInput";
import { HighlightText } from "../HighlightText";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
    List: {
        paddingTop: 5,
        paddingBottom: 40
    },
    listItemLayout: {
        marginTop: 25,
        marginBottom: 10,
        padding: "25px 28px",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 20,
            paddingRight: 20
        }
    }
    , header_gap: {
        paddingBottom: 10
    }
    , listItemContainer: {
        borderWidth: 1,
        borderStyle: "none",
        borderRadius: 10,
        paddingTop: 30,
        paddingBottom: 30,
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.07)",
        "&:hover": {
            backgroundColor: "initial !important",
            boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.1)",
        }
    },
    listItemTextContainer: {
        paddingRight: 20
    },
    lessPadding: {
        paddingLeft: 20,
        paddingRight: 15
    }
    , title: {
        fontSize: 15
    },
    "@global": {
        ".Mui-selected": {
            borderWidth: 2,
            borderColor: theme.palette.primary.main + " !important",
            backgroundColor: "initial !important",
            borderStyle: "solid",
        }
    }
    , checkboxContainer: {
        position: "absolute", top: 20, right: 0
    }
    , full_flex: {
        flex: "1"
    }
}))

export default function ListItemInput<P extends {
    primaryText: string,
    secondaryText?: string,
    id: string,
    selected?: boolean,
    icon?: React.ReactElement,
    iconSrc?: string
}>(props: {
    items: P[];
    loadingState?: {
        isLoading: boolean,
        skeletonItems: number
    }
    itemSelectedChecker?: (item: P) => boolean;
    onClick(result: P, selected: boolean): void;
    children?: React.ReactNode;
    searchBar?: boolean;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    iconDimension?: "large" | "normal";
    classes?: {
        List?: string
    }
    innerItemChildren?: (item: P) => React.ReactNode
}) {

    const classes = useStyles();
    const [search, set_search] = React.useState("");

    // TODO: cambiar esto a `claims`
    // const { user } = useAppContext();
    const filterCreated = FilterFunctionWithNormalization(search, "primaryText");
    var filteredItems = [];
    if (props.loadingState?.isLoading) {
        filteredItems = new Array(props.loadingState.skeletonItems || 1).fill({
            primaryText: <Skeleton variant="text" className={classes.title} style={{ flex: 1, flexGrow: 1 }} />
            , icon: <Skeleton variant="circle" width={43} height={43} />
            , isSkeleton: true
        })
    } else {
        filteredItems = props.items.reduce((sortedList, x) => {
            const item = {
                ...x
                , score: filterCreated(x)
            };
            if (item.score >= 0.4) sortedList.push(item);
            return sortedList;
        }, [] as Array<P & { score: number }>)
            .sort((a, b) => b.score - a.score);
    }

    return (<List className={[classes.List, props.classes?.List || ""].join(" ")}>

        {props.header && <div className={[classes.listItemLayout, classes.header_gap].join(" ")}> {props.header}</div>}

        {props.searchBar ? (<SearchItemInput
            onTextChange={set_search}
            value={search}
        />) : null}
        {filteredItems.map((item, index) => {
            const selected: boolean = props.itemSelectedChecker ? props.itemSelectedChecker(item) : (item.selected || false);

            return (<ListItem
                className={[classes.listItemLayout, classes.listItemContainer, item.iconSrc ? classes.lessPadding : ""].join(" ")}
                key={"isSkeleton" in item ? ("skeleton_idx_" + index) : item.id}
                button
                onClick={() => props.onClick(item, selected!!)}
                selected={selected}
                color="primary"
            >
                <ListItemSecondaryAction className={classes.checkboxContainer} >
                    <Checkbox color="primary" checked={selected} disabled={"isSkeleton" in item} />
                </ListItemSecondaryAction>
                {item.icon && <ListItemAvatar>{item.icon}</ListItemAvatar>}
                {item.iconSrc && <ListItemAvatar>
                    <GroupIcon dimension={props.iconDimension || "normal"} size={43} logoUrl={item.iconSrc} />
                </ListItemAvatar>}
                <div className={["MuiListItemText-root"
                    , "MuiListItemText-multiline"
                    , classes.listItemTextContainer
                    , "isSkeleton" in item ? classes.full_flex : ""
                ].join(" ")}>
                    {typeof item.primaryText === "string"
                        ? (<Typography variant="body2" className={classes.title} dangerouslySetInnerHTML={{
                            __html: HighlightText(item.primaryText, search)
                        }} />)
                        : item.primaryText}
                    <Typography variant="caption">{item.secondaryText}</Typography>
                    {props.innerItemChildren && props.innerItemChildren(item)}
                </div>
            </ListItem>)
        })}

        {props.footer && <div className={classes.listItemLayout}> {props.footer}</div>}

        {props.children}

    </List>)
}