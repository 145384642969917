import React from "react";
import { Typography } from "@material-ui/core";
import InfoDialog from "../../Components/InfoDialog";
export default function () {
    const [open, set_open] = React.useState(false);
    return (
        <div style={{ marginTop: 3 }}>
            <Typography onClick={() => set_open(true)} variant="caption" component="a" color="primary" >
                Más información
            </Typography>
            <InfoDialog isOpen={open} onClose={() => set_open(false)} title="Clases" >
                <div style={{ marginBottom: 60 }}>
                    <Typography variant="caption" component="p" color="secondary">
                        Las marcas se protegen en base al rubro al cual se dedican, es por esto que cada clase es virtualmente como una marca diferente.
                        Cada clase deberá recorrer su propio proceso de registro y deberá también pagar sus propias tasas al Gobierno de Chile.
                    </Typography>
                    <br />
                    <Typography variant="caption" component="p" color="secondary">
                        No es un requisito registrar la marca con todas sus clases a la vez, si no que se pueden ir registrando nuevas clases en el tiempo sin problemas.
                    </Typography>
                </div>
            </InfoDialog>
        </div>
    )
}