import { CircularProgress, Typography } from "@material-ui/core";
export default function LoadingScreen(props: {
    text?: boolean;
    fullScreen?: boolean;
    loadingComponent?: React.ReactNode
}) {

    const fullScreen = (Object.prototype.hasOwnProperty.call(props, "fullScreen") && props.fullScreen !== false);
    const text = props.text === undefined ? true : props.text;
    return (<div style={{ position: fullScreen ? "absolute" : "relative", display: "table", height: "100%", width: "100%", textAlign: "center", margin: "auto" }} >
        <div style={{ display: "table-cell", verticalAlign: "middle", margin: "auto" }} >
            {props.loadingComponent || (<>
                <CircularProgress size={60} color="primary" />
                {text
                    ? <Typography variant="h3" color="secondary">Cargando...</Typography>
                    : null}
            </>)}
        </div>
    </div>);
};