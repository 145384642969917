import { ThemeProvider } from "@material-ui/core/styles";
import AppTheme from './Theme/App.Theme';
import { Routes, Route, BrowserRouter as Router, Navigate } from "react-router-dom";
import ResumePage from "./Layouts/OrderSummary";
import "./App.css";
import PurchaseCompletedPage from "./Layouts/PurchaseCompleted";
import AppContext from "./Components/Context/AppContext";
import CustomStyles from "./Theme/CustomStyles";
import SubmitRequestPage from "./Layouts/PurchaseProcess/SubmitRequest";
import PaymentStatusPage from "./Components/PaymentStatus";
import TitleFromPath from "./lib/TitleFromPath";
import OpenPaymentPage from "./Components/OpenPaymentPage";
import StartFlowPage from "./Layouts/Item-Selection-Flow/StartFlow";
import WithLogin from "./Components/HOC/withLogin";
import WitnOnLoad from "./Components/HOC/withOnLoad";
import ErrorScreen from "./Components/ErrorScreen";
import AnalyticsEvents from "./lib/AnalyticsEvents";
import WithAnonymousEmailSignUp from "./Components/HOC/WithAnonymousEmailSignUp";
import PopupBasedFlowPage from "./Layouts/Flow.v2";


const SearchStart = WitnOnLoad(WithLogin(StartFlowPage));
const PopupBasedFlow = WitnOnLoad(PopupBasedFlowPage);
const Resume = WitnOnLoad(ResumePage);
const SubmitRequest = WitnOnLoad(WithAnonymousEmailSignUp(SubmitRequestPage));
const PurchaseCompleted = WitnOnLoad(WithAnonymousEmailSignUp(PurchaseCompletedPage));
const PaymentStatus = WithLogin(PaymentStatusPage);
const OpenPayment = WitnOnLoad(WithAnonymousEmailSignUp(OpenPaymentPage));

export default function App() {

  const pageLoad = function (path: string,
    paymentStatus?: Payment["status"],
    paymentNumber?: Payment["number"]
  ) {
    TitleFromPath(path, paymentStatus, paymentNumber);
    const w = window as any;
    AnalyticsEvents.setPage();
    w.initialize_google_tag_manager && w.initialize_google_tag_manager();
  }

  return (<ThemeProvider theme={AppTheme}>
    <CustomStyles />
    <AppContext>
      <Router>
        <Routes>
          <Route index element={<Navigate to={"/flow/"} />} />
          <Route path="/start-flow" element={<SearchStart onLoaded={pageLoad} />} />
          <Route path="/flow/*" element={<PopupBasedFlow onLoaded={pageLoad} />} />
          <Route path="/resume" element={<Resume onLoaded={pageLoad} />} />
          <Route path="/submit" element={<SubmitRequest onLoaded={pageLoad}
            validateFetchedData={(lead, navRoutes) => {
              if (lead.replacedBy) {
                navRoutes.navigateToSubmit(lead.replacedBy);
                return true;
              }
              if (!lead.editable && !lead.rejected_duplicable) {
                navRoutes.navigateToCompleted(lead.id);
                return true;
              }
              return false;
            }} />}

          />
          <Route path="/submitted" element={<PurchaseCompleted
            onLoaded={pageLoad}
          />}
          />
          <Route path="/payment-status" element={<PaymentStatus
            onLoaded={pageLoad}
          />}
          />
          <Route path="/open-payment/:id" element={<OpenPayment
            onLoaded={pageLoad}
          />}
          />
          <Route path="*" element={<ErrorScreen message="La pagina que buscas no existe." statusCode={404} name="Pagina no existe" />}
          />
        </Routes>
      </Router>
    </AppContext>
  </ThemeProvider>);
}