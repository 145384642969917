import { withStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { BORDER_COLOR } from "./colors";


export default withStyles((theme: Theme) => ({
    "@global": {

        ".MuiInputBase-root": {
            fontSize: theme.typography.body1.fontSize
        },
        ".MuiFormHelperText-root.MuiFormHelperText-contained": {
            marginLeft: 0
            , marginRight: 0
            , fontFamily: "Helvetica Now Text Light"
            , fontSize: 11
        },

        ".MuiFilledInput-root.MuiFilledInput-colorSecondary": {
            border: "0px solid transparent",
            background: theme.palette.common.white,
            borderRadius: "5px",
            "&:hover": {
                background: BORDER_COLOR
            }
        },
        ".MuiFilledInput-root.MuiFilledInput-colorSecondary.Mui-focused": {
            background: "white !important",
            boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 10px 0px rgb(0 0 0 / 14%), 0px 2px 30px 5px rgb(0 0 0 / 12%)",
        },

        ".MuiFilledInput-root.MuiFilledInput-colorSecondary > .MuiInputBase-input.MuiFilledInput-input": {
            paddingTop: 42,
            paddingBottom: 18,
            paddingLeft: 20,
            fontSize: 16
        },
        ".MuiFilledInput-colorSecondary > .MuiInputBase-input.MuiFilledInput-input::placeholder": {
            color: "#B7B7B7 !important",
            fontFamily: "Helvetica Now Text Light",
            fontSize: 16,
            opacity: 1
        },
        '.MuiFilledInput-colorSecondary > .MuiInputBase-input.MuiFilledInput-input[type="button"].empty': {
            color: "#B7B7B7 !important",
            fontSize: 16,
            opacity: 1,
            fontWeight: 200,
        },
        ".MuiFormLabel-root.MuiFormLabel-colorSecondary.MuiInputLabel-formControl.MuiInputLabel-filled": {
            color: "#252525",
            "&.MuiInputLabel-shrink": {
                transform: "translate(22px, 23px) scale(0.75)",
                fontSize: 19
            }
        }

        , ".MuiFormLabel-root": {
            color: theme.typography.caption.color
        }

        , ".MuiInputBase-input::placeholder": {
            fontFamily: "Helvetica Now Text Light"
        },


        ".MuiCheckbox-root": {
            color: "#d9dbdc",
            "&:hover": {
                backgroundColor: "initial !important",
                color: theme.palette.primary.main
            }
        }

        , ".MuiOutlinedInput-colorSecondary.Mui-error": {
            backgroundColor: theme.palette.error.light,
            "&:focus": {
                bordercolor: "inherit"
            }
        }
    }
}))(() => null);