import React from "react";
import { Typography, Portal, Hidden, Button } from "@material-ui/core";
import FlexBox from "../../../Components/Controls/FlexBox";
import SubmitButton from "../Submit-Button";
import RedirectionScreen from "../RedirectionScreen";
import useBreakpoints from "../../../Components/Hooks/useBreakpoints";
import OrderLayout, { ORDER_LAYOUT_FOOTER_CONTAINER_ID } from "../../OrderLayout";
import useStyles from "./Styles";
import usePayPurchaseState from "./State";
// import PaymentOptions from "../../../Components/PaymentOptions";
import withFetchLead from "../../../Components/HOC/withFetchLead";
import TieneLogoInput from "./TieneLogoInput";
import ErrorNotification from "../../../Components/ErrorNotification";
import SubmitTermsAndConds from "./SubmitTermsAndConds";
import PromoCodeInput from "./PromoCodeInput";
import RadioInput from "../../../Components/RadioInput";
import EditMarcaDialog from "./EditMarcaDialog";
import CopyRightFooter from "../../LandingPage/CopyRightFooter";
import CountDown from "../../../Components/CountDown";
import PaymentPlatformList from "./PaymentPlatformList";
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";


export default withFetchLead(function SubmitRequest(props: { lead: Lead }) {
    const {
        paymentRedirectData,
        invoice_type,
        set_invoice_type,

        logo,
        updateLogo,
        force_change_marca,
        replace_marca,
        marca,

        promo_code,
        onPromoCodeChange,
        resumen,
        payments,

        payment_platform,
        set_payment_platform,

        status,
        set_status,
        error_message,

        contacto,
        updateContacto
    } = usePayPurchaseState(props.lead);

    const isLoading = ["SUBMIT", "QUOTE"].indexOf(status) >= 0;

    const classes = useStyles();
    const { xsDown } = useBreakpoints();
    const submitButtonText = "Confirmar y solicitar registro";

    const [termsAccepted, set_termsAccepted] = React.useState(false);
    const [editContact, set_editContact] = React.useState(true);

    return (<>
        <ContactForm
            onComplete={contact => { updateContacto(contact); set_editContact(false); }}
            open={editContact}
            onClose={() => set_editContact(false)}
            default={contacto}
        />
        <CountDown />
        <OrderLayout
            clases_seleccionadas={props.lead.clases_seleccionadas!}
            marca={marca}
            resumen={resumen}
            isLoading={isLoading}
            payments={payments}
        >
            <RedirectionScreen isOpen={status === "REDIRECT-TO-TRANSBANK" && !!paymentRedirectData?.url} {... (paymentRedirectData || { token: "", url: "" })} />
            <div style={{ marginBottom: 40 }}>
                <Typography variant="body1" component={Link} style={{ textDecoration: "none", color: "inherit" }} to={{
                    pathname: "/resume"
                    , search: window.location.search
                }}>Atrás</Typography>
                <div>
                    <Typography variant="h3">Confirmar y solicitar registro</Typography>
                </div>
            </div>
            <EditMarcaDialog onCompleted={replace_marca} open={force_change_marca} previousValue={props.lead.marca} reason={props.lead.rejection_reason} />

            <div>
                <ErrorNotification message={error_message} />
                <FlexBox className={classes.section}
                    style={{ alignItems: "start", justifyContent: "unset", border: "0px solid transparent" }}
                >
                    <div style={{ flex: 1, flexGrow: 1 }}>
                        <Typography variant="h6" style={{ marginBottom: 10 }}>Contacto</Typography>
                        <Typography variant="caption" component="p">Email: {contacto?.email}</Typography>
                        <Typography variant="caption" component="p">Nombre: {contacto?.nombre}</Typography>
                        <Typography variant="caption" component="p">Telefono: {contacto?.telefono}</Typography>
                    </div>
                    <Button
                        variant="text"
                        color="secondary"
                        type="button"
                        onClick={() => set_editContact(true)}
                        style={{
                            textDecoration: "underline", fontFamily: "Helvetica Now Text Medium"
                        }}
                    >
                        Edita
                    </Button>
                </FlexBox>

                <FlexBox className={classes.section}>
                    <TieneLogoInput disabled={isLoading} value={logo} onChange={updateLogo} />
                </FlexBox>

                <FlexBox className={[classes.section].join(" ")}>
                    <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>Tipo de documento</Typography>
                    <RadioInput options={[{ value: "boleta", name: "Boleta" }, { value: "factura", name: "Factura" }]} onChange={set_invoice_type as any} value={invoice_type as any} />
                </FlexBox>

                <FlexBox className={[classes.section].join(" ")} >
                    <div>
                        <Typography variant="h6" style={{ marginTop: 10, marginBottom: 20 }}>¿Cómo quieres pagar?</Typography>
                        <PaymentPlatformList value={payment_platform} onChange={set_payment_platform} />
                        <PromoCodeInput
                            defaultValue={promo_code}
                            clases_seleccionadas={props.lead.clases_seleccionadas!}
                            cuotas={resumen.cuotas}
                            logo={logo}
                            id={props.lead.id}
                            onChange={onPromoCodeChange} />
                    </div>
                </FlexBox>

                <Portal container={document.getElementById(ORDER_LAYOUT_FOOTER_CONTAINER_ID)} disablePortal={!xsDown} >
                    <div className={classes.brand_footer}>
                        <div className={classes.brand_button_container} >
                            <SubmitButton disabled={!termsAccepted} isLoading={isLoading} onSubmit={() => set_status("SUBMIT")} >
                                {submitButtonText}
                            </SubmitButton>
                            <SubmitTermsAndConds checked={termsAccepted} onChange={set_termsAccepted} className={classes.acceptTermsBlock} />
                        </div>
                        <Hidden xsDown>
                            <div className={classes.brand_button_container}>
                                <CopyRightFooter />
                            </div>
                        </Hidden>
                    </div>
                </Portal>
            </div >
        </OrderLayout>
    </>);
});


export function FormatoDireccionCL(holder: { region: string, ciudad: string, direccion: string }) {
    if (holder.direccion && holder.ciudad && holder.region)
        return `${holder.direccion}, ${holder.ciudad}, ${holder.region}`;
    return null;
}