import { makeStyles, Theme } from "@material-ui/core";
import { HOVER_COLOR } from "../../Theme/colors";

const UseItemSelectionFlowStates = makeStyles((theme: Theme) => ({
    root: {
        overflow: "hidden"
        , display: "flex"
        , alignItems: "center"
        , flexDirection: "row"

        , [theme.breakpoints.up("sm")]: {
            "& > div": {
                height: "100%"
            }
        }
        , [theme.breakpoints.down("xs")]: {
            display: "block",
            overflow: "auto"
        }
    },
    contentContainer: {
        [theme.breakpoints.up("sm")]: {
            width: 515
        }
    }
    , controllersContainer: {
        [theme.breakpoints.up("sm")]: {
            width: 450,
        }
        , margin: "auto"
    }
    , title: {
        letterSpacing: "unset",
        backgroundColor: "#a6fff7",
        display: "inline-block",
        marginTop: 20
    },
    title_content: {
        [theme.breakpoints.down("xs")]: {
            flexBasis: "auto",
            marginBottom: 15
        }
    },
    inner_input_content: {
        flex: 1,
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
    }
    , leftSide_root: {
        backgroundColor: HOVER_COLOR
        , padding: 30
        , [theme.breakpoints.down("xs")]: {
            padding: 20
        }
    }
    , innerContainer: {
        display: "flex"
        , height: "100%"
        , flexDirection: "column"
        , [theme.breakpoints.up("sm")]: {
            height: "inherit"
            , "& div.header": {
                position: "absolute",
                zIndex: 10,
                top: 1,
                padding: "30px 20px"
            },
            " & > div.content": {
                marginTop: -81
                , paddingTop: 81
                , flex: 1
                , flexGrow: 1
                , height: "100%"
            }
        }
        , "& >.footer": {
            width: "100%",
            backgroundColor: "white"
        }
        , [theme.breakpoints.down("xs")]: {
            "& >.header": {
                margin: -20,
                marginBottom: 0
            }
            , "&> .footer": {
                position: "absolute",
                bottom: 0,
                zIndex: 10,
                boxShadow: "0px 0px 16px 0px rgb(0 0 0 / 7%)"
            }
        }
    }
    , footer: {
        height: 86,
        overflow: "hidden",
        minHeight: 86
    }
    , rightSide_root: {
        flex: 1,
        flexGrow: 1,
        overflow: "auto"
    }
}));

export default UseItemSelectionFlowStates;