import { IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import CloseIcon from "../../Components/Icons/CloseIcon";

const useStyles = makeStyles({
    input: {
        paddingTop: "26px !important",
        paddingBottom: "24px !important",
        textAlign: "center"
    },
    FieldControl: {
        borderRadius: "50px !important"
    }
});

export default function (props: {
    value: string,
    placeholder?: string,
    onTextChange(text: string): void
}) {

    const classes = useStyles();

    return (<div style={{ display: "table", height: "100%", margin: "auto", textAlign: "center", width: "100%" }} >
        <div style={{ display: "table-cell", verticalAlign: "middle" }} >
            <TextField
                variant="outlined"
                color="secondary"
                autoFocus
                value={props.value}
                placeholder={props.placeholder}
                onChange={ev => props.onTextChange(ev.target.value)}
                style={{
                    // maxWidth: 460,
                    paddingBottom: "7vh"
                }}
                inputProps={{
                    className: classes.input
                }}
                helperText="Encuentra Si Existen Marcas Registradas Similares Que Te Prohiban El  Uso  De  La  Tuya."
                FormHelperTextProps={{
                    style: { fontSize: 15, marginTop: 15, textAlign: "center", maxWidth: 400, marginLeft: "auto", marginRight: "auto" }
                }}
                InputProps={{
                    className: classes.FieldControl,
                    startAdornment: (<InputAdornment position="start" style={{
                        margin: 0
                        , marginRight: -45
                        , marginLeft: 7
                    }}>
                        <img src={process.env.PUBLIC_URL + "/svg/blue-search.svg"} height={15} style={{ margin: 10, marginRight: 13 }} />
                    </InputAdornment>),
                    endAdornment: ((props.value || "").toString().trim().length
                        ? (<InputAdornment position="end">
                            <IconButton style={{ padding: 5 }} onClick={() => props.onTextChange("")} type="button" color="secondary">
                                <CloseIcon size={11} />
                            </IconButton>
                        </InputAdornment>)
                        : null),
                }}
            />
        </div>
    </div>);
}