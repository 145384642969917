import React from "react";

export default function (props: {
    children: React.ReactNode,
    style?: React.CSSProperties
}) {
    return (<div style={{ height: "100%", display: "table", marginLeft: 0, ...(props.style || {}) }} >
        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            {props.children}
        </div>
    </div>)
}