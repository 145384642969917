import React from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import InfoDialog from "src/Components/InfoDialog";

const useStyles = makeStyles(theme => ({
    root: {
        border: "1px solid #d9dbdc",
        borderRadius: 15,
        marginBottom: 30
    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: 30
    },
    content_container: {
        flex: 1,
        flexGrow: 1,
        textAlign: "left"
    }
    , title_container: {
        display: "inline-block"
        , "& > *": {
            display: "inline"
            , verticalAlign: "middle"
        }
        , backgroundColor: "#a6fff7"
        , cursor: "pointer"
        , zIndex: 1
    }
    , button: {
        paddingLeft: "20px !important"
        , paddingRight: "20px !important"
        , minHeight: "50px !important"
    }
    , clickable_div: {
    }
    , action_row: {
        display: "flex"
        , flexDirection: "row"
        , paddingTop: 20
        , [theme.breakpoints.down("xs")]: {
            display: "block"
            , " & > div:last-child": {
                paddingTop: 10
            }
        }
        , " & > div:first-child": {
            flex: 1,
            flexGrow: 1
        }
    }
    , title_icon: {
        width: "auto"
        , marginRight: 4
        , minHeight: "1.5em"
        , minWidth: "1.5em"
        , display: "inline-block"
        , backgroundRepeat: "no-repeat"
        , backgroundPositionX: "50%"
        , backgroundPositionY: "60%"
        , backgroundSize: "150%"
    }
    , description_text: {
        whiteSpace: "pre-wrap"
        , wordWrap: "break-word"
        , overflowWrap: "break-word"
    }
}));

export default function ClaseCard(props: {
    id: string | number;
    marca: string;
    onToggle(): void;
    selected: boolean;
} & Lead["detalle_clases"][number]) {

    const classes = useStyles();
    const [detail_open, set_detail_open] = React.useState(false);

    return (<div className={classes.root}>
        <div className={classes.container}>
            <div className={classes.content_container}>
                <div>
                    <div
                        className={classes.title_container}
                        onClick={props.details.length ? () => set_detail_open(true) : undefined}
                    >
                        {props.iconSrc && <div style={{ backgroundImage: `url(${props.iconSrc})` }} className={classes.title_icon} />}
                        <Typography variant="body2" style={{ fontSize: 15 }}>{props.title}</Typography>
                        <img src={process.env.PUBLIC_URL + "/svg/info.svg"} height={15} style={{ marginLeft: 4 }} />
                    </div>
                </div>
                <div style={{ paddingTop: 10 }}>
                    <Typography variant="h4">{props.marca}</Typography>
                </div>
                <div className={classes.action_row}>
                    <div>
                        <div>
                            <div style={{ flex: 1, flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                                <Typography variant="h3" style={{ fontWeight: 600 }} >{props.price.toCurrency()}&nbsp;</Typography>
                                <Typography variant="body1" style={{ textDecoration: "line-through", fontSize: 23 }} >{props.regular_price.toCurrency()}</Typography>
                            </div>
                            <Typography variant="caption" color="secondary">Monto no incluye tasas</Typography>
                        </div>
                    </div>
                    <div>
                        <Button variant={props.selected ? "outlined" : "contained"}
                            color={props.selected ? "secondary" : "primary"}
                            className={classes.button}
                            onClick={() => props.onToggle()}
                        >
                            {props.selected ? "Quitar del carrito" : "Agregar al carrito"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <InfoDialog isOpen={detail_open} onClose={() => set_detail_open(false)}>
            <div style={{ paddingBottom: 40 }}>
                <Typography variant="body1" component="pre" className={classes.description_text}>{props.details.map(x => x.itemDescription).join("\n")}</Typography>
            </div>
        </InfoDialog>
    </div>)
}

/**
 <Typography color="primary" component="a" onClick={(e: any) => { e.preventDefault(); }} variant="caption">
                        {props.checked ? "Eliminar" : "Agregar"}
                    </Typography>

**/