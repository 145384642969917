import "../Theme/index.css";
import "../lib/ExtendNumber";
import HookToLandingPage from './HookToLandingPage';
import AppContext from '../Components/Context/AppContext';
import AppTheme from '../Theme/App.Theme';

import CustomStyles from '../Theme/CustomStyles';
import { ThemeProvider } from '@material-ui/core';

const html_root_ID = "askldfnaowejro1234";

export function get_root_ID() {
    try {
        if (!document.getElementById(html_root_ID)) throw {};
    } catch (ex) {
        const x = document.createElement("DIV");
        x.id = html_root_ID;
        document.getElementsByTagName("body").item(0)?.append(x);
    }
    return html_root_ID
}



export default function () {
    return (<ThemeProvider theme={AppTheme}>
        <CustomStyles />
        <AppContext>
            <HookToLandingPage />
        </AppContext>
    </ThemeProvider>
    );
}