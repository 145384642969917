import { makeStyles } from "@material-ui/core";

const borderLine = "1px solid #eaeaea";
export default makeStyles(theme => ({
    section: {
        paddingTop: 30,
        paddingBottom: 30,
        borderTop: borderLine,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -20,
            marginRight: -20,
            padding: 20,
            borderTopWidth: 6
        }
    },
    section_last: {
        borderBottom: "1px solid #eaeaea",
        [theme.breakpoints.down("xs")]: {
            borderBottomWidth: 6
        }
    },
    titularRoot: {
        "& > *": {
            display: "block"
        }
    },
    notification: {
        marginLeft: 15
        , [theme.breakpoints.down("xs")]: {
            marginLeft: 5
        }
    },
    notification_caption: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "inherit", lineHeight: "inherit"
        }
    },
    brand_footer: {
        marginTop: 30,
        [theme.breakpoints.down("xs")]: {
            marginTop: 0,
            padding: 20,
            borderTop: "6px solid #eaeaea"
        }
    },
    brand_button_container: {
        marginTop: 55,
        [theme.breakpoints.down("xs")]: {
            marginTop: 20
        }
    }
    , acceptTermsBlock: {
        marginTop: 20
    }
}));
