import { Typography } from "@material-ui/core";
import useBreakpoints from "./Hooks/useBreakpoints";

export default function (props: {
    children?: React.ReactNode,
    style?: React.CSSProperties
}) {
    const { xsDown } = useBreakpoints();

    if (!props.children) return null;
    return (<div style={{ marginBottom: 15, ...(props.style || {}) }}>
        <Typography variant={xsDown ? "h3" : "h5"}>
            {props.children}
        </Typography>
    </div>);
}