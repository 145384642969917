import LandingHeader from "../../Layouts/LandingPage/LandingHeader";
import { MainContainer, LeftContainer, RightContainer } from "../../Layouts/PurchaseProcess/OrderLayout/Containers";
import RedirectionScreen from "../../Layouts/PurchaseProcess/RedirectionScreen";
import CompletedStatusView from "./CompletedStatusView";
import InProgressStatusView from "./InProgressStatusView";
import PayStatusView from "./PayStatusView";

export default function (props: Payment & {
    redirectData: { token: string, url: string } | null;
    defaultPaymentMethod?: string;
    onSubmit(payment_method: string): void;
    isLoading: boolean;
    error?: Error | null;
}) {
    const { redirectData, onSubmit, isLoading, error, defaultPaymentMethod, ...payment } = props;

    const { title, subtitle } = get_title_from_number(props.number);

    var Component = (<InProgressStatusView amount={payment.amount} date={payment.date} />);

    if (payment.status === "COMPLETED") Component = (<CompletedStatusView
        amount={payment.amount}
        card_type={payment.card_type!}
        date={payment.date}
        card_number={payment.card_number!}
    />);

    if (payment.status === "ERROR") Component = (<PayStatusView
        amount={payment.amount}
        date={payment.date}
        error={error}
        isLoading={isLoading}
        onSubmit={props.onSubmit}
        defaultPaymentMethod={props.defaultPaymentMethod}

        title="Ocurrió un problema con el pago."
        subtitle="El pago no se pudo completar, verifica que tu tarjeta esté activa y tenga fondos suficientes. Si la información de pago es correcta, ponte en contacto con tu banco para obtener más detalles."
    />);
    // READY
    if (payment.status === "READY") Component = (<PayStatusView
        amount={payment.amount}
        date={payment.date}
        error={error}
        isLoading={isLoading}
        onSubmit={props.onSubmit}
        defaultPaymentMethod={props.defaultPaymentMethod}

        title={title}
        subtitle={subtitle}
    />);

    return (<div>
        <LandingHeader show_login={false} />
        <MainContainer>
            <LeftContainer>
                {Component}
            </LeftContainer>
            <RightContainer>
                {redirectData ? <RedirectionScreen {...redirectData} isOpen={true} /> : null}
            </RightContainer>
        </MainContainer>
    </div>);
}


function get_title_from_number(value: string) {
    if (value === "UTM_INICIAL") return {
        title: "Pago de Tasa Inicial del Gobierno de Chile",
        subtitle: "Ya se encuentra todo listo para comenzar tu proceso de registro solo falta el pago de la Tasa Inicial al Gobierno de Chile. Una vez que la marca comience el proceso contarás con la preferencia del nombre de la marca frente a otras solicitudes de registro posteriores a la tuya."
    };
    if (value === "UTM_FINAL") return {
        title: "Pago de Tasa Final del Gobierno de Chile",
        subtitle: "Tu solicitud ya pasó con éxito por todas las etapas administrativas. Ahora solo debes realizar el pago de la Tasa Final De Registro al Gobierno de Chile. Una vez recibida gestionaremos el pago de Tasa en la Tesorería General de la República y te enviaremos el comprobante de pago a tu correo electrónico."
    };

    return {
        title: "?",
        subtitle: "?"
    }
}