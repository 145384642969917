import React from "react";
import { Typography, makeStyles, Theme } from "@material-ui/core";
import { AppContext } from "./Context/AppContext";


export default class CountDown extends React.Component<any, any> {

    state = {
        secondsLeft: 0,
        text: "",
        strong: "",
        visible: false
    }
    timer = 0;

    static contextType = AppContext;


    secondsToTime(secs: number) {

        const days = two_digits(Math.floor(secs / (24 * 60 * 60)));

        const divisor_for_hours = secs % (24 * 60 * 60);
        const hours = two_digits(Math.floor(divisor_for_hours / (60 * 60)));

        const divisor_for_minutes = divisor_for_hours % (60 * 60);
        const minutes = two_digits(Math.floor(divisor_for_minutes / 60));

        const divisor_for_seconds = divisor_for_minutes % 60;
        const seconds = two_digits(Math.ceil(divisor_for_seconds));

        return {
            days,
            hours,
            minutes,
            seconds
        };
    }

    startTimer = (secondsLeft: number) => {
        this.setState({ secondsLeft }, () => {
            if (this.timer == 0 && this.state.secondsLeft > 0) {
                this.timer = window.setInterval(this.countDown, 1000);
            }
        });
    }

    componentDidMount() {
        const { API } = this.context as React.ContextType<typeof AppContext>;
        API.appTopCountDown()
            .then(result => {
                if (!result.visible) return;
                this.setState({ visible: true, text: result.text, strong: result.strong });
                if (result.secondsLeft) this.startTimer(result.secondsLeft);
            }).catch(ex => {
                console.error("appTopCountDown error", ex);
            });
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        const secondsLeft = this.state.secondsLeft - 1;
        this.setState({
            secondsLeft: secondsLeft
        });

        // Check if we're at zero.
        if (secondsLeft == 0) {
            clearInterval(this.timer);
        }
    }

    render() {
        if (!this.state.visible) return null;
        const time = this.secondsToTime(this.state.secondsLeft);
        return (
            <CountDownVisualComponent {...time} text={this.state.text} strong={this.state.strong} />
        );
    }
}




const useStyles = makeStyles((theme: Theme) => ({

    title_container: {
        color: "#00326B"
    },
    root: {
        backgroundColor: "rgb(7, 29, 52)",
        color: theme.palette.primary.contrastText,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 40,
        paddingRight: 40,
        [theme.breakpoints.down("xs")]: {
            display: "inherit",
            flexDirection: "inherit",
            textAlign: "center",
            paddingLeft: 20,
            paddingRight: 20
        }
    },
    left_text: {
        flex: 1
        , flexGrow: 1,
        [theme.breakpoints.down("xs")]: {
            flex: "inherit",
            flexGrow: "inherit",
            fontSize: 14
        }
    },
}));

function CountDownVisualComponent(props: {
    text: string
    , strong?: string
    , hours: number | string
    , days: number | string
    , minutes: number | string
    , seconds: number | string
}) {
    const classes = useStyles();
    return (<div className={classes.root}>
        <Typography
            component="small"
            className={classes.left_text}
            variant="body1"
            color="inherit"
        >{props.text}&nbsp;<Typography variant="h1" color="inherit" style={{
            fontSize: "inherit"
            , lineHeight: "inherit"
            , margin: "inherit"
            , display: "inline-block"
            , letterSpacing: 0
        }} >{props.strong}</Typography></Typography>
        <div>
            <CountDownText type="value">{props.days}</CountDownText>
            <CountDownText type="label">D&nbsp;</CountDownText>
            <CountDownText type="value">{props.hours}</CountDownText>
            <CountDownText type="label">H&nbsp;</CountDownText>
            <CountDownText type="value">{props.minutes}</CountDownText>
            <CountDownText type="label">M&nbsp;</CountDownText>
            <CountDownText type="value">{props.seconds}</CountDownText>
            <CountDownText type="label">S&nbsp;</CountDownText>
        </div>
    </div>);
}

const useCountdownStyle = makeStyles((theme:Theme)=>({
    timer_text_container: {
        display: "inline-block"
    },
    timer_text: {
        fontSize: 27,
        display: "inherit",
        [theme.breakpoints.down("xs")]: {
            fontSize: 20
        }
    },
    number_container: {
        minWidth: 35,
        textAlign: "right"
    },
    number_label: {
        color: "#33aaff"
    }
}))

function CountDownText(props: {
    children: React.ReactNode
    , type: "value" | "label"
}) {
    const classes = useCountdownStyle();

    return (
        <div className={[classes.timer_text_container, classes.number_container].join(" ")}>
            <Typography color="inherit" className={[classes.timer_text, props.type === "label" ? classes.number_label : ""].join(" ")} variant="h1">{props.children}</Typography>
        </div>
    );
}


function two_digits(value: number | string) {
    const text = "0" + value.toString();
    return text.slice(-2);

}