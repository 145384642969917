import { withStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";


export default withStyles((theme: Theme) => ({
    "@global": {

        ".MuiPaper-root": {
            color: (theme.typography as any).color
        }
        , ".MuiChip-root.MuiChip-outlinedPrimary": {
            backgroundColor: "#7ebbe8",
            border: "none",
            color: theme.palette.common.white,
            "&:hover": {
                backgroundColor: `${theme.palette.primary.main} !important`,
                border: "none",
                color: theme.palette.common.white
            },
        }
        , ".MuiDialog-paperScrollPaper.MuiPaper-rounded": {
            borderRadius: 10
        }
        , ".MuiDialog-paperFullScreen": {
            borderRadius: "0px !important"
        }
        , ".MuiPaper-root.MuiDialog-paper.MuiDialog-paperWidthMd": {
            maxWidth: 900
        }
        , ".MuiDialog-paperWidthSm": {
            maxWidth: 650
        }

        , ".order-layout-container": {
            padding: 20
            , margin: "auto"
            , maxWidth: 1170
        }


        , ".landing-page-block-1": {
            maxWidth: "62vw",
            marginLeft: "auto",
            marginRight: "auto",
            [theme.breakpoints.down("md")]: {
                maxWidth: "70vw"
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "calc(100% - 60px)"
            }
        }
        , ".landing-page-block-2": {
            maxWidth: "75vw",
            marginLeft: "auto",
            marginRight: "auto",
            [theme.breakpoints.down("md")]: {
                maxWidth: "80vw"
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "calc(100% - 40px)"
            }
        }

        , ".full-height": {
            height: "var(--app-height)"
        }

        , ".flow-content-container": {
            maxWidth: 630
            , paddingLeft: 30
            , paddingRight: 30
            , [theme.breakpoints.down("xs")]: {
                paddingLeft: 15
                , paddingRight: 15
            }
            , margin: "auto"
        }

        , ".MuiChip-root": {
            height: 18
            , fontSize: 12
            , lineHeight: 1
        }
        , ".MuiChip-label": {
            paddingLeft: 10
            , paddingRight: 10
        }
    }
}))(() => null);