import { faker } from "@faker-js/faker";

export default function () {
    return {
        displayName: faker.name.fullName()
        , email: faker.internet.email()
        , uid: faker.database.mongodbObjectId()
        , phoneNumber: faker.phone.number()
        , photoURL: faker.internet.url()
        , providerId: "default"
    }
}