import { Paper, Typography, Button } from "@material-ui/core";
import React from "react";
export default function (props: FixedFooterContentProps) {

    return (
        <Paper elevation={3} style={{
            width: "100%"
            , position: "fixed"
            , bottom: 0
            , padding: 0
            , margin: 0
            , display: "flex"
            , zIndex: 1
            , flexDirection: "row"
        }}>
            <FixedFooterContent {...props} style={{
                width: "100%", padding: 20
            }} />
        </Paper>)
}


interface FixedFooterContentProps {
    marca: string,
    total: number;
    detalleRef?: any,
    isLoading: boolean,
    submitButtonProps: {
        disabled: boolean,
        isLoading: boolean,
        label: string,
        onClick(): void
    }
}
export function FixedFooterContent(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & FixedFooterContentProps) {


    const { marca, total, detalleRef, submitButtonProps, ...divProps } = props;
    const scrollRef = props.detalleRef;

    return (<div {...divProps} style={{ display: "flex", ...(props.style || {}) }}  >
        <div style={{ flex: 1, margin: "auto", paddingRight: 10 }}>
            <Typography variant="subtitle2" style={{ lineHeight: 1.3 }}>
                {props.isLoading ? "Cargando..." : <>Total {total.toCurrency()}*</>}
            </Typography>
            <Typography
                component="a"
                variant="caption"
                onClick={() => scrollRef &&
                    scrollRef.current &&
                    scrollRef.current.scrollIntoView()}
                color="primary"
                style={{ lineHeight: 1, cursor: "pointer" }}
            >
                Ver detalle
            </Typography>
        </div>
        <Button
            type="button"
            disabled={submitButtonProps.disabled || submitButtonProps.isLoading}
            onClick={submitButtonProps.onClick}
            variant="contained"
            color="primary"
            className="small"
            style={{ minHeight: 50, margin: "auto", paddingLeft: 25, paddingRight: 25 }}
        >
            {submitButtonProps.label}
        </Button>
    </div >);
}