import { useTheme, useMediaQuery } from "@material-ui/core";

export default function () {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    const smOnly = useMediaQuery(theme.breakpoints.only("sm"));
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    return {
        smUp,
        smOnly,
        xsDown
    }
}