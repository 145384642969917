import React from "react";
import { Button, TextField } from "@material-ui/core";
import InfoDialog from "../../../Components/InfoDialog";
import TextInputGroup from "src/Components/Controls/TextInputGroup";
import { IsEmail, IsPhoneNumber } from "src/API/Models/DataTypeValidation";

type Contact = Lead["contacto"];

export default function ContactForm(props: {
    open: boolean;
    onClose(): void;
    default?: Partial<Contact>;
    onComplete(value: Contact): void;
}) {
    const [contact, set_contact] = React.useState<Contact>({
        email: props.default?.email || ""
        , telefono: props.default?.telefono || ""
        , nombre: props.default?.nombre || ""
    });
    const onChange = e => set_contact({ ...contact, [e.target.name]: e.target.value });
    React.useEffect(() => {
        if (props.open && props.default)
            set_contact({
                email: props.default?.email || ""
                , telefono: props.default?.telefono || ""
                , nombre: props.default?.nombre || ""
            });
    }, [props.open]);

    const error = validate(contact);
    const isValidated = !Object.keys(error).length;

    return (<InfoDialog
        maxWidth="sm"
        onClose={props.onClose}
        actions={<div style={{ padding: 20 }} >
            <Button
                color="primary"
                variant="contained"
                type="button"
                className="small"
                disabled={!isValidated}
                onClick={() => {
                    if (!isValidated) return;
                    props.onComplete(contact);
                    props.onClose();
                }}>
                Aceptar
            </Button>
        </div>}
        isOpen={props.open}
        title="Datos de contacto"
    >
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <TextInputGroup>
                <TextField
                    variant="outlined"
                    color="secondary"
                    label="Nombre Completo"
                    fullWidth
                    name="nombre"
                    autoFocus
                    autoComplete="name"
                    value={contact.nombre}
                    onChange={onChange}
                />
                <TextField
                    variant="outlined"
                    color="secondary"
                    label="Email"
                    fullWidth
                    name="email"
                    autoComplete="work home"
                    type="email"
                    error={!!(contact.email && error["email"])}
                    helperText={(contact.email && error["email"])
                        ? (typeof error["email"] === typeof ""
                            ? error["email"]
                            : "Ingrese un email válido")
                        : null}
                    value={contact.email}
                    onChange={onChange}
                />
                <TextField
                    variant="outlined"
                    color="secondary"
                    label="Telefono"
                    fullWidth
                    name="telefono"
                    autoComplete="work home"
                    type="tel"
                    error={!!(contact.telefono && error["telefono"])}
                    helperText={(contact.telefono && error["telefono"])
                        ? (typeof error["telefono"] === typeof ""
                            ? error["telefono"]
                            : "Ingrese un telefono válido")
                        : null}
                    value={contact.telefono}
                    onChange={onChange}
                />

            </TextInputGroup>
        </div>
    </InfoDialog>);
}


function validate(contact: Partial<Contact>) {
    const error_list: Partial<Contact> = {};
    if (!contact.nombre?.trim().length) error_list.nombre = "El nombre no es válido";
    if (!IsEmail(contact.email)) error_list.email = "El correo electrónico no es válido";
    if (!IsPhoneNumber(contact.telefono)) error_list.telefono = "El número no es válido";
    return error_list;
}