import { Typography } from "@material-ui/core";
import CardNumberText from "../../lib/CardNumberText";
import DateToText, { DateToTextArg } from "../../lib/DateToText";
import CardTypeIcon from "../PaymentOptions/CardTypeIcon";
import TitleStatusView from "./TitleStatusView";

export default function (props: { date: DateToTextArg, amount: number, card_type: string, card_number: string }) {
    return (<div>
        <TitleStatusView>¡Gracias por tu pago!</TitleStatusView>
        <Typography variant="h6">Tu pago se procesó con éxito:</Typography>
        <Typography variant="body1">Fecha de cobro: {DateToText(props.date)}</Typography>
        <Typography variant="body1">Metodo de pago: <CardTypeIcon name={props.card_type} height={12} /> {CardNumberText(props.card_number)}</Typography>
        <Typography>Monto: {props.amount.toCurrency()}</Typography>
    </div>)
}