import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    copyRightContainer: {
        display: "flex"
        , flexDirection: "row"
        , marginTop: 70
        , marginBottom: 20
        , textAlign: "left"
        , fontSize: 11
        , [theme.breakpoints.down("xs")]: {
            display: "block"
        }
    }
}))
export default function (props: {
    politicasPrivacidad?: boolean
}) {
    const classes = useStyles();
    return (<>
        <div className={classes.copyRightContainer}>
            <div style={{ flex: 1, flexGrow: 1 }}>
                <Typography variant="caption" style={{ fontSize: "inherit" }}>Copyright ©️ 2023 SIMPLE MARCAS LIMITADA. Todos los derechos Reservados.</Typography>
            </div>
            {props.politicasPrivacidad
                ? (<div style={{ fontSize: 11 }}>
                    <Typography component="a" target="_blank" href="https://www.simplemarcas.cl/politicas-de-privacidad" variant="caption" style={{ fontSize: "inherit" }}>Políticas de Privacidad.</Typography>
                </div>)
                : null}
        </div>
    </>);
}