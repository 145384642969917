import { CircularProgress, IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import CloseIcon from "../Icons/CloseIcon";

const backgroundColor = "#f1f0f1 !important";
const useStyles = makeStyles({
    TextField: {
        backgroundColor,
    },
    TextFieldInput: {
        paddingTop: 12,
        "&::placeholder": {
            fontSize: 14
        }
    },
    FilledInput: {
        backgroundColor,

    }
});

export default function (props: {
    onTextChange(text: string): void;
    value: string;
    loading?: boolean;
}) {
    const classes = useStyles();

    return (<TextField
        fullWidth
        className={classes.TextField}
        value={props.value || ""}
        onChange={ev => props.onTextChange(ev.target.value)}
        variant="filled"
        type="text"
        onKeyDown={event => {
            if (event.key === "Enter") {
                event.preventDefault();
                return false;
            }
        }}
        InputProps={{
            disableUnderline: true,
            startAdornment: (<InputAdornment position="start" style={{
                margin: 0
                , marginRight: 10
                , minWidth: 26
            }}>{props.loading
                ? (<CircularProgress size={20} color="primary" />)
                : (<img src={process.env.PUBLIC_URL + "/svg/blue-search.svg"} height={16} style={{ margin: 5 }} />)
                }</InputAdornment>),
            endAdornment: (props.value || "").toString().trim().length
                ? (<InputAdornment position="end">
                    <IconButton style={{ padding: 5 }} onClick={() => props.onTextChange("")} type="button" color="secondary">
                        <CloseIcon size={11} />
                    </IconButton>
                </InputAdornment>)
                : null,
            className: classes.FilledInput
        }}
        inputProps={{
            className: classes.TextFieldInput
        }}
        placeholder="Escribe aquí..."
    />)
}