import React from "react";
import $ from "jquery";

export default function () {

    const ref = React.useRef<any>(null);
    const [isInScreen, set_isInScreen] = React.useState(true);

    React.useEffect(() => {
        const WindowScrollHandler = function () {                  // assign scroll event listener
            if (!ref.current) return;
            const TARGET_EL = $(ref.current) as any;
            const fixmeTop = (TARGET_EL.offset()?.top || 0) + (TARGET_EL.outerHeight(true) || 0);       // get initial position of the element
            const currentScroll = $(window).scrollTop() || -1; // get current position
            if (currentScroll >= fixmeTop) {           // apply position: fixed if you
                set_isInScreen(false);
            } else {                                   // apply position: static
                set_isInScreen(true);
            }
        }

        $(window).on("scroll", WindowScrollHandler);
        return () => {
            $(window).off("scroll", WindowScrollHandler);
        }
    });

    return [ref, isInScreen] as [React.LegacyRef<HTMLDivElement> | undefined, boolean];
}