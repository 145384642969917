import Line from "../../Components/Line";
import CallSales from "../Item-Selection-Flow/CallSales";
import { LandingLogoStyles } from "../LandingPage/LandingHeader";
import LandingLogo from "../LandingPage/LandingLogo";
import { LeftContainer, MainContainer, RightContainer } from "../PurchaseProcess/OrderLayout/Containers";
import { FixedFooterContent } from "./FixedFooter";

export default function OrderSummaryHeader(props: {
    marca: string,
    total: number,
    isLoading: boolean,
    detalleRef: any,
    submitButtonProps: any,
    show_detalle?: boolean
}) {
    const logoClasses = LandingLogoStyles();
    return (<div style={{ position: "fixed", zIndex: 10, backgroundColor: "white", top: 0, width: "100%" }} >

        <MainContainer style={{ marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}>

            <LeftContainer style={{ height: 80 }} >
                <LandingLogo
                    className={logoClasses.Logo}
                    style={{ position: "absolute", top: 30 - 22, left: 0 }}
                />

            </LeftContainer>

            <RightContainer style={{ margin: "auto" }} >
                {props.show_detalle
                    ? <FixedFooterContent
                        style={{ marginLeft: 55 }}
                        marca={props.marca}
                        isLoading={props.isLoading}
                        total={props.total}
                        submitButtonProps={props.submitButtonProps}
                        detalleRef={props.detalleRef}
                    />
                    : null}

            </RightContainer>
            <div style={{ position: "absolute", right: 20, top: 30, display: props.show_detalle ? "none" : "block" }}>
                <CallSales />
            </div>

        </MainContainer>

        <Line size={1} use="header" />
    </div >
    )
}