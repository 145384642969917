import OrderLayout from "../../Layouts/OrderLayout";
import RedirectionScreen from "../../Layouts/PurchaseProcess/RedirectionScreen";
import CompletedStatusView from "./CompletedStatusView";
import InProgressStatusView from "./InProgressStatusView";
import PayStatusView from "./PayStatusView";

export default function InstallmentStatusView(props: {
    marca: string,
    clases_seleccionadas: Array<string | number>,
    resumen: Lead["resumen"],
    payments: Lead["payments"],
    payment: Payment,
    redirectData: { token: string, url: string } | null;
    defaultPaymentMethod?: string;
    onSubmit(payment_method: string): void;
    isLoading: boolean;
    error?: Error | null;
}) {


    const { redirectData
        , onSubmit
        , isLoading
        , error
        , defaultPaymentMethod
        , payment
        , ...request } = props;

    var Component = (<InProgressStatusView amount={payment.amount} date={payment.date} />);

    if (payment.status === "COMPLETED") Component = (<CompletedStatusView
        amount={payment.amount}
        card_type={payment.card_type!}
        date={payment.date}
        card_number={payment.card_number!}
    />);

    if (payment.status === "ERROR") Component = (<PayStatusView
        amount={payment.amount}
        date={payment.date}
        error={error}
        isLoading={isLoading}
        onSubmit={props.onSubmit}
        defaultPaymentMethod={defaultPaymentMethod}

        title="Ocurrió un problema con el pago."
        subtitle="El pago no se pudo completar, verifica que tu tarjeta esté activa y tenga fondos suficientes. Si la información de pago es correcta, ponte en contacto con tu banco para obtener más detalles."
    />);
    // READY
    if (payment.status === "READY") Component = (<PayStatusView
        amount={payment.amount}
        date={payment.date}
        error={error}
        isLoading={isLoading}
        onSubmit={props.onSubmit}
        defaultPaymentMethod={defaultPaymentMethod}

        title={"Pago cuota #" + payment.number}
    />);


    return (<OrderLayout
        marca={request.marca}
        clases_seleccionadas={request.clases_seleccionadas}
        resumen={request.resumen}
        payments={request.payments}
    >
        {redirectData ? <RedirectionScreen {...redirectData} isOpen={true} /> : null}
        {Component}
    </OrderLayout>);
}
