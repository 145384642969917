import { Checkbox, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline"
    },
    content: {
        flex: 1
    }
})
export default function (props: {
    checked: boolean,
    className?: string,
    onChange(new_value: boolean): void
}) {
    const classes = useStyles();
    return (
        <div className={[classes.flex, props.className || ""].join(" ")}>
            <div>
                <Checkbox
                    size="small"
                    color="primary"
                    checked={props.checked || false}
                    onChange={ev => props.onChange(ev.target.checked)}
                />
            </div>
            <div className={classes.content}>
                <Typography variant="caption" component="small" className="sm" color="secondary">
                Al marcar esta casilla y enviar este formulario, reconozco que he leído y acepto los&nbsp;<TermnsLink />.
                </Typography>
            </div>
        </div>);
}

function TermnsLink() {
    return (<Typography
        href="https://www.simplemarcas.cl/terminos-del-servicio"
        target="_blank"
        color="primary"
        style={{ fontSize: "inherit", lineHeight: "inherit", fontFamily: "inherit" }}
        component="a"
    >
        Términos del servicio
    </Typography>)
}