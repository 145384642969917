import React from "react";
import { Typography, Grid, Paper, makeStyles, Theme } from "@material-ui/core";
import Line from "../Components/Line";
import NotificationDiv from "../Components/Controls/NotificationDiv";
import LandingHeader from "./LandingPage/LandingHeader";
import ProductSummaryList from "../Components/ProductSummaryList";
import CopyRightFooter from "./LandingPage/CopyRightFooter";
import withFetchLead from "../Components/HOC/withFetchLead";
import useBreakpoints from "../Components/Hooks/useBreakpoints";
import useNavigateToStep from "../Components/Hooks/useNavigateToStep";
import { useAppContext } from "../Components/Context/AppContext";
import { LeadStatus } from "../API/Models/LeadStatus";
import DateToText from "../lib/DateToText";
import CardNumberText from "../lib/CardNumberText";
import CardTypeIcon from "../Components/PaymentOptions/CardTypeIcon";
import AnalyticsEvents from "../lib/AnalyticsEvents";
import { User } from "firebase/auth";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: 30,
        marginTop: 20,
        borderRadius: 8
    }
    , root: {
        padding: 30,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 15,
            paddingRight: 15
        }
    }
    , root_sides: {
        [theme.breakpoints.down("sm")]: {
            margin: "auto",
            width: "100%"
        }
    }
    , sides: {
        [theme.breakpoints.down("sm")]: {
            margin: 0,
            width: "100%"
        }
    }
}));

export default withFetchLead(function (props: { lead: Lead & { status: LeadStatus } }) {
    const { lead } = props;

    const { navigateToFlow, navigateToSubmit } = useNavigateToStep();
    const { user, anonymous_activity_email, initialized } = useAppContext();

    React.useEffect(() => {
        if (!initialized) return;
        if (!lead.id) {
            navigateToFlow();
            return;
        }
        if ([LeadStatus.SUBMITTED, LeadStatus.APPROVED].indexOf(lead.status) < 0) {
            navigateToSubmit();
            return;
        }
        AnalyticsEvents.purchaseCompletionShown(lead, user as User);
    }, [initialized]);

    const email = user?.email || anonymous_activity_email;

    const { resumen, payments } = lead;
    const classes = useStyles();
    const { xsDown } = useBreakpoints();

    return (<>
        <LandingHeader show_login={false} />
        <div style={{ textAlign: "center", background: "#f3f3f3" }} className={classes.root} >
            <div style={{ maxWidth: 1200, margin: "auto", textAlign: "center" }}>
                <Grid container spacing={xsDown ? 1 : 5} style={{ textAlign: "left" }} className={classes.root_sides} >
                    <Grid item sm={8} className={classes.sides}>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography color="secondary" variant="h6">{user && user.displayName && (name(user!.displayName) + ", ")}hemos recibido tu solicitud!</Typography>
                            <div style={{ display: lead.submittedAt ? "block" : "none" }} >
                                <Typography component="p" variant="caption">Fecha: {DateToText(lead.submittedAt! as string)}</Typography>
                            </div>
                            <div style={{ marginTop: 10 }} >
                                <Typography color="secondary" component="small" variant="body1"> Gracias por utilizar Simple Marcas! </Typography>
                            </div>

                            <div>
                                <Typography color="secondary" component="small" variant="body1">Enviaremos los detalles de tu solicitud a <Typography color="inherit" style={{ display: "inline", fontSize: "inherit" }} variant="body2">{email}</Typography></Typography>
                            </div>
                        </Paper>

                        <Paper elevation={0} className={classes.paper}>
                            <Typography color="secondary" variant="h6">Estamos confirmando tu solicitud.</Typography>
                            <div style={{ margin: 10, marginTop: 30, marginBottom: 30 }} >
                                {xsDown
                                    ? <img src={`${process.env.PUBLIC_URL}/img/last-stepper-mobile.png`} style={{ width: "100%", height: "auto" }} />
                                    : <img src={`${process.env.PUBLIC_URL}/img/last-stepper.png`} style={{ width: "100%", height: "auto" }} />
                                }
                            </div>

                            <NotificationDiv
                                iconSrc={process.env.PUBLIC_URL + "/svg/exclam.svg"}>
                                <Typography variant="body1" color="secondary" component="small">
                                    Una vez que se confirme, recibirás una notificación en tu correo electrónico y comenzaremos con tu proceso de registro.
                                </Typography>
                            </NotificationDiv>
                        </Paper>

                        <Paper elevation={0} className={classes.paper}>
                            <Typography color="secondary" variant="h6">Detalle de la solicitud</Typography>

                            <div style={{ marginTop: 20, marginBottom: 10 }}>
                                <Typography color="secondary" className="c-303030" style={{ fontSize: 21 }} variant="h5">{lead.marca}</Typography>
                                <Typography color="secondary" style={{ marginTop: 10, marginBottom: 15 }} variant="body2">Clase {(lead.clases_seleccionadas || []).join(", ")}</Typography>
                                <Line />
                            </div>
                            <ProductSummaryList hide_detalle_title={true} {...resumen} payments={payments} />
                        </Paper>

                        <Paper elevation={0} className={classes.paper}>
                            <Typography variant="h6">Detalle del pago</Typography>
                            <div style={{ marginTop: 13 }} >
                                <div>
                                    <CardTypeIcon name={payments[0]?.card_type || ""} height={12} />
                                    <Typography variant="body2" style={{ fontSize: "inherit", display: "inline" }} color="secondary">
                                        &nbsp;{CardNumberText((payments[0] || { card_number: "xxxx xxxx xxxx xxxx" }).card_number || "")}
                                    </Typography>
                                </div>
                                <Typography variant="body1" color="secondary">
                                    <Typography variant="body2" style={{ fontSize: "inherit", display: "inline" }} color="secondary">Cuotas:</Typography>
                                    {resumen.cuotas}
                                </Typography>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item sm={4} className={classes.sides}>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography color="secondary" variant="h6">Problemas con tu compra?</Typography>
                            <div style={{ marginTop: 10 }} >
                                <Typography color="secondary" component="small" variant="body1">Escríbenos a&nbsp;
                                    <Typography style={{ display: "inline-block", fontSize: "inherit" }} color="inherit" variant="body2" component="a" href="mailto:asistencia@simplemarcas.cl">asistencia@simplemarcas.cl</Typography>
                                </Typography>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <CopyRightFooter />
            </div>
        </div>
    </>);
});


function name(displayName?: string | null) {
    if (!displayName) return "";
    return displayName.split(" ")[0] || displayName.split(" ")[1] || displayName;
}