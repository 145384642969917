export default function (path: string, paymentStatus?: Payment["status"] | string, paymentNumber?: string) {
    if (path.indexOf("flow") >= 0)
        return document.title = "Simple Marcas - Búsqueda";

    if (path.indexOf("resume") >= 0)
        return document.title = "Simple Marcas - Registro de Marca";

    if (path.indexOf("submitted") >= 0)
        return document.title = "Simple Marcas - Gracias";

    if (path.indexOf("submit") >= 0)
        return document.title = "Simple Marcas - Resumen de la orden";

    if (path.indexOf("payment-status") >= 0) {
        if (paymentStatus && paymentNumber)
            return document.title = PaymentTitle(paymentStatus as Payment["status"], paymentNumber);
        return document.title = "Simple Marcas - Pago no encontrado";
    }

    if (path.indexOf("open-payment") >= 0) {
        if (paymentStatus)
            return document.title = "Simple Marcas - " + paymentStatus;
        return document.title = "Simple Marcas - Pago Abierto";
    }

    document.title = "Simple Marcas";
}

function PaymentTitle(paymentStatus: Payment["status"], number: string) {

    if (number === "UTM_INCIAL") {
        if (paymentStatus === "ERROR")
            return `Simple Marcas - Tasa Inicial rechazada`;

        if (paymentStatus === "COMPLETED")
            return `Simple Marcas - Pago completado Tasa Inicial`;

        return `Simple Marcas - Tasa Inicial`;
    }

    if (number === "UTM_INCIAL") {
        if (paymentStatus === "ERROR")
            return `Simple Marcas - Tasa Final rechazada`;

        if (paymentStatus === "COMPLETED")
            return `Simple Marcas - Pago completado Tasa Final`;

        return `Simple Marcas - Tasa Final`;
    }

    if (paymentStatus === "ERROR")
        return `Simple Marcas - Pago ${number} rechazado`;

    if (paymentStatus === "COMPLETED")
        return `Simple Marcas - Pago completado cuota ${number}`;

    return `Simple Marcas - Cuota ${number}`;
}