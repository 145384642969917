import React from "react";
import { GetFlow } from ".";
export default function fetchFlowData(type: GroupType | ListType | "root-group") {
    const [loading, set_loading] = React.useState(true);
    const [error, set_error] = React.useState<null | Error>(null);
    const [data, set_data] = React.useState([]);

    React.useEffect(() => {
        GetFlow(type, undefined, undefined, () => {
            set_data(GetFlow(type))
            set_loading(false);
        }, err => {
            set_error(err);
            set_loading(false);
        });
    }, []);

    return [data, loading, error] as const;
}