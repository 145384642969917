import { createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export default createTheme({

    breakpoints: {
        values: {
            xs: 0,
            sm: 770,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },

    palette: {
        primary: {
            main: "#0087ff",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#081d34",
            contrastText: "#ffffff"
        },
        error: {
            main: "#bb0000",
            light: "#fffbfb"
        }
    },
    typography: {
        fontSize: 16,
        // @ts-ignore
        color: "#081d34",
        fontFamily: "Helvetica Now Text Regular",
        [breakpoints.down("xs")]: {
            fontSize: 12
        },


        h1: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 44,
            letterSpacing: -0.6,
            [breakpoints.down("xs")]: {
                fontSize: 35
            }
        },
        h2: {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 33,
            [breakpoints.down("xs")]: {
                fontSize: 23
            }
        },
        h3: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 30,
            [breakpoints.down("xs")]: {
                fontSize: 25
            }
        },
        h4: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 21,
            lineHeight: 1.334,
            [breakpoints.down("xs")]: {
                fontSize: 21
            }
        },
        h5: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 25,
            [breakpoints.down("xs")]: {
                fontSize: 20
            }
        },
        h6: {
            fontFamily: "Poppins",
            fontSize: 17,
            fontWeight: 500,
            [breakpoints.down("xs")]: {
                fontSize: 20
            }
        },



        subtitle1: {
            fontFamily: "Helvetica Now Text Light",
            fontSize: 18,
            lineHeight: 1.3,
            [breakpoints.down("md")]: {
                fontSize: 16
            }
        },
        subtitle2: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 17,
            lineHeight: 1.2,
            [breakpoints.down("xs")]: {
                fontSize: 17
            }
        },
        caption: {
            fontFamily: "Lato",
            color: "#5A677D",
            fontSize: 13
        },
        body1: {
            fontFamily: "Poppins",
            fontSize: 15,
            [breakpoints.down("xs")]: {
                fontSize: 15
            }
        },
        body2: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 16
        }

    },
    props: {
        MuiButtonBase: {
            disableTouchRipple: true,
            disableRipple: true
        }
        , MuiFilledInput: {
            disableUnderline: true,
        }
        , MuiOutlinedInput: {
            notched: false
        }
        , MuiTextField: {
            fullWidth: true
        }
        , MuiDialogContent: {
            style: {
                overflowX: "hidden"
            }
        }
    }
});