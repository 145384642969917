import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    flexBox: {
        display: "flex"
        , flexDirection: "row"
        , justifyItems: "center"
        , justifyContent: "center"
        , "& > *:first-child ": {
            flex: 1,
            flexGrow: 1,
            margin: "auto"
        }
    }
})

export default function (props: React.HTMLAttributes<HTMLDivElement>) {

    const classes = useStyles();

    const { className, children, ...remainProps } = props;
    return <div {...remainProps} className={classes.flexBox + " " + (className || "")}  >
        {children}
    </div>
}