import React from "react";

const boldRegExp = /\*(.*?)\*/;

export default function (props: {
    input: string;
    children: React.ElementType<{ text: string }>;
    RenderBold?: React.ElementType<{ text: string }>
}) {
    if (!props.input) return null;
    return (<>{props.input.split(/\n|↵/g).map((lineText, lineTextIndex, allLineTexts) => {
        const is_last = (lineTextIndex === allLineTexts.length - 1);

        const TextLineArray = lineText.split(boldRegExp)
            .map((textItem, textItemIndex) => {
                // all oods are bold
                var isBold = textItemIndex % 2;
                if (!textItem) return <React.Fragment key={`line-${lineTextIndex}-${textItemIndex}`}></React.Fragment>;

                if (isBold && props.RenderBold)
                    return <props.RenderBold key={`line-${lineTextIndex}-${textItemIndex}`} text={textItem} />;

                return <props.children key={`line-${lineTextIndex}-${textItemIndex}`} text={textItem} />;
            });

        return (<React.Fragment key={`line-${lineTextIndex}`}>
            {TextLineArray}
            {is_last ? null : <br />}
        </React.Fragment>);
    })
    }</>)
}
