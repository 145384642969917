import { useNavigate, useSearchParams } from "react-router-dom";
import { MergeStateFromSearchParams, URLSearchState } from "./lib/useStateFromSearchParams";


export default function () {
    const navigate = useNavigate();
    const [searchparams] = useSearchParams();

    return {
        navigateToFlowStart() {
            searchparams.delete("_s");
            navigate({
                pathname: "/start-flow"
                , search: ""
            });
        },
        navigateToFlowLink(sid?: string) {
            searchparams.delete("_s");
            if (sid) searchparams.append("_s", sid);
            return "/flow?" + searchparams.toString();
        },
        navigateToFlow(sid?: string, state?: string) {
            searchparams.delete("_s");
            if (sid) searchparams.append("_s", sid);
            if (state) searchparams.set("_m", state);
            navigate({
                pathname: "/flow"
                , search: searchparams.toString()
            });
        },
        navigateToOrderPage(state?: URLSearchState) {
            navigate({
                pathname: "/resume"
                , search: MergeStateFromSearchParams(searchparams, state).toString()
            });
        },
        navigateToSearchResult(_m: string, id?: string) {
            searchparams.delete("_s");
            navigate({
                pathname: "/resume"
                , search: `_m=${_m}` + (id ? `&_s=${id}` : "")
            });
        },
        navigateToSubmit(sid?: string) {
            navigate({
                pathname: "/submit"
                , search: sid ? ("_s=" + sid) : searchparams.toString()
            });
        },
        navigateToCompleted(sid?: string) {
            navigate({
                pathname: "/submitted"
                , search: sid ? ("_s=" + sid) : searchparams.toString()
            });
        }
    }
}

