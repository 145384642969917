import { useParams } from "react-router-dom";
import ErrorScreen from "../ErrorScreen";
import OpenPayment from "./OpenPayment";
import OpenPaymentActions from "./OpenPaymentActions";
import LoadingScreen from "../LoadingScreen";

export default function () {
    const params = useParams();
    const id = params.id;
    const { data, error, status } = OpenPaymentActions(id);

    var theError = { message: "Ocurrió un error", name: "🪲" };
    if (error) theError = error;

    if (status === "ERROR")
        return (<ErrorScreen {...error || theError} />);

    if (status === "LOADING")
        return (<LoadingScreen />);

    return (<OpenPayment
        {...(data || {}) as any}
        id={id!}
    />);
}