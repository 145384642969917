
/*
const URL = MockValues()
    ? "http://localhost:5001/simple-marcas-ecommerce/us-central1/api/"
    : "./api/";
*/

const isDev = function () {
    return process.env.NODE_ENV === "development";
}

function serverUrl() {
    if (isDev()) return "http://localhost:5001/simple-marcas-ecommerce/us-central1/api/api/";
    if (process.env.PROGRAM_TYPE === "landing_hook") return "https://app.simplemarcas.cl/api/";
    return `${window.origin || window.location.origin}/api/`;
}


export default abstract class {
    /**
     * Asigna una funcion de promesa que devuelve el token del usuario para uso de solicitudes http
     * @param fn Promise<string>
     */
    public async setTokenGenerator(fn: () => Promise<string>) {
        try {
            const result = await fn();
            if (!(result && result.length)) throw {};
            this.getToken = fn;
        } catch (ex) {
            this.getToken = () => Promise.resolve("");
        }
    }
    private __anonymousActivityEmail: string | undefined = undefined; 
    public setAnonymousActivityEmail(value: string | undefined) {
        this.__anonymousActivityEmail = value;
    }
    private getToken() {
        return Promise.resolve("");
    }

    protected async createAuthorizedHeader() {
        const h = new Headers();
        h.set("authorization", "Bearer " + (await this.getToken()));
        h.set("anonymous_activity_email", this.__anonymousActivityEmail || "");
        return h;
    }
    /**
     * @param path Ruta
     * @param options Datos de la solicitud. si solo se declara `body`, se enviará una solicitud POST.
     * Si no se recibe ningún valor, se enviará una solicitud `GET`
     * @returns Promise<any>
     */
    protected async F(path: string, options: {
        method?: "PUT" | "POST" | "DELETE" | "GET" | "PATCH"
        , body?: { [key: string]: any }
    } = { method: "GET" }) {

        var method = options.method;
        if (!method && options.body) method = "POST";
        if (!method && !options.body) method = "GET";
        const URL = serverUrl();
        const response = await fetch(`${URL}${path}`, {
            method
            , body: options.body && JSON.stringify(options.body)
            , headers: await this.createAuthorizedHeader()
            , mode: "cors"
        });
        if (!response.ok) {
            // sin autorización
            if (response.status == 401) throw {
                message: "Inicia sesión para continuar",
                status: response.status,
                statusText: response.statusText
            }
            const error = await response.json();
            if (response.status >= 400) {
                error.status = response.status;
                error.statusText = response.statusText;
            }
            throw error;
        }
        if (response.ok) return response.json();
    }
}