import { makeStyles, Theme, Typography } from "@material-ui/core";
import InfoDialog from "../../Components/InfoDialog";
import Questions from "../LandingPage/Questions";

const useStyles = makeStyles((theme: Theme) => ({
    text: {
        fontSize: (+theme.typography.caption.fontSize!) - 1,
        textDecoration: "none"
    },
    onHover: {
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));


export default function (props: {
    isOpen: boolean,
    onClose(): void;
}) {

    const classes = useStyles();

    const QuestionAction = (<div style={{
        background: "rgb( 245, 245, 245 )"
        , textAlign: "center"
        , width: "100%"
        , paddingTop: 27
        , paddingBottom: 23
        , margin: "auto"
        , display: "block"
    }} >
        <div style={{ marginBottom: 3 }}>
            <img src={`${process.env.PUBLIC_URL}/svg/chat-icon.svg`} height={25} />
        </div>
        <div>
            <Typography variant="caption"
                className={classes.text}
                style={{
                    fontFamily: "Helvetica Now Text Medium"
                    , color: "#222222"
                }}>
                ¿Tienes alguna otra pregunta?
            </Typography>
        </div>
        <div>
            <Typography variant="caption"
                className={[classes.text].join(" ")}
                component="a"
                color="primary"
                href="http://runfoodapp.com"
            >
                Chatea con un especialista
            </Typography>
        </div>
    </div>);

    return (<InfoDialog  {...props} actions={QuestionAction} title="Preguntas frecuentes" >
        <Questions />
    </InfoDialog>);
}