import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useAppContext } from "../../Components/Context/AppContext";
import ErrorScreen from "../../Components/ErrorScreen";
import useNavigateToStep from "../../Components/Hooks/useNavigateToStep";
import LoadingScreen from "../../Components/LoadingScreen";
import FlowLayout from "./FlowLayout";
import ListItemInput from "./Select-Group/List-Item-Input";


const useStyles = makeStyles({
    videoContainer: {
        height: "100%",
        width: "100%",
        position: "relative",
        "& > video": {
            width: "100%",
            height: "100%",
            position: "absolute",
            objectFit: "cover",
            zIndex: 0
        }

        , "& > .caption": {
            zIndex: 1,
            position: "relative",
            textAlign: "center",
            color: "#dc0000",
            padding: "10px"
        }
    },
    List: {
        paddingBottom: 0
    }
})

export default function () {

    const classes = useStyles();
    const { navigateToSearchResult, navigateToFlow } = useNavigateToStep();
    const [show, set_show] = React.useState(false);
    const [searchHistory, set_searchHistory] = React.useState<{ marca: string, base64_state: string, id: string }[]>([]);
    const [error, set_error] = React.useState<Error | null>(null);
    const [loading, set_loading] = React.useState(true);
    const { API } = useAppContext();

    React.useEffect(() => {
        API.getSearchHistory().then(results => {
            /** @ts-ignore */
            set_searchHistory(results);
        }).catch(ex => {
            set_error(ex);
        }).finally(() => {
            set_loading(false);
        });
    }, []);

    if (loading) return <LoadingScreen fullScreen />;
    if (error) return <ErrorScreen {...error} />;

    /*
     leftContent={<div className={classes.videoContainer}>
                 <video autoPlay muted loop>
                     <source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                 </video>
             </div>}
 */
    return (<FlowLayout
        title="Haz una nueva búsqueda o selecciona una anterior"
        titleIconSrc={`${process.env.PUBLIC_URL}/img/start-search-title.png`}
        rightFootContent={null}
    >
        {searchHistory && searchHistory.length
            ? (<div>
                <ListItemInput
                    classes={{ List: classes.List }}
                    header={<Typography variant="h5">Mis búsquedas anteriores</Typography>}
                    items={searchHistory.map(item => ({
                        id: item.id
                        , base64_state: item.base64_state
                        , primaryText: item.marca
                        , icon: <img title="Icon" height={20} width={20} src={`${process.env.PUBLIC_URL}/svg/prev-search.svg`} />
                    })).splice(0, show ? searchHistory.length : 3)}
                    onClick={item => navigateToSearchResult(item.base64_state, item.id)}
                    footer={searchHistory.length > 3
                        ? <Typography variant="body2" className="sm" component="a" onClick={() => set_show(!show)}>
                            Mostrar {show ? "menos" : "todas"}
                        </Typography>
                        : null}
                />

            </div>)
            : null}

        <ListItemInput
            header={<Typography variant="h5">Hacer una búsqueda nueva</Typography>}
            items={[
                {
                    id: "NEW"
                    , primaryText: "Hacer una nueva búsqueda"
                    , icon: <img title="Icon" height={20} width={20} src={`${process.env.PUBLIC_URL}/svg/add.svg`} />
                }
            ]}
            onClick={() => navigateToFlow()}
        />

    </FlowLayout>)
}