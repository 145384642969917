import { makeStyles, Theme } from "@material-ui/core";
import { BORDER_STYLE } from "../../../Theme/colors";
export default makeStyles((theme: Theme) => ({
    leftTitle: {
        marginTop: 25
    }
    , loadingText: {
        paddingLeft: 20
        , paddingRight: 20
    }
    , QueIncluyeLine: {
        marginTop: 20,
        [theme.breakpoints.down("xs")]: {
            display: "none !important"
        }
    }
    , CarouselSection: {
        marginTop: 40,
        [theme.breakpoints.down("xs")]: {
            marginTop: 10
        }
    }
    , QueIncluyeText: {
        marginBottom: 40,
        [theme.breakpoints.down("xs")]: {
            marginBottom: 20
        }
    }
    , FeaturesContainer: {
        paddingBottom: 0
    }
    , xsFullWidth: {
        [theme.breakpoints.down("xs")]: {
            marginLeft: -20,
            marginRight: -20
        }
    },
    DividerRule3: {
        marginTop: 60,
        marginBottom: 10,
        [theme.breakpoints.down("xs")]: {
            marginTop: 50
        }
    },
    DividerRule4: {
        marginTop: 70
        , marginBottom: 60
        , [theme.breakpoints.down("xs")]: {
            marginTop: 45,
            marginBottom: 35
        }
    }
    , featuresContainer: {
        paddingTop: 43
        , marginTop: 40
        , borderTop: BORDER_STYLE
    }
    , VideoSection: {
        marginTop: 50
        , [theme.breakpoints.down("xs")]: {
            marginTop: 0
        }
    }

    , searchBar: {
        backgroundColor: "#f6f7f9"
        , paddingTop: 20
        , paddingBottom: 20
        , textAlign: "center"
    }
}));
