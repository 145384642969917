import { Typography } from "@material-ui/core";
import useBreakpoints from "src/Components/Hooks/useBreakpoints";
export default function CallSales() {
    const { xsDown } = useBreakpoints();
    const style:React.CSSProperties = { display: "inline" };
    if (xsDown) style.fontSize = 12;
    return (<Typography
        component="small"
        variant="body2"
        style={style}>
        Ventas: <a style={{ color: "inherit", textDecoration: "none" }} href="tel:+56224053566">562 240 53 566</a>
    </Typography>);
}