import { TextField } from "@material-ui/core";

export default function (props: {
    value: string;
    onChange(value: string): void;
    error?: string | null
    disabled?: boolean;
}) {

    /*
    const [status, set_status] = React.useState("LOADING");
    const [codes, set_codes] = React.useState<{ name: string, dial_code: string, code: string }[]>([]);

    React.useEffect(() => {
        if (status !== "LOADING") return;
        fetch("https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json")
            .then(results => results.json())
            .then(results => {
                set_status("");
                console.info(results);
                set_codes(results);
            });
    }, [status]);
    const all_values = (props.value || "").split(" ");
    const selection = all_values.shift() || "";
    const text = all_values.join("") || "";
    */

    return (
        <TextField
            fullWidth
            value={props.value}
            label="Teléfono"
            type="tel"
            placeholder="+"
            variant="outlined"
            color="secondary"
            disabled={props.disabled}
            onChange={event => {
                var value = event.target.value;
                if (value && value.toString().trim())
                    value = "+" + value.replace(/\+/g, "");
                props.onChange(value);
            }}
            error={!!props.error}
            helperText={props.error}
        />
    );
}

/*
            InputProps={{
                startAdornment: (<InputAdornment position="start" >
                    <TextField
                        value={selection}
                        onChange={event => props.onChange(event.target.value + " " + text)}
                        select
                        SelectProps={{
                            native: true
                        }}
                    >
                        {codes.map(c => (<option value={c.dial_code}>{c.name}</option>))}
                    </TextField>
                </InputAdornment>)
            }}
*/